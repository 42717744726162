import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IFarmer, IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {farmers} from '../ApiClients/DummyData';
import FarmerItem from './FarmerListItem';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import {readLocalStorage, writeLocalStorage} from '../Library/sp_general';
import { GetViewDefaultType } from '../Library/helpers';
import OrderService from '../ApiClients/OrderService';
import Translate from './Material/Traductor';

//import {GetLogoEmpresa} from '../Library/helpers';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});

interface IFarmersProps {
    //history: any;
    //match: any;
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    cart: Array<IProduct>;
    pedido: IPedido;
    addOrRemoveFromCart: Function;
    updateCart: Function;
    checkPermission:Function;
    allowed:boolean;
    lang:string;
}

interface IFarmersState {
    loading: boolean;
    loadbeauty: boolean;
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;
    farmers: Array<IFarmer>
    searchValue: string;
    viewType: number;
    titleModal:string;
}

class Farmers extends Component<IFarmersProps, IFarmersState> {
    constructor(props: IFarmersProps) {
        super(props);
        this.state = {
            loading: true,
            loadbeauty: false,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            farmers: [],
            searchValue: '',
            viewType: GetViewDefaultType(),
            titleModal: ''
        };
    }

    componentDidMount() {
        this.handleMyInfoChange();
    }

    componentDidUpdate = (prevprops:IFarmersProps) => {
        if(prevprops.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo)
        this.loadData();
    }

    loadData = async () => {
        let farmers:Array<IFarmer> = await OrderService.loadFarmers();

        this.setState({
            farmers: farmers,
            loadbeauty: false,
            loading: false
        })
    }
    
    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            default:
                break;
        }
    }


    
    handleItemClick = (farmer: IFarmer, index: number) => {
        this.props.navigate(`/farmer/${farmer.id}`)
    }

    handleOptionClick = (option:number, farmer:IFarmer) => {
        switch(option){
            case 1: // Go to farmer products
                this.props.navigate(`/farmer/${farmer.id}/products`);
                break;
        }
    }

    products = (viewType:number, lang:string) => {

        const searchValue = this.state.searchValue;

        let f_farmers = filter(this.state.farmers, function(o:IFarmer){return o.name.toLowerCase().includes(searchValue.toLowerCase())});

        let ret = undefined;

        if(f_farmers.length > 0) {
            if(viewType == 1){
                ret = f_farmers.map((item:IFarmer, idx:number)=>{

                    item.onItemClick = () => this.handleItemClick(item, idx)
                    item.onOptionClick = (option:number) => this.handleOptionClick(option, item)
                    //item.quantity = (item.isInCart?item.quantity:item.minimunQuantity)
        
                    return(
                        <FarmerItem
                            key={item.id}
                            style={{}}
                            index={idx}
                            data={[item, lang]}
                        />
                    )
                });

                ret = 
                    <div className='dynamic-grid'>
                        {ret}
                    </div>

            } else if(viewType == 2){

                ret = f_farmers.map((item:IFarmer, idx:number)=>{

                    item.onItemClick = () => this.handleItemClick(item, idx)
                    item.onOptionClick = (option:number) => this.handleOptionClick(option, item)
                    //item.quantity = (item.isInCart?item.quantity:item.minimunQuantity)
                    
                    return(
                        <FarmerItem
                            key={item.id}
                            style={{}}
                            index={idx}
                            data={[item, lang]}
                        />
                    )
                });

                ret = 
                    <div className='dynamic-grid'>
                        {ret}
                    </div>
            }
        } else {
            ret = 
            <div style={{textAlign:'center', marginTop:20}}>
                <a style={{color:'#000'}}>{`${Translate(lang, "label.farmer.03")}`}</a>
            </div>
        }

        return (
            <div style={{
                position: 'absolute',
                top: 200,
                left: 0,
                right: 0,
                bottom: 30,
                overflowY: 'auto',
                zIndex:0
            }}>
                {ret}
            </div>

        );
    }

    handleSearchChange=(evt:any)=>{
        if(!evt){
            this.setState((prevState, props) =>({
                searchValue: '',
            }),);
        } else {
            this.setState((prevState, props) =>({
                searchValue: evt.target.value,
            }),);
        }
    }

    render() {
        const {myInfo, version, navigate, pedido, params, lang } = this.props;
        const {loadbeauty, searchValue, viewType, loading } = this.state;

        if(myInfo == null)
            console.log('Usuario no autorizado');
            
        return (
            <div>
                {/*TutorialExplication()*/}
                <Layout
                    navigate={navigate}
                    params={params}
                    placeholderSearch={Translate(lang,"label.placeholderSearch.01")}
                    badgeAlarms={pedido.cart.length}
                    version={version}
                    searchValue={searchValue}
                    onSearchChange={this.handleSearchChange}
                    me={myInfo}
                />
                <div className='home-content'>

                    {/*this.home()*/}
                    {loading || !myInfo?
                        <div style={{marginLeft:'50%',marginTop:'10%'}}>
                            <Loader loading={this.state.loading}/>
                        </div>

                    :
                        this.products(viewType, lang)
                    }

                    <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={false}
                            titleModal={this.state.titleModal}
                        />
                </div>
            </div>
        );
    }
}

export default withParamsAndNavigate(Farmers);