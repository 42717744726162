import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import { IFarmer, IFestivo, IMe, IPedido, IProduct, IProject } from '../Library/types';
import IconButton from '@material-ui/core/IconButton';
import { cloneDeep, findIndex, sortBy, find, filter } from 'lodash';
import OrderService from '../ApiClients/OrderService';
import ProductsIcon from '@material-ui/icons/PlaylistAddOutlined'
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DoIcon from '@material-ui/icons/OpenInNewOutlined';
//import Summary from './Summary';
import orderIcon from '../Assets/images/cargas.png';
import {verifyDateTimeLimit, GetFormattedPedidoDate, getStatusDescriptionColor, addThousandsAndReplaceDecimal, RemoveFunctions } from '../Library/helpers'
import Loader from './Material/Loader';
import CreateModal from './Material/CreateDialog';
import DatePicker from './Material/DatePicker';
import { withParamsAndNavigate } from './Material/ReactRouter';
import ProductItem from './ProductListItem02';
import {readLocalStorage, writeLocalStorage } from '../Library/sp_general'
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import Translate from './Material/Traductor';
import { GetLanguage } from '../Library/helpers';


const styles = (theme: Theme) => createStyles({
    itemDistribucion: {
        border: '2px solid #ccc',
        borderRadius: '15px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        padding: '5px',
        position: 'relative',
    },
    tablecell: {
        fontSize: 11
    }
});

function makeid(length:number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function GetBehaviour(pedido:IPedido, me:IMe){
    if(me.profile == 10 && pedido.id.length == 0)
        return 1;
    if(me.profile == 20 && pedido.id.length > 0 && pedido.estado == 10)
        return 2;
    return 0;
}

interface ICestaProps {
    history: any;
    match: any;
    pedido: IPedido;
    addOrRemoveFromCart: Function;
    updateCart: Function;
    farmRef?: string;
    dataareaid?:string;
    updateCartDate: (date: any, value?: string | null | undefined) => void;
    fullScreen?: boolean;
    updateCartComments?: any;
    idPedido?: number;
    resetCart: Function;
    classes:any;
    updateQuantityCart:Function;
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    resetPedido: Function;
    checkPermission:Function;
    allowed:boolean;
    lang:string;
}

interface ICestaState {
    openCommentDialog: boolean;
    summaryMode: boolean;
    cartDate: any;
    inputText: any;
    creationModalOpen: boolean;
    modalType: number;
    typeAction: number;
    loadingModal: boolean;
    loading: boolean;
    titleModal:string;
    selectedProduct?:IProduct;
    loadbeauty: boolean;
    searchValue: string;
    behaviour: number;
}

class Cesta extends Component<ICestaProps, ICestaState> {
    constructor(props: ICestaProps) {
        super(props);
        this.state = {
            openCommentDialog: false,
            summaryMode: false,
            cartDate: this.initialDatetime(),
            inputText:'',
            creationModalOpen: false,
            modalType: -1,
            typeAction: -1,
            loadingModal: false,
            loading: true,
            titleModal: '',
            loadbeauty: false,
            searchValue: '',
            behaviour: 0,
        };
    }

    initialDatetime = () => {
        if(this.props.pedido.cart.length > 0 && this.props.pedido.date){
            return this.props.pedido.date;
        } else {
            let currentDatetime:Date = new Date();
            //let currentDay: number = currentDatetime.getDate();
            //currentDatetime.setDate(currentDay + 2);
            return  currentDatetime;
        }
    }

    calculateMinDate = () => {
        //if(this.state.farm !== undefined){
            let currentDatetime:Date = new Date();
            let currentDay: number = currentDatetime.getDate();
            currentDatetime.setDate(currentDay+1);
            return currentDatetime;
        //}
    }

    disableDays = (date:any, festivos:Array<IFestivo>) => {
        return date.getDay() === 0 || date.getDay() === 6 || date.getDay() === 2 || date.getDay() === 5 || findIndex(festivos, function(o){return new Date(o.fecha).toDateString() === date.toDateString()})>-1?true:false;
    }

    controlExistingDatetime = () => {
        /*const {farm} = this.state;
        if(farm){
            let dateTimePicker : Array<any> = verifyDateTimeLimit(this.state.cartDate, farm.timeLimitPedido, farm.beforeTimeLimitPedido, farm.festivos);
            this.setState({
                cartDate: dateTimePicker[0],
                modalType: 1,
                inputText: `¡ATENCIÓN! La fecha de entrega se ha modificado para el ${GetFormattedPedidoDate(dateTimePicker[0])}.`,
                creationModalOpen: dateTimePicker[1]
            })
            this.props.updateCartDate(dateTimePicker[0]);
        }*/
    }

    componentDidMount= async ()=> {
        this.handleMyInfoChange();
    }

    componentDidUpdate(prevProps: ICestaProps) {
        if(prevProps.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
        else if (this.props.farmRef !== prevProps.farmRef || this.props.dataareaid !== prevProps.dataareaid) {
            this.handleMyInfoChange();
        }
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo);

        this.setState({
            behaviour: GetBehaviour(this.props.pedido, this.props.myInfo)
        }, ()=>this.loadData());
        
    }

    loadData = async () => {
        const {myInfo, allowed} = this.props;
        //console.log('data', allowed)

        if(!allowed)
            return;
    
        const d = new Date().setDate(new Date().getDate() + 1)
        this.handleDatePickerChange('', d)
        this.controlExistingDatetime();
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleDatePickerControl = (inputDate: any) => {
        /*const {farm} = this.state;

        if(farm){
            let dateTimePicker : Array<any> = verifyDateTimeLimit(inputDate, farm.timeLimitPedido, farm.beforeTimeLimitPedido, farm.festivos);
        
            this.setState({
                cartDate: dateTimePicker[0],
                modalType: 1,
                inputText: `¡ATENCIÓN! La fecha de entrega se ha modificado para el ${GetFormattedPedidoDate(dateTimePicker[0])}.`,
                creationModalOpen: dateTimePicker[1]
            })
            this.props.updateCartDate(dateTimePicker[0]);
        }*/
    }

    savePedido = async (userId:string, action:number, data:any) => {
        let response = await OrderService.saveData(userId, action, {pedidos: data});

        if([-1,0].indexOf(response) > -1){
            this.setState({
                inputText: Translate(this.props.lang, "label.cesta.01"),
                modalType: 1,
                creationModalOpen: true,
                titleModal: Translate(this.props.lang, "label.general.01").toUpperCase(),
                loadingModal: false
            });
        }
        else {
            this.setState({
                loadingModal: false
            })
            this.props.resetPedido();
            this.props.navigate('/purchases');
        }
    }


    handleCommentClick = () => {
        this.setState({ openCommentDialog: !this.state.openCommentDialog });
    }

    handleCloseComment = () => {
        this.setState({ openCommentDialog: false });
    }

    handleDatePickerChange = (id:string, e: any) => {
        this.props.updateCartDate(e)
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Tramita el pedido

                let clone = cloneDeep(this.props.pedido);
                clone.estado = 10;

                const {myInfo} = this.props;

                if(!myInfo)
                    return;

                let pedidos:Array<IPedido> = [];
                clone.farmers.map((farmer:IFarmer)=>{
                    let pedido = cloneDeep(clone);
                    pedido.id = `${makeid(10)}`;
                    pedido.cart = filter(pedido.cart, function(o){return o.farmer.id == farmer.id});
                    pedido.farmer = farmer
                    pedido.invoice = this.calculatePrice(pedido.cart);
                    pedido.totalProducts = pedido.cart.length;
                    pedidos.push(pedido);
                });

                this.setState({
                    loadingModal: true
                },()=>this.savePedido(myInfo.userId, 1, pedidos));               
                break;
        }
    }

    handleChangeQuantity=(evt:any, product:IProduct)=>{
        const value = evt.target.value;

        if(isNaN(value))
            return;

        const quantity = Math.floor(value);

        let p_products: Array<IProduct> = cloneDeep(this.props.pedido.cart);

        const idx = findIndex(p_products, function(item:IProduct){return item.id == product.id})

        // Allow 0 value for suplier in case of no stock
        if([2].indexOf(this.state.behaviour) > -1)
            product.minimunQuantity = 0;

        if(idx > -1){   
            product.quantity = 
                quantity && quantity >= product.minimunQuantity?
                    quantity
                :
                    product.minimunQuantity;

            product.quantitySend = product.quantity;
            product.quantityAsked = product.quantity;
            p_products[idx] = product;
            this.props.updateCart(product);
        }
    }

    
    handleAddUnit=(product:IProduct)=>{
        let p_products: Array<IProduct> = cloneDeep(this.props.pedido.cart);

        const idx = findIndex(p_products, function(item:IProduct){return item.id == product.id})

        if(idx > -1){   
            product.quantity = 
                product.quantity?
                    product.quantity + 1
                :
                    product.minimunQuantity + 1;
            
            product.quantitySend = product.quantity;
            product.quantityAsked = product.quantity;

            p_products[idx] = product;
            
            this.props.updateCart(product);
        }
    }

    handleRemoveUnit=(product:IProduct)=>{
        let p_products: Array<IProduct> = cloneDeep(this.props.pedido.cart);

        const idx = findIndex(p_products, function(item:IProduct){return item.id == product.id})

        if(idx > -1){   
            product.quantity = 
                product.quantity?
                    (product.quantity > product.minimunQuantity?
                        product.quantity - 1
                    :
                        product.minimunQuantity
                    )
                :
                    product.minimunQuantity;
            
            product.quantitySend = product.quantity;
            product.quantityAsked = product.quantity;
            p_products[idx] = product;
            this.props.updateCart(product);
        }
    }

    products = (behaviour:number, lang:string) => {
        const searchValue = this.state.searchValue;

        let ret = filter(this.props.pedido.cart, function(o:IProduct){return o.name.toLowerCase().includes(searchValue.toLowerCase())}).map((item:IProduct, idx:number)=>{

            // Read only
            let readonly = true;
            if([1,2].indexOf(this.state.behaviour) > -1)
                 readonly = false;

            item.onAddClick = ()=>this.props.addOrRemoveFromCart(item)
            item.onRemoveClick = (!readonly?()=>this.props.addOrRemoveFromCart(item):undefined)
            item.onChangeQuantity = (!readonly?(evt:any)=>this.handleChangeQuantity(evt, item):undefined)
            item.onAddUnitClick = (!readonly?()=>this.handleAddUnit(item):undefined)
            item.onRemoveUnitClick = (!readonly?()=>this.handleRemoveUnit(item):undefined)
            //item.quantity = (item.isInCart?item.quantity:item.minimunQuantity)

            
            return(
                <ProductItem
                    style={{}}
                    index={idx}
                    data={[item, lang]}
                />
            )
        });
        
        return (
            <div style={{
                position: 'absolute',
                top: 300,
                left: 0,
                right: 0,
                bottom: 30,
                overflowY: 'auto'
            }}>
                <div className='dynamic-grid'>
                    {ret}
                </div>
            </div>

        );
    }

    calculatePrice=(cart:Array<IProduct>)=>{
        if(cart.length == 0)
            return "0.00";
        const price:number = cart.map((item:IProduct) => ((item.quantity?item.quantity:1) * item.base_price) + ((item.quantity?item.quantity:1) * item.base_price*(item.iva/100))).reduce((prev:number, next:number) => prev + next);
        return price.toFixed(2);
    }

    handleConfirmOrder = (pedido: IPedido) => {

        let text = Translate(this.props.lang, "label.cesta.02", pedido.farmers[0].name);
        if(pedido.farmers.length > 1)
            text = Translate(this.props.lang, "label.cesta.03", pedido.farmers.length, pedido.farmers.length);

        this.setState({
            inputText: text,
            modalType: 2,
            typeAction: 1,
            creationModalOpen: true,
            titleModal: Translate(this.props.lang, "label.general.01").toUpperCase()
        })
    }

    render() {
        const {myInfo, version, navigate, pedido, params, lang } = this.props;
        const {loadbeauty, searchValue, behaviour } = this.state;

        if(myInfo == null)
            console.log('Usuario no autorizado');       
        
        let statusProperties : Array<string> = getStatusDescriptionColor(pedido.estado, lang)
        return(
            <div>
                {/*TutorialExplication()*/}
                {[1].indexOf(behaviour)>-1?
                    <Layout
                        navigate={navigate}
                        params={params}
                        placeholderSearch={(myInfo?Translate(myInfo.language,"label.placeholderSearch.02"):"")}
                        badgeAlarms={pedido.cart.length}
                        version={version}
                        searchValue={searchValue}
                        me={myInfo}
                    />
                :
                    null
                }

                {loadbeauty || !myInfo?
                    <div style={{marginLeft:'50%',marginTop:'10%'}}>
                        <Loader loading={this.state.loading}/>
                    </div>

                :
                    pedido.cart.length == 0?
                        <div style={{textAlign: 'center',marginTop:'150px'}}>
                            <h3>{`${Translate(myInfo.language, "label.basket.empty")}`}</h3>
                            <img
                            alt="Cesta"
                            src={orderIcon}
                            style={{ height:'20%', width:'20%'}}
                            />
                            <div>
                                <button className='button-do-order' style={{width:'200px'}} onClick={()=>navigate('/')}>
                                    {`${Translate(myInfo.language, "label.basket.go")}`}
                                </button>
                            </div>
                        </div>
                    :
                        <div>
                            <div style={{
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                border: '2px solid #ddd',
                                borderRadius: '15px',
                                margin: '5px',
                                width: 'calc(100% - 0px)!important',
                                position: 'relative',
                                boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
                            }}>
                                <div>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr style={{width:'100%'}}>
                                                <td    style={{width:'60%',maxWidth:'120px', overflowX:'auto'}}>
                                                    {[1].indexOf(behaviour) > -1?
                                                        <p>
                                                            <a style={{ color: '#284770', fontWeight:'bold' }}>{`${Translate(myInfo.language, "label.order.new")}`}</a>
                                                        </p>
                                                    :
                                                        <p style={{maxWidth:'120px'}}>
                                                            <a style={{ color: '#284770', fontWeight:'bold' }}>{`${Translate(myInfo.language, "label.order.reference")}: `}</a>
                                                            <a style={{color:'#284770',}}>{`${pedido.id}`}</a>
                                                        </p>
                                                    }
                                                    
                                                    <p>
                                                        <a style={{ color: '#284770', fontWeight:'bold' }}>{`${Translate(myInfo.language, "label.order.cost")}: `}</a>
                                                        {[1].indexOf(behaviour) > -1?
                                                            <a style={{color:'#284770',}}>{`${addThousandsAndReplaceDecimal(this.calculatePrice(pedido.cart), ',')} €`}</a>
                                                        :
                                                            <a style={{color:'#284770',}}>{`${pedido.invoice}`}</a>
                                                        }
                                                    </p>
                                                    {/*<b style={{ color: '#284770' }}>{this.props.idPedido?this.props.idPedido:'Nuevo pedido'}</b>
                                                    <br />*/}
                                                </td>
                                                <td  style={{width:'100%', paddingRight:0, paddingTop:'15px', display:'flex'}}>
                                                    <div style={{maxWidth:'130px', marginLeft:'auto'}}>
                                                        {DatePicker('date', `${Translate(myInfo.language, "label.order.dateDelivery")}`,"dd/MM/yyyy",this.handleDatePickerChange,this.props.pedido.date,([1].indexOf(behaviour) > -1?false:true),(date:any)=>this.disableDays(date, []),this.calculateMinDate())}
                                                    </div>
                                                    <IconButton size='small' onClick={this.handleCommentClick} style={{ float: 'left' }}>
                                                        <CommentIcon />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    {[0].indexOf(behaviour) > -1?
                                                        <p>
                                                            <a style={{ color: '#284770', fontWeight:'bold' }}>{`${Translate(myInfo.language, "label.order.state")}: `}</a>
                                                            <a style={{color:'#284770',}}>{`${statusProperties[0]}`}</a>
                                                        </p>
                                                    :
                                                        null
                                                    }
                                                </td>
                                            </tr>
                                            {/*<tr style={{width:'100%'}}>
                                                <td colSpan={2} style={{width:'100%'}}>
                                                    <IconButton onClick={this.handleCommentClick} style={{ float: 'right' }}>
                                                        <CommentIcon />
                                                    </IconButton>
                                                </td>
                                                    </tr>*/}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    {[1].indexOf(behaviour) > -1?
                                        <button className='button-do-order' onClick={()=>this.handleConfirmOrder(pedido)}>
                                            {`${Translate(myInfo.language, "label.order.do")}`}
                                        </button>
                                    :
                                        [2].indexOf(behaviour) > -1?
                                            <div style={{marginTop:'0px', display:'flex'}}>
                                                <button className='button-do-order'>
                                                    {`${Translate(myInfo.language, "label.order.accept")}`}
                                                </button>
                                                <button className='button-do-order'>
                                                    {`${Translate(myInfo.language, "label.order.cancel")}`}
                                                </button>
                                            </div>

                                        :
                                            null
                                    }

                                </div>
                                <Dialog fullScreen={this.props.fullScreen} open={this.state.openCommentDialog} onClose={this.handleCloseComment} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">{`${Translate(myInfo.language, "label.order.comment.01")}`}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            {`${Translate(myInfo.language, "label.order.comment.02")}`}
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            multiline
                                            margin="dense"
                                            id="name"
                                            label={`${Translate(myInfo.language, "label.order.comment.01")}`}
                                            type="text"
                                            fullWidth
                                            value={this.props.pedido.comments}
                                            onChange={this.props.updateCartComments}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCloseComment} color="primary">
                                            {`${Translate(myInfo.language, "label.order.done")}`}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            {this.products(behaviour, lang)}
                        </div>
                }              
                    
                <CreateModal
                    onCloseCreationModal={this.onCloseCreationModal}
                    isOpen={this.state.creationModalOpen}
                    inputText={this.state.inputText}
                    typeModal={this.state.modalType}
                    action={this.handleCreateModal}
                    typeAction={this.state.typeAction}
                    loadingModal={this.state.loadingModal}
                    titleModal={this.state.titleModal}
                />
            </div>
        );

            /*return (
                <Summary
                    history={this.props.history}
                    farm={this.state.farm}
                    cart={this.props.cart}
                    cartDate={this.state.cartDate}
                    cartComments={this.props.cartComments}
                    goBack={this.goBack}
                    status={-1}
                    pedidoId={this.props.idPedido}
                    pedido={undefined}
                    resetCart={this.props.resetCart}
                    updateCart={this.props.updateCart}
                    updateQuantityCart={this.props.updateQuantityCart}
                />
            );*/
    }
}
export default withParamsAndNavigate(Cesta);