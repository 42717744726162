import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {} from '../ApiClients/DummyData';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import {onAuthStateChanged, signOut } from '../Library/sp_firebase';

//import {GetLogoEmpresa} from '../Library/helpers';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});


interface IHomeProps {
    version: string;
    params?:any;
    navigate?:any;
    myInfo:IMe;
}

interface IHomeState {
    loading: boolean;
}

class Home extends Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount=()=>{

    }

    componentDidUpdate = (prevprops:IHomeProps) => {
        //if(prevprops.params.farmerRef !== this.props.params.farmerRef)

    }

    goToFeature=(id: string)=>{
        //this.props.history.push(`/project/${id}/manage`);
        const {params, navigate} = this.props;
        navigate(`/${id}`);
    }

    render() {
        const {version, navigate, myInfo } = this.props;
        const {loading } = this.state;

        if(!myInfo)
            return(``);

        return (
            <div style={{position:'relative', height:'100vh', width:'100%'}}>
                <div className='login-image-background' style={{backgroundImage: `url(${background})`}}></div>
                <div style={{position:'absolute', top:20, textAlign:'center', width:'100%'}}>
                    <div style={{padding:20, textAlign:'justify'}}>
                        <p style={{lineHeight:2}}>
                            <a>{`Hola `}</a><a style={{fontWeight:'bold'}}>{`${myInfo.displayName}`}</a><a>{`, tu usario está desactivado, vuelva a intentarlo o contacte con el administrador.`}</a>
                        </p>
                    </div>
                </div>
                <div className='form_login'>
                    <button className='login-content' onClick={()=>window.location.replace(window.location.origin)}>
                        Volver a intentar
                    </button>
                    <button className='btn-signout' onClick={()=>signOut()}>
                        Cerrar sesión
                    </button>
                </div>

            </div>
        );
    }
}

export default withParamsAndNavigate(Home);