import React, { Component } from 'react';
import { IPedido, IProduct, IMe } from '../Library/types';
import OrderService from '../ApiClients/OrderService';
//import Summary from './Summary';
import Layout from './Layout';
import Loader from './Material/Loader';
import { searchPedidos, GetViewDefaultType } from '../Library/helpers';
import {readLocalStorage, writeLocalStorage} from '../Library/sp_general';
import { filter, find } from 'lodash';
import { withParamsAndNavigate } from './Material/ReactRouter';
import CreateModal from './Material/CreateDialog';
import DatePicker from './Material/DatePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Purchase from '../Components/Purchase';
import Sale from '../Components/Sale';
import Translate from './Material/Traductor';

interface IPedidoDetailProps {
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    classes:any;
    checkPermission:Function;
    updateCart: Function;
    allowed:boolean;
    type: number;
    lang:string;
}

interface IPedidoDetailState {
    pedido?: IPedido;
    loading: boolean;
    loadbeauty: boolean;
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;
    searchValue: string;
    viewType: number;
    openCommentDialog:boolean;
}

class PedidoDetail extends Component<IPedidoDetailProps, IPedidoDetailState>  {
    constructor(props: IPedidoDetailProps) {
        super(props);
        this.state = {
            openCommentDialog: false,
            loading: true,
            loadbeauty: false,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue: '',
            viewType: GetViewDefaultType()
        };
    }

    componentDidMount() {
        this.handleMyInfoChange();
    }

    componentDidUpdate(prevProps: IPedidoDetailProps) {
        if(prevProps.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
        else if (this.props.params.pedidoRef !== prevProps.params.pedidoRef) {
            this.handleMyInfoChange();
        }
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo);

        this.loadData();
    }

    loadData = () => {
        const {myInfo, allowed} = this.props;

        if(!allowed)
            return;
    
        this.loadPedidoDetails();
    }

    loadPedidoDetails = async () => {
        const {myInfo, params, type} = this.props;
        let pedidoRef = params.pedidoRef;
        if(myInfo && pedidoRef) {
            let response = await OrderService.getPedido(myInfo.userId, pedidoRef, type);
            this.setState({ pedido: response, loading: false, loadbeauty: false });
        }

        /*let read:any = readLocalStorage('pedidos');
        if(read == null)
            read = [];
        else
            read = JSON.parse(read);

        let dale:any = find(read, function(o:IPedido){return o.id == pedidoRef})*/

    }

    calculateMinDate = () => {
        //if(this.state.farm !== undefined){
            let currentDatetime:Date = new Date();
            let currentDay: number = currentDatetime.getDate();
            currentDatetime.setDate(currentDay+1);
            return currentDatetime;
        //}
    }

    disableDays = (date:any, festivos:any) => {
        return false;
    }

    handleDatePickerChange = (id:string, e: any) => {

    }

    handleCommentClick = () => {
        this.setState({ openCommentDialog: !this.state.openCommentDialog });
    }

    handleCloseComment = () => {
        this.setState({ openCommentDialog: false });
    }

    render() {
        const {myInfo, version, navigate, classes, params, updateCart, checkPermission, allowed, type, lang } = this.props;
        const {loadbeauty, searchValue, viewType, loading, pedido } = this.state;

        return(
            <div>
                {/*TutorialExplication()*/}
                <Layout
                    navigate={navigate}
                    params={params}
                    placeholderSearch={""}
                    badgeAlarms={0}
                    version={version}
                    searchValue={searchValue}
                    me={myInfo}
                />

                {loading?
                    <div style={{marginLeft:'50%',marginTop:'10%'}}>
                        <Loader loading={this.state.loading}/>
                    </div>
                    :      
                        !pedido?
                            <div style={{textAlign: 'center', marginTop:'10%' }}>
                                <h4>{`${Translate(lang, "label.pedido.detail.01", params.pedidoRef)}`}</h4>
                            </div>
                        :  
                            type == 1?
                                <Purchase
                                    myInfo={myInfo}
                                    version={version}
                                    pedido={pedido}
                                    checkPermission={checkPermission}
                                    allowed={allowed}
                                    lang={lang}
                                />
                            :
                                <Sale
                                    myInfo={myInfo}
                                    version={version}
                                    pedido={pedido}
                                    checkPermission={checkPermission}
                                    allowed={allowed}
                                    lang={lang}
                                />
                }  
            </div>
        )
   
    }
}

export default (withParamsAndNavigate(PedidoDetail));