import React, { Component } from 'react';
import { IProduct, IMe, IPedido } from '../Library/types';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import OrderService from '../ApiClients/OrderService';
import Loader from './Material/Loader';
import { getProductDescriptionColor } from '../Library/helpers';
import { withParamsAndNavigate } from './Material/ReactRouter';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Translate from './Material/Traductor';

const styles = (theme: Theme) => createStyles({
    container: {
        position: 'absolute',
        top: 100,
        left: 0,
        right: 0,
        bottom: 30,
        overflowY: 'auto',
        padding: '10px',
        textAlign: 'center',
    },
    image: {
        maxWidth:'200px',
        width: '100%',
        height: 'auto',
        borderRadius:10
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
});

interface IProductDetailProps {
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    classes:any;
    pedido: IPedido;
    checkPermission:Function;
    allowed:boolean;
    lang:string;
}

interface IProductDetailState {
    product?: IProduct;
    loading: boolean;
}

class ProductDetail extends Component<IProductDetailProps, IProductDetailState>  {
    constructor(props: IProductDetailProps) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.handleMyInfoChange();
    }

    componentDidUpdate(prevprops: IProductDetailProps) {
        if(prevprops.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
        else if (this.props.params.productRef !== prevprops.params.productRef || this.props.params.dataareaid !== prevprops.params.dataareaid) {
            this.handleMyInfoChange();
        }
    }

    
    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo)
        this.loadData();
    }

    loadData = () => {
        const {myInfo, allowed} = this.props;

        if(!allowed)
            return;
    
        this.loadProductDetails();
    }

    loadProductDetails = async () => {
        let productRef = this.props.params.productRef;

        let product:IProduct = await OrderService.getProduct(productRef);

        this.setState({ product, loading: false});
    }

    render() {
        const { classes, navigate, pedido, version, myInfo, params, lang } = this.props;

        const { product } = this.state;

        if(myInfo == null)
            console.log('Usuario no autorizado');

        let typeProperties : Array<string> = product?getProductDescriptionColor(product.category_sale, lang):['','','']
        const imageB64 = product && product.image?new Buffer(product.image).toString("base64"):'';

        return (
            <div>
               <Layout
                    navigate={navigate}
                    params={params}
                    placeholderSearch={""}
                    badgeAlarms={pedido.cart.length}
                    version={version}
                    searchValue={''}
                    me={myInfo}
                />
                {product && myInfo?
                    <div className={classes.container}>
                        <h3>{product.name}</h3>
                        <div style={{position:'absolute', top:15, left:15}}>
                            <IconButton edge="end" aria-label="Comments" onClick={()=>navigate(-1)}>
                                <BackIcon />
                            </IconButton>
                        </div>
                        {/*<button className='button-back' style={{position:'absolute', top:20, left:5}} onClick={()=>navigate(-1)}>{`Volver`}</button>*/}
                        <div className={classes.promotion} style={{backgroundColor:typeProperties[1], color:typeProperties[2]}}>
                            {typeProperties[0]}
                        </div>
                        <div style={{textAlign:'center'}}>
                            <p>{`${Translate(lang, "label.product.detail.02")}: `}{product.farmer.name}</p>
                        </div>

                        <img
                            alt='' 
                            src={`${product.uri_img}`}
                            className={classes.image}
                        />

                        <p style={{textAlign:'justify', margin:20}}>
                            {product.description}
                        </p>
                    </div>
                :
                    this.state.loading?
                        <div style={{marginLeft:'50%',marginTop:'50%'}}>
                            <Loader loading={this.state.loading}/>
                        </div>
                    :
                        <div className={classes.container}>
                            <h3>{`${Translate(lang, "label.product.detail.01")}`}</h3>
                        </div>
                }
            </div>
        );
    }
}
export default withStyles(styles)(withParamsAndNavigate(ProductDetail));