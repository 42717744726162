//import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore, initializeFirestore} from "firebase/firestore";
import { getDatabase } from "firebase/database";
import {initializeAuth} from 'firebase/auth'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA93IiFAR3VFztLANO3N5qS8SE5nJ3BjnM",
  authDomain: "spo-agrir.firebaseapp.com",
  databaseURL: "https://spo-agrir-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "spo-agrir",
  storageBucket: "spo-agrir.appspot.com",
  messagingSenderId: "515062715461",
  appId: "1:515062715461:web:69b8f4f0bb2dc387ec3f2c",
  measurementId: "G-63PNY2BK03",
};

const settings = {ignoreUndefinedProperties:false};

// Initialize Firebase
//export const app = initializeApp(firebaseConfig);

//export const auth = initializeAuth(app);

export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
initializeFirestore(app, settings);
export const firestore = getFirestore(app);
export const db = getDatabase(app);

export const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);