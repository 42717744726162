function isNumber(inputCheck:any) {
  if(!isNaN(inputCheck) && inputCheck !==undefined && inputCheck !== null && inputCheck !=="") {
    return true
  }
  else {
    return false
  }
}

function getNumeric(input:string) {
  return String(input).replace(/[^\d]/g, "");
}

function getPositiveNegativeNumeric(input:string) {
  //return String(input).replace(/[^-?\d*\.{0,1}\d+$]/g, "");
  return String(input).replace(/[^-?\d*{0,1}\d+$]/g, "");
}

function replaceComma(input:any) {
  var result = String(input).replace(",",".");
  return result
}

function isPhoneNumber(input:string){
  let patt = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
  return patt.test(input);
}

function callPhone(number:string){
  window.open(`tel:${number}`, '_self')
}

function addZeroesDecimals(num:number, decimals:number) {
  let roundDecimals:string = num.toFixed(decimals);
  roundDecimals = roundDecimals.replace('.',',');
  return roundDecimals;
}

function validateEmail(email:string) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
}

function isJson(str:string) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

// **** DATES **** //

function GetFormattedDateTime(inputData:any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day)+"T00:00:00.000Z";
}

function GetDate(inputData:any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day);
}

function GetFormattedDate(inputData: any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year;
}

function GetDatetimeFormat(inputData: any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  var hour = todayTime.getHours();
  var minut = todayTime.getMinutes();

  return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year + " " + (hour < 10?'0'+hour:hour)+ ":" + (minut < 10?'0'+minut:minut)+"h";
}

function GetDatetimeFormat02(inputData: any) {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();
  var hour = todayTime.getHours();
  var minut = todayTime.getMinutes();
  var seconds = todayTime.getSeconds();

  return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year + " " + (hour < 10?'0'+hour:hour)+ ":" + (minut < 10?'0'+minut:minut)+":" + (seconds < 10?'0'+seconds:seconds);
}

function calcBusinessDays(dDate1:Date, dDate2:Date) { // input given as Date objects
  var iWeeks, iDateDiff, iAdjust = 0;
  if (dDate2 < dDate1) return -1; // error code if dates transposed
  var iWeekday1 = dDate1.getDay(); // day of week
  var iWeekday2 = dDate2.getDay();
  iWeekday1 = (iWeekday1 == 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
  iWeekday2 = (iWeekday2 == 0) ? 7 : iWeekday2;
  if ((iWeekday1 > 5) && (iWeekday2 > 5)) iAdjust = 1; // adjustment if both days on weekend
  iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
  iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;

  // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
  iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)

  if (iWeekday1 < iWeekday2) { //Equal to makes it reduce 5 days
    iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1)
  } else {
    iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2)
  }

  iDateDiff -= iAdjust // take into account both days on weekend

  return (iDateDiff);
}

// **** LOCALSTORAGE **** //

function writeLocalStorage(title:string, value:any){
  try{
    localStorage.setItem(title, value);
    return true;
  } catch(err){
    return false;
  }
}

function readLocalStorage(title:string){
  try{
    return localStorage.getItem(title);
  } catch(err) {
    return undefined;
  }
}

function removeItemLocalStorage(title:string){
  try{
    return localStorage.removeItem(title);
  } catch(err) {
    return undefined;
  }
}

function getObjects(obj:any, key:any, val:any, newVal:any) {

  for (var i in obj) {

    
      if (!obj.hasOwnProperty(i)) continue;
      if (i == key && obj[key] == val) {
          obj[key] = newVal;
      }
  }
  return obj
}

const findAndModifyFirst = (tree:any, objToFindBy:any, replacementObj:any) => {
  //let treeToReturn = tree;
  let findSuccess = false;

  //let modifiedObj = false;
  //const findKeys = Object.keys(objToFindBy);
  for(let i=0;i<tree.length;i++){
    if(tree[i].id == objToFindBy.id){
      findSuccess = true;

      // Mantiene el campo children como el original, solo queremos que afecte a los datos del objeto y no a su jerarquía
      if(tree[i].hasOwnProperty('children')){
        const schildren = tree[i].children;
        tree[i] = replacementObj;
        tree[i].children = schildren;
      } else {
        tree[i] = replacementObj;
      }

      break;
    } else if(tree[i].hasOwnProperty('children')){
      let ret:any = findAndModifyFirst(tree[i].children, objToFindBy, replacementObj);
      findSuccess = ret[0];

      if(findSuccess)
        break;

    }
  }

  return [findSuccess, tree]; 
  /*if (findSuccess) {
    for (let prop in tree) {
      delete tree[prop];
    }
    for (let prop in replacementObj) {
      tree[prop] = replacementObj[prop];
    }
    return tree;
  }
  const findInChildren = (obj:any, childrenKey:any, objToFindBy:any, replacementObj:any) => {
    if (obj.hasOwnProperty(childrenKey)) {
      for (let i = 0; i < obj[childrenKey].length; i++) {
        findKeys.forEach((key) => {
          isEqual(obj[childrenKey][i][key], objToFindBy[key]) ? findSuccess = true : findSuccess = false;
        });
        if (findSuccess) {
          obj[childrenKey][i] = replacementObj;
          modifiedObj = true;
          break;
        }
      }
      if (!findSuccess) {
        obj[childrenKey].forEach((child:any) => findInChildren(child, childrenKey, objToFindBy, replacementObj));
      }
    }
    return obj;
  };
  findInChildren(tree, childrenKey, objToFindBy, replacementObj);
  return modifiedObj ? treeToReturn : false;*/
};

const removeAccents = (str:any) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
} 

const GetLocaleLanguage = () => {
  return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
}

const changeTimeZone = (date:string, timeZone: string) => {

  const timezoneTypes:Array<any> = [
    {
      id: 'Romance Standard Time', timezones: ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Europe/Paris']
    }
  ];

  let language = GetLocaleLanguage();

  let timezone:any = timezoneTypes.find(function(o:any){return o.id == timeZone});

  let timezone2apply:string = timezone?timezone.timezones[0]:'Europe/Madrid';

  if (typeof date === 'string') {
    return new Date(date).toLocaleString(language, { timeZone: timezone2apply });
  }

  return date;
}

export {
  isNumber, replaceComma, writeLocalStorage, readLocalStorage,
  removeItemLocalStorage, addZeroesDecimals, GetFormattedDateTime,
  GetDate, calcBusinessDays, GetFormattedDate, GetDatetimeFormat,
  getNumeric, getPositiveNegativeNumeric, getObjects, findAndModifyFirst,
  removeAccents, validateEmail, isJson, GetDatetimeFormat02, changeTimeZone,
  isPhoneNumber, callPhone
}