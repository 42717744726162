import React from 'react';
import { adalApiFetch } from './adalConfig';
import appData from '../ApiClients/appData';
import { filter, split, reduce, find, cloneDeep, groupBy } from 'lodash';
import { IData, IMarker, IObjects, IIData, IFarmer, IProduct, IPedido } from './types';
import {GetFormattedDate, readLocalStorage, writeLocalStorage} from './sp_general';

import {inflateSync} from 'react-zlib-js';
import { Buffer } from 'buffer';
import Translate from '../Components/Material/Traductor';

const fetchAPI_old = (
    url: string,
    method: string,
    parseJSON = false,
    body: any = undefined,
    headers = true
): Promise<any> => {
    return adalApiFetch(fetch, url, {
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: body ? JSON.stringify(body) : body,
        headers: headers
            ? new Headers({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8',
            })
            : undefined
    }).then((response: any) => {
        if (response.ok) {
            if (parseJSON) {
                return response.json();
            } else {
                return response.text();
            }
        } else if (!parseJSON) {
            throw new Error(`Error ${response.status}`);
        } else {
            try {
                return response.json().then((data: any) => {
                    console.log('Error!');
                    return Promise.reject(new Error(data.message));
                });
            } catch (err) {
                throw new Error(`Error ${response.status}`);
            }
        }
        return response;
    });
}

const fetchAPI = (
    url: string,
    method: string,
    parseJSON = false,
    body: any = undefined,
    headers = true
): Promise<any> => {
    return fetch(url, {
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: body ? JSON.stringify(body) : body,
        headers: headers
            ? new Headers({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8',
                'authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJwcnVuZXJhb3Jpb2xhc2VyZ2lAZ21haWwuY29tIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl0sImV4cCI6MTY3NzQwODUyNiwidXNlcklkIjoiMjcyNTMxNTgiLCJhdXRob3JpdGllcyI6WyJST0xFX1BSRU1JVU0iXSwianRpIjoiMWJhZTE0MWItNzhhZC00YWE2LTg3ZTItYTdhODg5NTAzMGUzIiwiY2xpZW50X2lkIjoiZnJvbnRlbmQtYWJhd2ViYXBwcyJ9.J4kU7E8ey74x0R7rCl1QgO47_810Td8myqhvpTnku4Q'
            })
            : undefined
    }).then((response: any) => {
        if (response.ok) {
            if (parseJSON) {
                return response.json();
            }
        } else if (!parseJSON) {
            throw new Error(`Error ${response.status}`);
        } else {
            try {
                return response.json().then((data: any) => {
                    console.log('Error!');
                    return Promise.reject(new Error(data.message));
                });
            } catch (err) {
                throw new Error(`Error ${response.status}`);
            }
        }
        return response;
    });
}


const zLibInflate=(data:any)=>{
  const buff:any = Buffer.from(data,'base64');

  const output = JSON.parse(inflateSync(buff).toString());

  return output;
}

const GetFormattedSqlDate = (inputData:string, type:number) => {
  var datime = new Date(inputData);
  var month = datime.getMonth() + 1;
  var day = datime.getDate();
  var year = datime.getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day)+` ${type==1?'00:00:00':'23:59:59'}`;
}

function addThousandsAndReplaceDecimal(number:string|number, separatorDecimal:string){
  //let newNumber = String(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  let newNumber = String(number);
  newNumber = newNumber.replace('.',separatorDecimal);
  newNumber = newNumber.replace(',',separatorDecimal);
  newNumber = newNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return newNumber;
}

function addZeroes(num:number) {
  return num.toFixed(Math.max(((num+'').split(".")[1]||"").length, 2));
}

const searchShops = (inputData:Array<IFarmer>, value: string) => {
  let lowerCaseValue = value.toLowerCase();
  let arrayValues : Array<string> = splitData(lowerCaseValue);
  let Shops : Array<IFarmer> = inputData;

  arrayValues.forEach(element => {
      Shops = filter(Shops,function(item){
      return item['name'].toLowerCase().indexOf(element)!==-1;
      });
  });

  return Shops;
}

const searchProducts = (inputData:Array<IProduct>, value: string, allProducts: boolean, lang:string) => {
  let lowerCaseValue = value.toLowerCase();
  let arrayValues : Array<string> = splitData(lowerCaseValue);
  let Products : Array<IProduct> = inputData;

  arrayValues.forEach(element => {
      Products = filter(Products,function(item){
      return item['name'].toLowerCase().indexOf(element)!==-1||
      getProductDescriptionColor(item['productType'], lang)[0].toLowerCase().indexOf(element)!==-1;
      });
  });

  if(allProducts!==true) {
      Products = filter(Products,function(item){
          return item['productType']!==0 || item['quantity']!==undefined
      });
  } 

  return Products;
}

const searchPedidos = (inputData:Array<IPedido>, value: string, lang:string) => {
  let lowerCaseValue = value.toLowerCase();
  let arrayValues : Array<string> = splitData(lowerCaseValue);
  let Pedidos : Array<IPedido> = inputData;

  arrayValues.forEach(element => {
      Pedidos = filter(Pedidos,function(item){
          if(item['id'] !== undefined && item['shop'] !== undefined) {
              return item['shopRef'].toLowerCase().indexOf(element)!==-1 || 
              item['id'].toLowerCase().indexOf(element)!==-1 ||
              item['shop']['name'].toLowerCase().indexOf(element)!==-1 ||
              getStatusDescriptionColor(item['status'], lang)[0].toLowerCase().indexOf(element)!==-1;
          } else {
              return item['shopRef'].toLowerCase().indexOf(element)!==-1
          }

      });
  });

  return Pedidos;
}

const splitData = (input:string) => {
  return split(input,' ');
}

const GetFormattedPedidoDate = (inputData: any) => {
  var todayTime = new Date(inputData);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime .getFullYear();
  return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year;
}

const verifyDateTimeLimit=(inputData:any, maxHour: number)=>{

  let currentDatetime:Date = new Date();
  let selectedDate : Date = new Date (inputData);

  let wasChanged = false;
  const verifyDay = (inputDay: Date) => {
      if(inputDay.getDay() !== 0){
          return true;
      } else {
          return false;
      }
  }

  const verifyHour = (inputDay: Date, maxHour: number, currentDate: Date) => {

      if((inputDay.getHours()*3600+(inputDay.getMinutes()*60)) < maxHour && (currentDate.getHours()*3600+(currentDate.getMinutes()*60)) < maxHour || maxHour === 0){
          return true;
      } else {
          return false;
      }
  }

  let currentDay: number = currentDatetime.getDate();
  let currentMonth: number = currentDatetime.getMonth()+1;
  let currentYear: number = currentDatetime.getFullYear();
  currentDatetime.setDate(currentDay + 1);
  let nextDay : number = currentDatetime.getDate();

  let selectedDay: number = selectedDate.getDate();
  let selectedMonth: number = selectedDate.getMonth()+1;
  let selectedYear: number = selectedDate.getFullYear();

  let tmpCurrentDatetime = new Date(currentYear+"/"+currentMonth+"/"+currentDay);
  let tmpSelectedDatetime = new Date(selectedYear+"/"+selectedMonth+"/"+selectedDay);

  // Verifica si la fecha introducida es igual o mayor a la fecha actual
  if(tmpSelectedDatetime >= tmpCurrentDatetime == true){
              selectedDate = selectedDate
  } else {
      selectedDate = currentDatetime
      wasChanged = true;
  }
  
  // Verifica que no esté en domingo, que no puedas pedir para el día siguiente si se han cumplido las 21h de la noche
  while(verifyDay(selectedDate) !== true || verifyHour(selectedDate, maxHour, currentDatetime) !== true && selectedDate.getDate() == currentDay || verifyHour(selectedDate, maxHour, currentDatetime) !== true && selectedDate.getDate() == nextDay) {
      // En caso de estar en el mismo día y se han cumplido las 21h, suma 2 días
      if(verifyHour(selectedDate, maxHour, currentDatetime) !== true && selectedDate.getDate() == currentDay) {
          selectedDate.setDate(selectedDate.getDate() + 2)
          wasChanged = true;
      } 
      // En caso contrario solo suma 1 día
      else {
          selectedDate.setDate(selectedDate.getDate() + 1)
          wasChanged = true;
      }
  }
  return [selectedDate, wasChanged];
}

const getStatusDescriptionColor = (status: number, lang:string) =>{
  switch(status) {
      case 0:
          return([Translate(lang, "label.StatusDescription.01"),'#f5f542','#555']);

      case 10:
          return([Translate(lang, "label.StatusDescription.02"),'#4287f5','#ffffff']);

      case 20:
          return([Translate(lang, "label.StatusDescription.03"),'#42e6f5','#ffffff']);

      case 40:
          return([Translate(lang, "label.StatusDescription.04"),'#2bcf57','#ffffff']);

      case 50:
          return([Translate(lang, "label.StatusDescription.05"),'#60f542','#ffffff']);

      case 98:
          return([Translate(lang, "label.StatusDescription.06"),'#f5a442','#ffffff']);

      case 99:
          return([Translate(lang, "label.StatusDescription.07"),'#f54242','#ffffff']);

      default:
          return([Translate(lang, "label.StatusDescription.08"),'#ffffff','#555']);
  }
}

const getProductDescriptionColor = (productType: number, lang:string) =>{
  //Retorna la descripción [0], color background [1] y color letras [2]
  switch(productType) {
      // Artículos habituales
      case 10:
          return(['','','']);
      // Artículos en oferta
      case 20:
          return([Translate(lang, "label.ProductDescription.01"),'#f5a442','#ffffff']);
      // Artículos en promoción
      case 30:
          return([Translate(lang, "label.ProductDescription.02"),'#f5ef42','#ffffff']);
      // Artículos histórico
      case 40:
          return(['','','']);
      // Artículos ofertas flash
      case 70:
          return([Translate(lang, "label.ProductDescription.03"),'#f00','#ffffff']);

      default:
          return([Translate(lang, "label.ProductDescription.04"),'#ffffff','#555']);
  }
}

const GetViewDefaultType=()=>{
    if(window.innerWidth >= 1024)
        return 1;
    return 2;
}

const RemoveFunctions=(json:Object)=>{
    return JSON.parse(JSON.stringify(json));
}

const GetLanguage = (key:string) => {
    const lang = readLocalStorage(key);
    return (lang == null || lang == undefined?'cat':lang);
}
export { 
  fetchAPI, getProductDescriptionColor, getStatusDescriptionColor,
  GetFormattedSqlDate,
  verifyDateTimeLimit, searchShops, zLibInflate,
  searchProducts, addThousandsAndReplaceDecimal, addZeroes,
  searchPedidos,
  GetFormattedPedidoDate, GetViewDefaultType,
  RemoveFunctions, GetLanguage
}