import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import { IFarmer, IFestivo, IMe, IPedido, IProduct, IProject } from '../Library/types';
import IconButton from '@material-ui/core/IconButton';
import { cloneDeep, findIndex, sortBy, find, filter } from 'lodash';
import OrderService from '../ApiClients/OrderService';
import ProductsIcon from '@material-ui/icons/PlaylistAddOutlined'
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DoIcon from '@material-ui/icons/OpenInNewOutlined';
//import Summary from './Summary';
import orderIcon from '../Assets/images/cargas.png';
import {verifyDateTimeLimit, GetFormattedPedidoDate, getStatusDescriptionColor, addThousandsAndReplaceDecimal, RemoveFunctions } from '../Library/helpers'
import Loader from './Material/Loader';
import CreateModal from './Material/CreateDialog';
import DatePicker from './Material/DatePicker';
import { withParamsAndNavigate } from './Material/ReactRouter';
import ProductItem from './ProductListItem02';
import {readLocalStorage, writeLocalStorage } from '../Library/sp_general'
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import Translate from './Material/Traductor';


const styles = (theme: Theme) => createStyles({
    itemDistribucion: {
        border: '2px solid #ccc',
        borderRadius: '15px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        padding: '5px',
        position: 'relative',
    },
    tablecell: {
        fontSize: 11
    }
});

function makeid(length:number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function GetBehaviour(pedido:IPedido, me:IMe){
    if(me.profile == 10 && pedido.id.length == 0)
        return 1;
    if(me.profile == 20 && pedido.id.length > 0 && pedido.estado == 10)
        return 2;
    return 0;
}

interface ICestaProps {
    history: any;
    match: any;
    pedido: IPedido;
    addOrRemoveFromCart: Function;
    farmRef?: string;
    dataareaid?:string;
    updateCartDate: (date: any, value?: string | null | undefined) => void;
    fullScreen?: boolean;
    updateCartComments?: any;
    idPedido?: number;
    resetCart: Function;
    classes:any;
    updateQuantityCart:Function;
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    resetPedido: Function;
    checkPermission:Function;
    allowed:boolean;
    lang:string;
}

interface ICestaState {
    openCommentDialog: boolean;
    summaryMode: boolean;
    inputText: any;
    creationModalOpen: boolean;
    modalType: number;
    typeAction: number;
    loadingModal: boolean;
    loading: boolean;
    titleModal:string;
    selectedProduct?:IProduct;
    loadbeauty: boolean;
    searchValue: string;
    behaviour: number;
    e_pedido:IPedido;
}

class Cesta extends Component<ICestaProps, ICestaState> {
    constructor(props: ICestaProps) {
        super(props);
        this.state = {
            openCommentDialog: false,
            summaryMode: false,
            inputText:'',
            creationModalOpen: false,
            modalType: -1,
            typeAction: -1,
            loadingModal: false,
            loading: true,
            titleModal:'MENSAJE',
            loadbeauty: false,
            searchValue: '',
            behaviour: 0,
            e_pedido: cloneDeep(props.pedido)
        };
    }

    calculateMinDate = () => {
        //if(this.state.farm !== undefined){
            let currentDatetime:Date = new Date();
            let currentDay: number = currentDatetime.getDate();
            currentDatetime.setDate(currentDay+1);
            return currentDatetime;
        //}
    }

    disableDays = (date:any, festivos:Array<IFestivo>) => {
        return date.getDay() === 0 || date.getDay() === 6 || date.getDay() === 2 || date.getDay() === 5 || findIndex(festivos, function(o){return new Date(o.fecha).toDateString() === date.toDateString()})>-1?true:false;
    }

    controlExistingDatetime = () => {
        /*const {farm} = this.state;
        if(farm){
            let dateTimePicker : Array<any> = verifyDateTimeLimit(this.state.cartDate, farm.timeLimitPedido, farm.beforeTimeLimitPedido, farm.festivos);
            this.setState({
                cartDate: dateTimePicker[0],
                modalType: 1,
                inputText: `¡ATENCIÓN! La fecha de entrega se ha modificado para el ${GetFormattedPedidoDate(dateTimePicker[0])}.`,
                creationModalOpen: dateTimePicker[1]
            })
            this.props.updateCartDate(dateTimePicker[0]);
        }*/
    }

    componentDidMount= async ()=> {
        this.handleMyInfoChange();
    }

    componentDidUpdate(prevProps: ICestaProps) {
        if(prevProps.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
        else if (this.props.farmRef !== prevProps.farmRef || this.props.dataareaid !== prevProps.dataareaid) {
            this.handleMyInfoChange();
        }
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo);

        this.setState({
            behaviour: GetBehaviour(this.props.pedido, this.props.myInfo)
        }, ()=>this.loadData());
        
    }

    loadData = async () => {
        const {myInfo, allowed} = this.props;

        if(!allowed)
            return;
    
        const d = new Date().setDate(new Date().getDate() + 1)
        this.handleDatePickerChange('', d)
        //await this.loadFarm();
        this.controlExistingDatetime();
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onToggleCreationModal = () => {
        this.setState({ creationModalOpen: !this.state.creationModalOpen });
    }

    handleDatePickerControl = (inputDate: any) => {
        /*const {farm} = this.state;

        if(farm){
            let dateTimePicker : Array<any> = verifyDateTimeLimit(inputDate, farm.timeLimitPedido, farm.beforeTimeLimitPedido, farm.festivos);
        
            this.setState({
                cartDate: dateTimePicker[0],
                modalType: 1,
                inputText: `¡ATENCIÓN! La fecha de entrega se ha modificado para el ${GetFormattedPedidoDate(dateTimePicker[0])}.`,
                creationModalOpen: dateTimePicker[1]
            })
            this.props.updateCartDate(dateTimePicker[0]);
        }*/
    }
    
    handleCloseComment = () => {
        this.setState({ openCommentDialog: false });
    }

    toggleSummaryMode = () => {
        this.setState({ summaryMode: !this.state.summaryMode });
    }

    goBack=()=>{
        this.setState({ summaryMode:false });
    }

    handleDatePickerChange = (id:string, e: any) => {
        let nPedido: IPedido = cloneDeep(this.state.e_pedido);
        nPedido.date = new Date(e).getTime();
    }

    savePedido = async (userId:string, action:number, data:any) => {
        let response = await OrderService.saveData(userId, action, data);

        if([-1,0].indexOf(response) > -1){
            this.setState({
                inputText: `¡ATENCIÓN! No se ha podido completar la acción, vuelvo a intentarlo.`,
                modalType: 1,
                creationModalOpen: true,
                titleModal:'MENSAJE',
                loadingModal: false
            });
        }
        else {
            this.setState({
                loadingModal: false
            })
            this.props.navigate('/sales');
        }
    }

    handleCreateModal = (typeAction: number) => {
        const {myInfo} = this.props;

        switch(typeAction){
            case 1: // Acepta pedido con o sin modificaciones

                let clone = cloneDeep(this.state.e_pedido);
                clone.modifiedBySaler = (clone.modifiedBySaler?clone.modifiedBySaler:false);
                clone.estado = 20;

                clone.invoice = this.calculatePrice(clone);

                if(!myInfo)
                    return;

                    
                this.setState({
                    loadingModal: true
                },()=>this.savePedido(myInfo.userId, 2, clone));                
                break;

            case 2: // Acepta o rechaza
                let clone02 = cloneDeep(this.state.e_pedido);
                clone02.estado = 98;

                if(!myInfo)
                    return;

                this.setState({
                    loadingModal: true
                },()=>this.savePedido(myInfo.userId, 2, clone02));

            break;
            case 3: // En reparto
                let clone03 = cloneDeep(this.state.e_pedido);
                clone03.estado = 40;

                if(!myInfo)
                    return;

                this.setState({
                    loadingModal: true
                },()=>this.savePedido(myInfo.userId, 3, clone03));
            break;
            case 4: // Entrega
            let clone04 = cloneDeep(this.state.e_pedido);
            clone04.estado = 50;

            if(!myInfo)
                return;

            this.setState({
                loadingModal: true
            },()=>this.savePedido(myInfo.userId, 4, clone04));
        break;
        }
    }

    handleRemoveUnit=(product:IProduct)=>{

        let nPedido: IPedido = cloneDeep(this.state.e_pedido);
        let p_products: Array<IProduct> = nPedido.cart;

        const idx = findIndex(p_products, function(item:IProduct){return item.id == product.id})
        console.log(idx)
        product.minimunQuantity = 0;

        if(product.quantitySend && idx > -1){   

            product.quantitySend = 
                    (product.quantitySend > product.minimunQuantity?
                        product.quantitySend - 1
                    :
                        product.minimunQuantity
                    )


            if(product.quantityAsked !== product.quantitySend)
                product.modifiedBySaler = true;
            else
                product.modifiedBySaler = false;

            p_products[idx] = product;
            nPedido.modifiedBySaler = product.modifiedBySaler;
            
            nPedido.cart = p_products;

            this.setState({
                e_pedido: nPedido
            })
        }
    }

    products = (behaviour:number, lang:string) => {
        const searchValue = this.state.searchValue;

        let readonly = [10].indexOf(this.state.e_pedido.estado) > -1?false:true;

        let products = cloneDeep(this.state.e_pedido.cart);

        let ret = products.map((item:IProduct, idx:number)=>{

            item.quantity = item.quantitySend;
            item.onRemoveUnitClick = (!readonly?()=>this.handleRemoveUnit(item):undefined)
            item.onAddClick = undefined
            item.onRemoveClick = undefined
            item.onChangeQuantity = undefined
            item.onAddUnitClick = undefined

            
            return(
                <ProductItem
                    style={{}}
                    index={idx}
                    data={[item, lang]}
                />
            )
        });
        
        return (
            <div style={{
                position: 'absolute',
                top: 325,
                left: 0,
                right: 0,
                bottom: 30,
                overflowY: 'auto'
            }}>
                <div className='dynamic-grid'>
                    {ret}
                </div>
            </div>

        );
    }

    calculatePrice=(pedido:IPedido)=>{
        if(pedido.cart.length == 0)
            return "0.00";
        const price:number = pedido.cart.map((item:IProduct) => ((item.quantitySend?item.quantitySend:1) * item.base_price) + ((item.quantitySend?item.quantitySend:1) * item.base_price*(item.iva/100))).reduce((prev:number, next:number) => prev + next);
        return price.toFixed(2);
    }

    handleConfirmOrder = (pedido: IPedido) => {
        const {lang} = this.props;
        this.setState({
            inputText: Translate(lang, "label.purchase.01", pedido.id),
            modalType: 2,
            typeAction: 1,
            creationModalOpen: true,
            titleModal:Translate(lang, "label.general.01").toUpperCase()
        })
    }

    handleRejectOrder = (pedido: IPedido) => {
        const {lang} = this.props;

        this.setState({
            inputText: Translate(lang, "label.purchase.02", pedido.id),
            modalType: 2,
            typeAction: 2,
            creationModalOpen: true,
            titleModal:Translate(lang, "label.general.01").toUpperCase()
        })
    }

    handleToTruckOrder = (pedido: IPedido) => {
        const {lang} = this.props;

        this.setState({
            inputText: Translate(lang, "label.purchase.03", pedido.id),
            modalType: 2,
            typeAction: 3,
            creationModalOpen: true,
            titleModal:Translate(lang, "label.general.01").toUpperCase()
        })
    }
    handleDoneOrder = (pedido: IPedido) => {
        const {lang} = this.props;

        this.setState({
            inputText: Translate(lang, "label.purchase.04", pedido.id),
            modalType: 2,
            typeAction: 4,
            creationModalOpen: true,
            titleModal:Translate(lang, "label.general.01").toUpperCase()
        })
    }
    
    handleCommentClick = () => {
        this.setState({ openCommentDialog: !this.state.openCommentDialog });
    }

    render() {
        const {myInfo, version, navigate, lang } = this.props;
        const {loadbeauty, searchValue, behaviour, e_pedido } = this.state;

        if(myInfo == null)
            console.log('Usuario no autorizado');       
        
        let statusProperties : Array<string> = getStatusDescriptionColor(e_pedido.estado, lang)
        return(
            <div>
                {/*TutorialExplication()*/}

                {loadbeauty || !myInfo?
                    <div style={{marginLeft:'50%',marginTop:'10%'}}>
                        <Loader loading={this.state.loading}/>
                    </div>

                :
                    e_pedido.cart.length == 0?
                        <div style={{textAlign: 'center',marginTop:'150px'}}>
                            <h3>{`${Translate(lang, "label.basket.empty")}`}</h3>
                            <img
                            alt="Cesta"
                            src={orderIcon}
                            style={{ height:'20%', width:'20%'}}
                            />
                            <div>
                                <button className='button-do-order' style={{width:'200px'}} onClick={()=>navigate('/')}>
                                    {`${Translate(lang, "label.basket.go")}`}
                                </button>
                            </div>
                        </div>
                    :
                        <div>
                            <div style={{
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                border: '2px solid #ddd',
                                borderRadius: '15px',
                                margin: '5px',
                                width: 'calc(100% - 0px)!important',
                                position: 'relative',
                                boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
                            }}>
                                <div>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr style={{width:'100%',}}>
                                                <td  style={{width:'60%',maxWidth:'120px', overflowX:'auto'}}>
                                                    <p style={{}}>
                                                        <a style={{ color: '#284770', fontWeight:'bold' }}>{`${Translate(lang, "label.order.reference")}: `}</a>
                                                        <a style={{color:'#284770',}}>{`${e_pedido.id}`}</a>
                                                    </p>
                                                    
                                                    <p>
                                                        <a style={{ color: '#284770', fontWeight:'bold' }}>{`${Translate(lang, "label.order.cost")}: `}</a>
                                                        <a style={{color:'#284770',}}>{`${[10].indexOf(e_pedido.estado)>-1?addThousandsAndReplaceDecimal(this.calculatePrice(e_pedido), ','):addThousandsAndReplaceDecimal(e_pedido.invoice.toFixed(2), ',')} €`}</a>

                                                    </p>
                                                    {/*<b style={{ color: '#284770' }}>{this.props.idPedido?this.props.idPedido:'Nuevo pedido'}</b>
                                                    <br />*/}
                                                </td>
                                                <td  style={{width:'100%', paddingRight:0, paddingTop:'15px', display:'flex'}}>
                                                    <div style={{maxWidth:'130px', marginLeft:'auto'}}>
                                                        {DatePicker('date', `${Translate(lang, "label.order.dateDelivery")}`,"dd/MM/yyyy",this.handleDatePickerChange,new Date(Number(e_pedido.date)).toISOString(),true,(date:any)=>this.disableDays(date, []))}
                                                    </div>
                                                    {e_pedido.comment.length > 0?
                                                        <IconButton onClick={this.handleCommentClick} size='small' style={{ float: 'left' }}>
                                                            <CommentIcon  style={{fill:'#ff9933'}}/>
                                                        </IconButton>
                                                    :
                                                        null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <p>
                                                        <a style={{ color: '#284770', fontWeight:'bold' }}>{`${Translate(lang, "label.order.state")}: `}</a>
                                                        <a style={{color:'#284770',}}>{`${statusProperties[0]}`}</a>
                                                    </p>
                                                </td>
                                            </tr>
                                            {/*<tr style={{width:'100%'}}>
                                                <td colSpan={2} style={{width:'100%'}}>
                                                    <IconButton onClick={this.handleCommentClick} style={{ float: 'right' }}>
                                                        <CommentIcon />
                                                    </IconButton>
                                                </td>
                                                    </tr>*/}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    {e_pedido.estado == 10?
                                        <div style={{marginTop:'0px', display:'flex'}}>
                                            <button className='button-accept-order' onClick={()=>this.handleConfirmOrder(e_pedido)}>
                                                {`${Translate(lang, "label.order.accept")}`}
                                            </button>
                                            <button className='button-reject-order' onClick={()=>this.handleRejectOrder(e_pedido)}>
                                                {`${Translate(lang, "label.order.cancel")}`}
                                            </button>
                                        </div>
                                    :
                                        /*e_pedido.estado == 20?
                                            <div style={{marginTop:'0px', display:'flex'}}>
                                                <button className='button-accept-order' onClick={()=>this.handleToTruckOrder(e_pedido)}>
                                                    {`En reparto`}
                                                </button>
                                            </div>
                                        :*/
                                            e_pedido.estado == 20 || e_pedido.estado == 40?
                                                <div style={{marginTop:'0px', display:'flex'}}>
                                                    <button className='button-accept-order' onClick={()=>this.handleDoneOrder(e_pedido)}>
                                                        {`${Translate(lang, "label.order.delivered")}`}
                                                    </button>
                                                </div>
                                            :
                                                null
                                    }

                                </div>
                                <Dialog fullScreen={this.props.fullScreen} open={this.state.openCommentDialog} onClose={this.handleCloseComment} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">{`${Translate(lang, "label.order.comment.01")}`}</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            disabled
                                            autoFocus
                                            multiline
                                            margin="dense"
                                            id="name"
                                            label={`${Translate(lang, "label.order.comment.01")}`}
                                            type="text"
                                            fullWidth
                                            value={e_pedido.comment}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCloseComment} color="primary">
                                            {`${Translate(myInfo.language, "label.order.done")}`}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            {this.products(behaviour, lang)}
                        </div>
                }              
                    
                <CreateModal
                    onCloseCreationModal={this.onCloseCreationModal}
                    isOpen={this.state.creationModalOpen}
                    inputText={this.state.inputText}
                    typeModal={this.state.modalType}
                    action={this.handleCreateModal}
                    typeAction={this.state.typeAction}
                    loadingModal={this.state.loadingModal}
                    titleModal={this.state.titleModal}
                />
            </div>
        );

            /*return (
                <Summary
                    history={this.props.history}
                    farm={this.state.farm}
                    cart={this.props.cart}
                    cartDate={this.state.cartDate}
                    cartComments={this.props.cartComments}
                    goBack={this.goBack}
                    status={-1}
                    pedidoId={this.props.idPedido}
                    pedido={undefined}
                    resetCart={this.props.resetCart}
                    updateCart={this.props.updateCart}
                    updateQuantityCart={this.props.updateQuantityCart}
                />
            );*/
    }
}
export default withParamsAndNavigate(Cesta);