import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IFarmer, IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {farmers} from '../ApiClients/DummyData';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import {readLocalStorage, writeLocalStorage} from '../Library/sp_general';
import { GetViewDefaultType, GetLanguage } from '../Library/helpers';
import LogoWhite from '../Assets/icons/abast10.png';
import Img01 from '../Assets/images/page_information/img_04.png';
import Img02 from '../Assets/images/page_information/img_06.png';
import Img03 from '../Assets/images/page_information/img_09.png';
import Img04 from '../Assets/images/page_information/img_10.png';
import Img05 from '../Assets/images/page_information/img_13.png';
import Img06 from '../Assets/images/page_information/img_14.png';
import Img07 from '../Assets/images/page_information/us.jpg';

import ImgNotification from '../Assets/icons/notification.png';
import ImgPromotion from '../Assets/icons/promotion.png';
import ImgGestion from '../Assets/icons/gestion.png';
import ImgPublish from '../Assets/icons/publish.png';
import ImgVisual from '../Assets/icons/visual.png';
import ImgAgile from '../Assets/icons/agile.png';
import ImgRating from '../Assets/icons/rating.png';
import ImgInformation from '../Assets/icons/information.png';

import ImgProximity from '../Assets/icons/proximity.png';
import ImgClimate from '../Assets/icons/climate.svg';
import ImgBridge from '../Assets/icons/bridge.png';
import ImgStamp from '../Assets/icons/stamp.png';

import LanguagePicker from './Material/LanguagePicker';

import Translate from './Material/Traductor';
import googleIcon from '../Assets/images/google.png';
import {onAuthStateChanged, signInWithGoogle } from '../Library/sp_firebase';

//import {GetLogoEmpresa} from '../Library/helpers';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});

interface IInformationProps {
    //history: any;
    //match: any;
    version: string;
    params?:any;
    navigate?:any;
    checkPermission:Function;
    handleGoingToPlatform: Function;
}

interface IInformationState {
    loading: boolean;
    loadbeauty: boolean;
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;
    searchValue: string;
    viewType: number;
    lang: string;
}

class Information extends Component<IInformationProps, IInformationState> {
    constructor(props: IInformationProps) {
        super(props);
        this.state = {
            loading: true,
            loadbeauty: false,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue: '',
            viewType: GetViewDefaultType(),
            lang: GetLanguage('language')

        };
    }

    componentDidMount() {

    }

    componentDidUpdate = (prevprops:IInformationProps) => {

    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleSignInGoogle=()=>{
        this.props.handleGoingToPlatform();
        signInWithGoogle()
    }

    handleLanguageChange=(n_lang:string)=>{
        const {lang} = this.state;
        
        if(lang == n_lang)
            return;

        writeLocalStorage('language', n_lang);
        window.location.reload();
    }

    render() {
        const {version, navigate, params } = this.props;
        const {loadbeauty, searchValue, viewType, lang } = this.state;

        return (
            <div className='main-page-wrapper'>

                <div className="home-wrapper">

                    <div className="white-navbar w-nav" data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner">
                        <div className="wrapper-1170px nav-wrapper">
                            <a href="/" aria-current="page" className="brand-logo w-nav-brand w--current" aria-label="home">
                            <img src={LogoWhite} loading="lazy" width={150} alt="" className="brand-logo-image" />
                            </a>

                            <div>
                                {LanguagePicker([{lang:'cat', value: 'CAT', name:'Català'},{lang:'es', value:'ES',name:'Español'}],lang,false,this.handleLanguageChange)}
                            </div>
                        </div>
                        <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" style={{display: 'none'}} />
                    </div>
                    <div className="home-hero-wrapper" style={{textAlign:'center'}}>
                        <div className="home-hero-content-wrapper">
                            <div className="home-hero-content-contain">
                                <h1 className="home-hero-heading">{`${Translate(lang, "login.text.01")}`}</h1>
                                <div className="home-hero-heading-text">{`${Translate(lang, "login.text.02")}`}</div>
                                <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="home-hero-button-contain">
                                    <a onClick={()=>this.handleSignInGoogle()} className="white-button w-button"><img className='login-google-img' src={googleIcon} alt="google"/>{`${Translate(lang, "login.text.03")}`}</a>
                                </div>
                            </div>
                            <div className="home-hero-background-gradient" />
                        </div>
                        <div className="home-hero-image-container">
                            <img className="home-hero-image" src={Img01} width={970} alt="" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} sizes="(max-width: 479px) 100vw, (max-width: 1279px) 90vw, 970px" data-w-id="2aaa8925-7e8d-b1ee-9cc9-7dada4e22a79" loading="lazy" srcSet={`${Img02} 500w, ${Img02} 800w, ${Img01} 1080w, ${Img01} 1600w, ${Img01} 1940w`} />
                        </div>
                    </div>
                </div>



                <div id="mision-aims" className="logo-section">
                    <div className="tabs-section">
                        <div className="tabs-wrapper">
                            <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="tabs-heading-contain">
                                <h2 className="tabs-heading">{`${Translate(lang, "login.text.04")}`}</h2>
                                <div className="tabs-text" style={{textAlign:'justify'}}>{`${Translate(lang, "login.text.05")}`}</div>
                                <br/>
                                <div className="tabs-text" style={{textAlign:'justify'}}>{`${Translate(lang, "login.text.06")}`}<a style={{fontStyle: 'italic'}}>{`${Translate(lang, "login.text.06.italic.01")}`}</a><a>{`${Translate(lang, "login.text.12")}`}</a></div>
                                <br/>
                                <div className="tabs-text" style={{textAlign:'justify'}}>{`${Translate(lang, "login.text.13")}`}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="features-hero-section">
                    <div className="features-hero-wrapper">
                    <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="features-hero-text-contain">
                        <h2 className="features-hero-heading">{`${Translate(lang, "login.text.07")}`}</h2>
                    </div>
                    <div className="features-hero-grid-contain">
                        <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="w-layout-grid features-hero-grid">
                        <div className="features-hero-grid-cell">
                            <div className="features-grid-icon-wrap">
                            <img src={ImgProximity} loading="lazy" alt="" className="features-grid-icon" />
                            </div>
                            <div className="features-grid-text-wrap">
                            <h5 className="features-grid-heading">{`${Translate(lang, "login.aim.title.01")}`}</h5>
                            <div className="features-grid-text">{`${Translate(lang, "login.aim.text.01")}`}</div>
                            </div>
                        </div>
                        <div className="features-hero-grid-cell">
                            <div className="features-grid-icon-wrap">
                            <img src={ImgClimate} loading="lazy" alt="" className="features-grid-icon" />
                            </div>
                            <div className="features-grid-text-wrap">
                            <h5 className="features-grid-heading">{`${Translate(lang, "login.aim.title.02")}`}</h5>
                            <div className="features-grid-text">{`${Translate(lang, "login.aim.text.02")}`}</div>
                            </div>
                        </div>
                        <div className="features-hero-grid-cell">
                            <div className="features-grid-icon-wrap">
                            <img src={ImgStamp} loading="lazy" alt="" className="features-grid-icon" />
                            </div>
                            <div className="features-grid-text-wrap">
                            <h5 className="features-grid-heading">{`${Translate(lang, "login.aim.title.03")}`}</h5>
                            <div className="features-grid-text">{`${Translate(lang, "login.aim.text.03")}`}</div>
                            </div>
                        </div>
                        <div className="features-hero-grid-cell">
                            <div className="features-grid-icon-wrap">
                            <img src={ImgBridge} loading="lazy" alt="" className="features-grid-icon" />
                            </div>
                            <div className="features-grid-text-wrap">
                            <h5 className="features-grid-heading">{`${Translate(lang, "login.aim.title.04")}`}</h5>
                            <div className="features-grid-text">{`${Translate(lang, "login.aim.text.04")}`}</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>


                <div className="tracking-accounts-section" style={{backgroundColor: '#006c2b'}}>
                    <div className="tracking-accounts-wrapper">
                        <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="tracking-accounts-content-wrapper">
                            <h2 className="tracking-accounts-heading">{`${Translate(lang, "login.text.08")}`}</h2>
                            <div className="w-layout-grid tracking-accounts-grid">
                                <div className="tracking-account-grid-wrap">
                                    <img src={ImgPublish} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                    <div className="paragraph-20px tracking-accounts-grid-text">
                                        <strong>{`${Translate(lang, "login.function.01.title.01")}`}</strong> <br />{`${Translate(lang, "login.function.01.text.01")}`}
                                    </div>
                                </div>
                                <div className="tracking-account-grid-wrap">
                                    <img src={ImgPromotion} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                    <div className="paragraph-20px tracking-accounts-grid-text">
                                        <strong>{`${Translate(lang, "login.function.01.title.02")}`}</strong> <br />{`${Translate(lang, "login.function.01.text.02")}`}
                                    </div>
                                </div>
                                <div className="tracking-account-grid-wrap">
                                    <img src={ImgGestion} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                    <div className="paragraph-20px tracking-accounts-grid-text">
                                    <strong>{`${Translate(lang, "login.function.01.title.03")}`}</strong> <br />{`${Translate(lang, "login.function.01.text.03")}`}
                                    </div>
                                </div>
                                <div className="tracking-account-grid-wrap">
                                    <img src={ImgNotification} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                    <div className="paragraph-20px tracking-accounts-grid-text">
                                    <strong>{`${Translate(lang, "login.function.01.title.04")}`}</strong> <br />{`${Translate(lang, "login.function.01.text.04")}`} <a style={{fontStyle: 'italic'}}>{`${Translate(lang, "login.function.01.text.04.italic.01")}`}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="tracking-accounts-image-wrapper">
                            <img className="tracking-account-card-image" src={Img03} width={842} alt="" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} sizes="(max-width: 479px) 100vw, (max-width: 991px) 81vw, 55vw" data-w-id="5c0f45ef-d0a2-be26-daa1-61c159934400" loading="lazy"srcSet={`${Img03} 500w, ${Img03} 800w, ${Img04} 1080w, ${Img04} 1600w, ${Img04} 1940w`} />
                        </div>
                    </div>
                </div>

                <div className="tracking-accounts-section" style={{backgroundColor: '#719920'}}>
                    <div className="tracking-accounts-wrapper">
                        <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="tracking-accounts-content-wrapper">
                            <h2 className="tracking-accounts-heading">{`${Translate(lang, "login.text.09")}`}</h2>
                            <div className="w-layout-grid tracking-accounts-grid">
                                <div className="tracking-account-grid-wrap">
                                        <img src={ImgVisual} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                        <div className="paragraph-20px tracking-accounts-grid-text">
                                        <strong>{`${Translate(lang, "login.function.02.title.01")}`}</strong> <br />{`${Translate(lang, "login.function.02.text.01")}`}
                                        </div>
                                    </div>
                                    <div className="tracking-account-grid-wrap">
                                        <img src={ImgAgile} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                        <div className="paragraph-20px tracking-accounts-grid-text">
                                        <strong>{`${Translate(lang, "login.function.02.title.02")}`}</strong> <br />{`${Translate(lang, "login.function.02.text.02")}`}
                                        </div>
                                    </div>
                                    <div className="tracking-account-grid-wrap">
                                        <img src={ImgInformation} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                        <div className="paragraph-20px tracking-accounts-grid-text">
                                        <strong>{`${Translate(lang, "login.function.02.title.03")}`}</strong> <br />{`${Translate(lang, "login.function.02.text.03")}`}
                                        </div>
                                    </div>
                                    <div className="tracking-account-grid-wrap">
                                        <img src={ImgRating} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon" />
                                        <div className="paragraph-20px tracking-accounts-grid-text">
                                        <strong>{`${Translate(lang, "login.function.02.title.04")}`}</strong> <br />{`${Translate(lang, "login.function.02.text.04")}`}
                                        </div>
                                    </div>
                                    <div className="tracking-account-grid-wrap">
                                        <img src={ImgNotification} loading="lazy" width={74} sizes="74px" alt="" className="tracking-accounts-grid-icon"/>
                                        <div className="paragraph-20px tracking-accounts-grid-text">
                                        <strong>{`${Translate(lang, "login.function.02.title.05")}`}</strong> <br />{`${Translate(lang, "login.function.02.text.05")}`}<a style={{fontStyle: 'italic'}}>{`${Translate(lang, "login.function.02.text.05.italic.01")}`}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="tracking-accounts-image-wrapper">
                            <img className="tracking-account-card-image" src={Img03} width={842} alt="" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle:'preserve-3d'}} sizes="(max-width: 479px) 100vw, (max-width: 991px) 81vw, 55vw" data-w-id="5c0f45ef-d0a2-be26-daa1-61c159934400" loading="lazy"srcSet={`${Img06} 500w, ${Img06} 800w, ${Img05} 1080w, ${Img05} 1600w, ${Img05} 1940w`} />
                        </div>
                    </div>
                </div>

                <div className="slider-section">
                    <div className="slider-wrapper">
                        <div data-delay={4000} data-animation="outin" className="slider w-slider" data-autoplay="false" data-easing="ease" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} data-hide-arrows="false" data-disable-swipe="false" data-w-id="7ba67fc6-b102-cec1-67d1-ffab3be95b01" data-autoplay-limit={0} data-nav-spacing={3} data-duration={500} data-infinite="true" role="region" aria-label="carousel">
                            <div className="mask w-slider-mask" id="w-slider-mask-0">
                            <div className="slide w-slide" aria-label="1 of 3" role="group" style={{transform: 'translateX(0px)', opacity: 1}}>
                                <div className="slide-container">
                                <div className="slide-left-text">
                                    <h3 className="slider-text-heading">{`${Translate(lang, "login.idea.01")}`}</h3>
                                    <h4 className="slider-author-name">{`${Translate(lang, "login.idea.02")}`}</h4>
                                    <div className="slider-text">
                                        {`${Translate(lang, "login.idea.03")}`}
                                        <br/>
                                        {`${Translate(lang, "login.idea.04")}`}
                                    </div>
                                </div>
                                <div className="slide-right-image">
                                    <div className="color-film-wrap">
                                    <img src={Img07} width={100} loading="lazy"   alt="" className="slider-image" />
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <div aria-live="off" aria-atomic="true" className="w-slider-aria-label" data-wf-ignore />
                            </div>
                        </div>
                    </div>
                </div>


                <div id="join-platform" className="main-cta-section">
                    <div className="footer-top-wrapper">
                    <h1 className="footer-top-heading" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}>{`${Translate(lang, "login.text.10")}`}</h1>
                    <div className="footer-top-text" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}>{`${Translate(lang, "login.text.11")}`}</div>
                    <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="home-hero-button-contain">
                        <a /*href="/#/login"*/onClick={()=>this.handleSignInGoogle()} className="white-button w-button"><img className='login-google-img' src={googleIcon} alt="google"/>{`${Translate(lang, "login.text.03")}`}</a>
                    </div>
                    </div>

                </div>


                <div className="footer dugfooter">
                    <div className="footer-wrapper fotwraperdug">
                    <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="footer-left-wrapper left-footer-dug">
                        <a href="/" aria-current="page" className="footer-logo-contain w-inline-block w--current">
                        <img src={LogoWhite} loading="lazy" width={196} alt="" className="footer-logo" />
                        </a>
                    </div>
                    </div>
                    <div style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="footer-text-block">
                    <strong>{`${Translate(lang, "login.rights")}`} </strong>
                    <a href="https://abastim.cat" target="_blank" className="footer-bottom-text-link">
                        <span className="footer-span">
                        <strong>Abastim</strong>
                        </span>
                    </a>
                    <strong> 2023 - </strong>
                    <a href='mailto:abastim.lleida@gmail.com'>
                        {`abastim.lleida@gmail.com`}
                    </a>
                    </div>
                </div>



            </div>
            
        );
    }
}

export default withParamsAndNavigate(Information);