import React from 'react';
import { IProduct } from '../Library/types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/ArrowForwardIos';
import MinusIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import TruckIcon from '@material-ui/icons/LocalShippingOutlined';
import PriorityIcon from '@material-ui/icons/LowPriorityOutlined';
import RemoveCartIcon from '@material-ui/icons/RemoveShoppingCartOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { getProductDescriptionColor } from '../Library/helpers'
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CartIcon from '@material-ui/icons/AddShoppingCartOutlined';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
    },
    root: {
        borderRadius: 0,
    },
    item: {
        // border: '1px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        cursor: 'pointer',
        paddingBottom: '50px',
        position: 'relative',
    },
    bottomActionsContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '30px',
        borderTop: '1px solid rgba(0,0,0,0.31)',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        padding: '5px 0px',
        color: '#284770',
    },
    quantIcon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },
    bottomRightActionsContainer: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        bottom: '0px',
        zIndex: 1,
        fontSize:'12px'
    },
    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 10px',
        fontSize: '10px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '5px',
        marginRight: '5px',
        width: 40,
    }
}));

const ProductListItem = (props: ListChildComponentProps, lang:string) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IProduct = data[index];

    let typeProperties : Array<string> = getProductDescriptionColor(item.category_sale, lang);
    //const imageB64 = item.image?new Buffer(item.image).toString("base64"):'';
    
    //imageB64 = `data:image/svg;base64,${imageB64.substring(8,imageB64.length)}`;
    return (
        <div style={style} key={index}>
            <ListItem button classes={{ root: classes.item }}>
                <ListItemIcon>
                    <Avatar 
                        key={`${item.id}`}
                        style={{width:'70px', height:'70px', paddingRight:10}} alt='' src={`${item.uri_img}`} classes={{
                            root: classes.root,
                            img: classes.img
                        }} 
                    />
                </ListItemIcon>
                <ListItemText style={{whiteSpace:'nowrap', overflowX:'auto', color:'#003B71'}} primary={item.name} secondary={`Precio: ${item.base_price} ${item.price_unit}/${item.sale_unit}`} />
                <div className={classes.bottomActionsContainer}>
                    {item.onRemoveUnitClick?
                        <IconButton onClick={item.onRemoveUnitClick} className={classes.quantIcon}>
                            <MinusIcon />
                        </IconButton>
                    :
                        null
                    }
                    {item.onChangeQuantity?
                        <TextField
                        id="standard-number"
                        value={item.quantity ? item.quantity : item.minimunQuantity}
                        //defaultValue={item.quantity ? item.quantity : 0}
                        onChange={item.onChangeQuantity}
                        onClick={item.onClickManualInput}
                        type="number"
                        className={classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        />
                    :
                        null
                    }
                    {item.onAddUnitClick?
                        <IconButton onClick={item.onAddUnitClick} className={classes.quantIcon}>
                            <AddIcon />
                        </IconButton>
                    :
                        null
                    }
                    {/*` ${item.sale_unit/} `*/}
                    <div  className={classes.bottomRightActionsContainer}>
                        {item.allowEdit?
                            <IconButton onClick={item.allowEdit} className={classes.quantIcon}>
                                <EditIcon />
                            </IconButton>
                        :
                            null
                        }
                        <div className={classes.promotion} style={{backgroundColor:typeProperties[1], color:typeProperties[2]}}>
                            {typeProperties[0]}
                        </div>
 
                        {item.onAddClick || item.allowEdit?
                            <div className={classes.separator} />
                        :
                            null
                        }
                        {item.onAddClick?
                            <IconButton onClick={item.onAddClick} className={classes.quantIcon}>
                                {item.isInCart === true ? <RemoveCartIcon /> : <CartIcon />}
                            </IconButton>
                        :
                            null
                        }
                        {item.allowEdit?
                            <span className='dot' style={{backgroundColor:(item.activated?'#a5d6a7':'#f44336'), marginLeft:10, marginRight:10, verticalAlign: 'middle',}}>
                            </span>
                        :
                            null
                        }
                    </div>
                </div>
                <ListItemSecondaryAction style={{ paddingBottom: '40px' }}>
                    <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

ProductListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default ProductListItem;