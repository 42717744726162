import {IPhoto, IProject, IProcess, ITag, IProduct, IFarmer} from "../Library/types"

export const processDsc:Array<IProcess> = [
    {
        id: 1,
        name: `PID 02`
    },
    {
        id: 2,
        name: `PID 01`
    }
]
export const vnPoints:Array<any> = [
    {
        id: 1,
        dsc: `Despiece - PID 01`
    },
    {
        id: 2,
        dsc: `Despiece - PID 02`
    },
    {
        id: 3,
        dsc: `Despiece - PID 03`
    },
    {
        id: 4,
        dsc: `Despiece - PID 04`
    }
];

export const projects:Array<IProject> = [
    {
        id: "5b14ea47471c46cda17ac1560410d7ff",
        name: "CCV-PRODUCT-01",
        description: "Clasificación imágenes 5D (test todo desglosado)",
        settings: {
            domainId: "0732100f-1a38-4e49-a514-c9b44c697ab5",
            classificationType: "Multiclass",
            targetExportPlatforms: [],
            useNegativeSet: true,
            detectionParameters: null,
            imageProcessingSettings: {
                augmentationMethods: {
                    rotation: true,
                    scaling: true,
                    translation: true,
                    horizontalFlip: true,
                    equalize: true,
                    solarize: true,
                    padtosquare: true
                }
            }
        },
        created: "2021-10-04T06:59:17.706Z",
        lastModified: "2021-10-28T07:16:47.384Z",
        drModeEnabled: false,
        status: "Succeeded"
    },
    {
        id: "4543tg-7d47-4e52-9205-fe7137b605be",
        name: "CCV-PRODUCT-02",
        description: "Clasificación imágenes 5D (test todo desglosado)",
        settings: {
            domainId: "0732100f-1a38-4e49-a514-c9b44c697ab5",
            classificationType: "Multiclass",
            targetExportPlatforms: [],
            useNegativeSet: true,
            detectionParameters: null,
            imageProcessingSettings: {
                augmentationMethods: {
                    rotation: true,
                    scaling: true,
                    translation: true,
                    horizontalFlip: true,
                    equalize: true,
                    solarize: true,
                    padtosquare: true
                }
            }
        },
        created: "2021-10-04T06:59:17.706Z",
        lastModified: "2021-10-28T07:16:47.384Z",
        drModeEnabled: false,
        status: "Succeeded"
    },
    {
        id: "34tgg4-7d47-4e52-9205-fe7137b605be",
        name: "PTL-PRODUCT-01",
        description: "Clasificación imágenes 5D (test todo desglosado)",
        settings: {
            domainId: "0732100f-1a38-4e49-a514-c9b44c697ab5",
            classificationType: "Multiclass",
            targetExportPlatforms: [],
            useNegativeSet: true,
            detectionParameters: null,
            imageProcessingSettings: {
                augmentationMethods: {
                    rotation: true,
                    scaling: true,
                    translation: true,
                    horizontalFlip: true,
                    equalize: true,
                    solarize: true,
                    padtosquare: true
                }
            }
        },
        created: "2021-10-04T06:59:17.706Z",
        lastModified: "2021-10-28T07:16:47.384Z",
        drModeEnabled: false,
        status: "Succeeded"
    },
    {
        id: "hgeg344-7d47-4e52-9205-fe7137b605be",
        name: "FRV-PRODUCT-01",
        description: "Clasificación imágenes 5D (test todo desglosado)",
        settings: {
            domainId: "0732100f-1a38-4e49-a514-c9b44c697ab5",
            classificationType: "Multiclass",
            targetExportPlatforms: [],
            useNegativeSet: true,
            detectionParameters: null,
            imageProcessingSettings: {
                augmentationMethods: {
                    rotation: true,
                    scaling: true,
                    translation: true,
                    horizontalFlip: true,
                    equalize: true,
                    solarize: true,
                    padtosquare: true
                }
            }
        },
        created: "2021-10-04T06:59:17.706Z",
        lastModified: "2021-10-28T07:16:47.384Z",
        drModeEnabled: false,
        status: "Succeeded"
    },
    {
        id: "34j4g44-7d47-4e52-9205-fe7137b605be",
        name: "CMB-PRODUCT-01",
        description: "Clasificación imágenes 5D (test todo desglosado)",
        settings: {
            domainId: "0732100f-1a38-4e49-a514-c9b44c697ab5",
            classificationType: "Multiclass",
            targetExportPlatforms: [],
            useNegativeSet: true,
            detectionParameters: null,
            imageProcessingSettings: {
                augmentationMethods: {
                    rotation: true,
                    scaling: true,
                    translation: true,
                    horizontalFlip: true,
                    equalize: true,
                    solarize: true,
                    padtosquare: true
                }
            }
        },
        created: "2021-10-04T06:59:17.706Z",
        lastModified: "2021-10-28T07:16:47.384Z",
        drModeEnabled: false,
        status: "Succeeded"
    }
];

export const tags:Array<ITag> = [
    {
        id: `00001`,
        name: `00001`,
        description: `2402F_N0200`,
        type: ``
    },
    {
        id: `00002`,
        name: `00002`,
        description: `2402F_00`,
        type: ``
    },
    {
        id: `00003`,
        name: `00003`,
        description: `3301F_N0200`,
        type: ``
    },
    {
        id: `00004`,
        name: `00004`,
        description: `3302F_N0200`,
        type: ``
    },
    {
        id: `00005`,
        name: `00005`,
        description: `3301F_N0203`,
        type: ``
    },
    {
        id: `00006`,
        name: `00006`,
        description: `3301F_N0204`,
        type: ``
    },
    {
        id: `00007`,
        name: `00007`,
        description: `3301F_N0205`,
        type: ``
    },
    {
        id: `00008`,
        name: `00008`,
        description: `3301F_N0206`,
        type: ``
    },
    {
        id: `00009`,
        name: `00009`,
        description: `3301F_N0207`,
        type: ``
    },
    {
        id: `00010`,
        name: `00010`,
        description: `3306F_N0200`,
        type: ``
    },
    {
        id: `99999`,
        name: `99999`,
        description: `3306F_N0200`,
        type: ``
    },
    {
        id: `00045`,
        name: `00045`,
        description: `3306F_N0200`,
        type: ``
    },
    {
        id: `00097`,
        name: `00097`,
        description: `00097_N00`,
        type: ``
    }
]


export const farmers:Array<IFarmer> = [
    {
        id: '5J0gqqVDo9fpnbz4IwJ2WkOonhW2',
        name: 'Jordi Guiu',
        description: 'Jove agricultor de familia agrària el qual ha decidit promoure el seu producte de proximitat a partir de la qualitat.',
        minimunPrice: 15,
        totalProducts: 3,
        uri_img: `https://mms.businesswire.com/media/20210303005986/es/862956/5/VisionGraphics-SAField-303x303_highres.jpg`,
        rates: {
            reviews: [
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                }
            ],
            qualification: 4.5,
        }
    },
    {
        id: 'F4SETcDs88hB3jesYCd07e5pFxZ2',
        name: 'Pol Montaner',
        description: 'Jove agricultor de la comarca del Alt Urgell.',
        minimunPrice: 15,
        totalProducts: 6,
        uri_img: `http://www.hull-o.com/wp-content/uploads/2015/10/Farmer.jpg`,
        rates: {
            reviews: [
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                },
                {
                    id: '4234',
                    review: 'holita'
                }
            ],
            qualification: 3.5,
        }
    }
]

export const iva:Array<any> = [
    {id:'1', name:'0'},
    {id:'4', name:'4'},
    {id:'10', name:'10'},
    {id:'21', name:'21'}
]

export const price_unit:Array<any> = [
    {id:'€', name:'€'}
]

export const sale_unit:Array<any> = [
    {id:'Ud', name:'Ud'},
    {id:'Kg', name:'Kg'},
    {id:'gr', name:'gr'},
    {id:'Caja', name:'Caja'}
]

export const category_sale:Array<any> = [
    {id:10, name:'Normal'},
    {id:20, name:'Oferta'},
    {id:30, name:'Promoción'}
]
/*
export const products:Array<any> = [
    {
        id: `1`,
        quantity: 1,
        farmerId: `F4SETcDs88hB3jesYCd07e5pFxZ2`,
        name: `Tomate pera`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.19,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/05.webp?alt=media&token=183a628c-0169-4915-b825-8c2408bb6445`,
        minimunQuantity: 1,
        farmer: farmers[0]
    },{
        id: `2`,
        quantity: 1,

        farmerId: `F4SETcDs88hB3jesYCd07e5pFxZ2`,
        name: `Patata`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.80,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/02.webp?alt=media&token=86fd90d7-0f17-4c9e-8a60-ab0a6fef5ec3`,
        minimunQuantity: 1,
        farmer: farmers[0]
        
    },
    {
        id: `3`,
        name: `Pimiento`,
        farmerId: `F4SETcDs88hB3jesYCd07e5pFxZ2`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.25,
        price_unit: "€",
        sale_unit: [`Ud`, `Tn`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/03.webp?alt=media&token=1bcdd3fb-06ac-4c36-bc7c-f008fbcee127`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `4`,
        farmerId: `F4SETcDs88hB3jesYCd07e5pFxZ2`,
        name: `Cebolla seca`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.60,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/04.webp?alt=media&token=0b141120-a995-4151-be3f-1a352bb5e430`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `5`,
        farmerId: `F4SETcDs88hB3jesYCd07e5pFxZ2`,
        name: `Coliflor`,
        description: `Perfecta para cremas y purés`,
        base_price: 2.19,
        price_unit: "€",
        sale_unit: [`Ud`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 0,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/01.webp?alt=media&token=2663c1aa-7366-4ee0-9612-820d93ad8a61`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `6`,
        quantity: 1,
        farmerId: `5J0gqqVDo9fpnbz4IwJ2WkOonhW2`,
        name: `Tomate pera`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.19,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/05.webp?alt=media&token=183a628c-0169-4915-b825-8c2408bb6445`,
        minimunQuantity: 1,
        farmer: farmers[0]
    },{
        id: `7`,
        quantity: 1,

        farmerId: `5J0gqqVDo9fpnbz4IwJ2WkOonhW2`,
        name: `Patata`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.80,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/02.webp?alt=media&token=86fd90d7-0f17-4c9e-8a60-ab0a6fef5ec3`,
        minimunQuantity: 1,
        farmer: farmers[0]
    },
    {
        id: `8`,
        name: `Pimiento`,
        farmerId: `5J0gqqVDo9fpnbz4IwJ2WkOonhW2`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.25,
        price_unit: "€",
        sale_unit: [`Ud`, `Tn`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/03.webp?alt=media&token=1bcdd3fb-06ac-4c36-bc7c-f008fbcee127`,
        minimunQuantity: 1,
        farmer: farmers[0]
    },
    {
        id: `9`,
        farmerId: `5J0gqqVDo9fpnbz4IwJ2WkOonhW2`,
        name: `Cebolla seca`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.60,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/04.webp?alt=media&token=0b141120-a995-4151-be3f-1a352bb5e430`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `10`,
        farmerId: `f_002`,
        name: `Coliflor`,
        description: `Perfecta para cremas y purés`,
        base_price: 2.19,
        price_unit: "€",
        sale_unit: [`Ud`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 0,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/01.webp?alt=media&token=2663c1aa-7366-4ee0-9612-820d93ad8a61`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `11`,
        quantity: 1,
        farmerId: `f_003`,
        name: `Tomate pera`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.19,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/05.webp?alt=media&token=183a628c-0169-4915-b825-8c2408bb6445`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },{
        id: `12`,
        quantity: 1,

        farmerId: `f_003`,
        name: `Patata`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.80,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/02.webp?alt=media&token=86fd90d7-0f17-4c9e-8a60-ab0a6fef5ec3`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `13`,
        name: `Pimiento`,
        farmerId: `f_003`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.25,
        price_unit: "€",
        sale_unit: [`Ud`, `Tn`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/03.webp?alt=media&token=1bcdd3fb-06ac-4c36-bc7c-f008fbcee127`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `14`,
        farmerId: `f_001`,
        name: `Cebolla seca`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.60,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/04.webp?alt=media&token=0b141120-a995-4151-be3f-1a352bb5e430`,
        minimunQuantity: 1,
        farmer: farmers[0]
    },
    {
        id: `15`,
        farmerId: `f_001`,
        name: `Coliflor`,
        description: `Perfecta para cremas y purés`,
        base_price: 2.19,
        price_unit: "€",
        sale_unit: [`Ud`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 0,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/01.webp?alt=media&token=2663c1aa-7366-4ee0-9612-820d93ad8a61`,
        minimunQuantity: 1,
        farmer: farmers[0]
    },
    {
        id: `16`,
        quantity: 1,
        farmerId: `f_002`,
        name: `Tomate pera`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.19,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/05.webp?alt=media&token=183a628c-0169-4915-b825-8c2408bb6445`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },{
        id: `17`,
        quantity: 1,

        farmerId: `f_002`,
        name: `Patata`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.80,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/02.webp?alt=media&token=86fd90d7-0f17-4c9e-8a60-ab0a6fef5ec3`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `18`,
        name: `Pimiento`,
        farmerId: `f_004`,
        description: `Variedad tomate para ensaladas`,
        base_price: 1.25,
        price_unit: "€",
        sale_unit: [`Ud`, `Tn`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 2,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/03.webp?alt=media&token=1bcdd3fb-06ac-4c36-bc7c-f008fbcee127`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `19`,
        farmerId: `f_005`,
        name: `Cebolla seca`,
        description: `Variedad tomate para ensaladas`,
        base_price: 0.60,
        price_unit: "€",
        sale_unit: [`500 gr`, `Kg`, `Cj`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 1,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/04.webp?alt=media&token=0b141120-a995-4151-be3f-1a352bb5e430`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
    {
        id: `20`,
        farmerId: `f_005`,
        name: `Coliflor`,
        description: `Perfecta para cremas y purés`,
        base_price: 2.19,
        price_unit: "€",
        sale_unit: [`Ud`],
        iva: 4,
        discount: 0,
        discount_dt: 0,
        category_agriculture: `Solanàcies`,
        category_cuisine: [`Ensalada`, `Entrante`, `Primer plato`, `Segundo plato`],
        category_sale: 0,
        uri_img: `https://firebasestorage.googleapis.com/v0/b/spo-agrir.appspot.com/o/01.webp?alt=media&token=2663c1aa-7366-4ee0-9612-820d93ad8a61`,
        minimunQuantity: 1,
        farmer: farmers[1]
    },
]
*/
export const Rate_labels: { [index: string]: string } = {
    0.5: 'Muy mala',
    1: 'Mala',
    1.5: 'Muy pobre',
    2: 'Pobre',
    2.5: 'Buena',
    3: 'Buena+',
    3.5: 'Notable',
    4: 'Notable+',
    4.5: 'Excelente',
    5: 'Excelente+',
  };