import { firestore, signInWithGoogle, auth } from "../firebase";
import {addDoc, collection, collectionGroup, doc, setDoc, updateDoc, getDoc, deleteDoc, query, where, orderBy, limit, getDocs} from 'firebase/firestore';

//** Firebase 9.14.0 
//
// https://firebase.google.com/docs/firestore/query-data/get-data
//
//*/

const AddDocument = (path: string, data: Object, merge: boolean = false) => {
    const ret = setDoc(doc(firestore, path), data, {merge: merge});

    return ret;
}

const old_AddDocument = (collection_name: string, id: string, data: Object, merge: boolean = false) => {
    const ret = setDoc(doc(firestore, collection_name, id), data, {merge: merge});

    return ret;
}

const Test_AddDocument = (path:string, data: Object, merge: boolean = false) => {
    const docref = doc(firestore, path);

    const ret = setDoc(docref, data, {merge: merge});
    

    return ret;
}

const old_Test_AddDocument = (collection_name: string, path:string, id: string, data: Object, merge: boolean = false) => {
    const ref = collection(firestore, 'users');
    const docref = doc(ref, collection_name);
    const ref2 = collection(docref, path);
    const docref2 = doc(ref2, id);

    const ret = setDoc(docref2, data, {merge: merge});
    

    return ret;
}

const RemoveDocument = (collection_name: string, id: string) => {
    const ret = deleteDoc(doc(firestore, collection_name, id));

    return ret;
}

const GetDocument = async (collection_name: string, id: string, path?:string) => {
    /*if(id) // Return document searched
        return getDoc(doc(firestore, collection, id));
    else // Return all documents
        console.log("j");

    const q = query(firestore, where("population", ">", 100000), orderBy("population"), limit(2));*/
    let docRef = doc(firestore, collection_name, id);
    if(path)
        docRef = doc(firestore, path);
    
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
    // doc.data() will be undefined in this case
        console.log("No such document!");
        return undefined;
    }
}

const old_GetDocument = async (collection_name: string, id: string) => {
    /*if(id) // Return document searched
        return getDoc(doc(firestore, collection, id));
    else // Return all documents
        console.log("j");

    const q = query(firestore, where("population", ">", 100000), orderBy("population"), limit(2));*/
    const docRef = doc(firestore, collection_name, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
    // doc.data() will be undefined in this case
        console.log("No such document!");
        return undefined;
    }
}

const GetDocuments = async (collection_name: string, all:boolean, path?:string) => {
    if(all) { // All documents
        let colRef = collection(firestore, collection_name);

        if(path)
            colRef = collection(firestore, path)

        const querySnapshot = await getDocs(colRef);

        /*querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
        });*/

        return querySnapshot;
    } else {}
}

const Test = async (path: string, all:boolean) => {
    const docref = doc(firestore, `/users/pWkjZTkpwLfInsD0lRwgxkQhHn42/settings/account`);

    const querySnapshot = await getDoc(docref);
    
    console.log(querySnapshot.get(`account`))
    return querySnapshot;
}

const GetUserLogged = () => {
    console.log('GetUserLogged')
    return new Promise((resolve, reject)=>{
        auth.onAuthStateChanged((user) => {
            resolve(user);
        })
    })

}

const onAuthStateChanged = (handleOnAuthStateChanged:Function) => {
    console.log('onAuthStateChanged')
    auth.onIdTokenChanged((user) => {
        handleOnAuthStateChanged(user)
    })
}

const signOut = () => {
    auth.signOut();
}


export {
    AddDocument,
    RemoveDocument,
    GetDocument,
    GetDocuments,
    GetUserLogged,
    signInWithGoogle,
    onAuthStateChanged,
    signOut,
    Test,
    Test_AddDocument
}
