// components/Translate.jsx
import * as React from 'react';
import { useContext } from 'react';
import lng from '../../ApiClients/languages.json';

const json_lng:any = lng;
const replace_pattern: string = "$$$$*$$$$";
const replace_pattern02: string = "$$$$**$$$$";
const replace_pattern03: string = "$$$$***$$$$";

function Translate(language:string, key:string, value01:string = "", value02:string = "", value03:string = "", lngDefault:string = "es") {
  /*if (Object.keys(languages).includes(language)) {
    return languages[language][text] || text;
  }*/
  let lng = language;
  value01 = String(value01);
  value02 = String(value02);
  value03 = String(value03);

  if(!(language in json_lng))
    lng = lngDefault
  
  let ret:string = json_lng[lng][key];

  if(value01.length > 0)
    ret = ret.replaceAll(replace_pattern, value01)

  if(value02.length > 0)
    ret = ret.replaceAll(replace_pattern02, value02)

  if(value03.length > 0)
      ret = ret.replaceAll(replace_pattern03, value03)

  return ret;
};

export default Translate;