import * as React from 'react';
import './FormsWidgets.css';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const userStyles = ({
    textField: {
      marginLeft: 1,
      marginRight: 50,
      marginBottom: 20,
      fontSize:'16px',
      minWidth: 200
    },
    textFieldFullWidth: {
      width:'100%',
      fontSize:'16px',
      marginBottom: 20
    },
    textFieldMiddleWidth: {
      marginLeft: 1,
      marginRight: 15,
      width:'30%',
      fontSize:'16px',
      marginBottom: 20,
      minWidth: 200
    },
    formControl: {
        marginRight: 20,
        marginBottom: 20,
        marginTop:15,
        minWidth: 180
    },
});

function returnStyle(typeStyle:number){
    switch(typeStyle){
        case 1:
            return userStyles.textField
        case 2:
            return userStyles.textFieldMiddleWidth
        case 3:
            return userStyles.textFieldFullWidth
        default:
            return userStyles.textField
    }
}

interface ITextFieldProps {
    name: string;
    fieldsData: any;
    id: string;
    handleFieldChange: Function;
    disabled: boolean;
    type: string;
    required:boolean;
    style:number;
    helper:string;
    maxText:number;
    noError:boolean;
    shrink:boolean;
    size:string;
    multiline?:boolean;
    placeholder?:string;
    rows?:number;
}

const TextFieldInput = (props: ITextFieldProps) => {

    let handler = (evt: any) => props.handleFieldChange(props.id, evt, props.type)
    // let validate = () => {
    //     if (props.canBeEmpty === false && props.deviceData[props.id] === '') {
    //         return false;
    //     }
    //     if (props.isNumber === true && (typeof props.deviceData[props.id]) === '')
    // }
    return (
        <TextField
        required={props.required}
        error={!props.noError}
        helperText={props.helper}
        multiline={props.multiline}
        id={props.id}
        type={props.type}
        minRows={props.rows}
        label={props.name}
        style={returnStyle(props.style)}
        value={props.fieldsData[props.id]?String(props.fieldsData[props.id]):""}
        onChange={handler}
        margin="normal"
        variant="outlined"
        disabled={props.disabled}
        placeholder={props.placeholder}
        
        inputProps={{
            maxLength: props.maxText,
        }}
        InputLabelProps={{
            shrink: props.shrink,
        }}
        size={props.size=="small"?"small":"medium"}
        />
    );
}

interface ISelectorFieldProps {
    name: string;
    fieldsData: any;
    id: string;
    deviceData: any;
    handleFieldChange: Function;
    size?: any;
    disabled: boolean;
    //data: Array<any>;
    children?: any;
    style:number;
    required:boolean;
    noError?:boolean;
}

const SelectorField = (props: ISelectorFieldProps) => {
    let handler = (evt: any) => props.handleFieldChange(props.id, evt);
    return (
        <FormControl variant="outlined" style={userStyles.formControl}>
            {props.name.length > 0?
                <InputLabel disabled={props.disabled} style={{backgroundColor:'rgba(221, 221, 221, 0.9)', paddingRight:5, paddingLeft:5}} shrink={props.deviceData[props.id]?true:false} id={props.id} required={props.required}>
                    <label style={{fontSize:'16px'}}>{props.name}</label>
                </InputLabel>
            :
                null
            }
            <Select
                id={props.name}
                autoWidth
                value={props.deviceData[props.id]?props.deviceData[props.id]:-1}
                onChange={handler}
                disabled={props.disabled}
                error={!props.noError}
                //labelWidth={labelWidth}
            >
                {props.fieldsData.map((item:any, index:number)=>{
                    return (
                        <MenuItem style={{overflowX:'auto'}} key={index} value={item.id}>{item.name}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

interface ICheckBoxFieldProps {
    name: string;
    fieldsData: any;
    id: string;
    handleFieldChange: Function;
    disabled: boolean;
    children?: any;
    index?:number;
    shrink:boolean;
    size:string;
    type: string;
}

const CheckBoxField = (props: ICheckBoxFieldProps) => {

    let handler = (evt: any) => props.handleFieldChange(props.id, evt, props.type, props.index)
    return (
        <FormControlLabel
        control={
            <Checkbox
            
            size={props.size=="small"?"small":"medium"}
            disabled={props.disabled}
            checked={props.fieldsData[props.id]==1?true:false}
            onChange={handler}
            inputProps={{
            'aria-label': 'primary checkbox',
            }}
            />
            }
            label={props.name}
        />
    );
}

interface ISwitchFieldProps {
    name: string;
    fieldsData: any;
    id: string;
    handleFieldChange: Function;
    disabled: boolean;
    color?: any;
}
const SwitchField = (props: ISwitchFieldProps) => {

    let handler = (evt: any) => props.handleFieldChange(props.id, evt)
    return (
        <Switch
            disabled={props.disabled}
            checked={props.fieldsData[props.id]==1?true:false}
            onChange={handler}
            color={props.color?props.color:"primary"}
            name={props.name}
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    );
}

export { TextFieldInput, SelectorField, CheckBoxField, SwitchField };