import { IFarmer } from "../Library/types";

let listShops:any=undefined;
let allowRefreshShops:boolean=true;

let listProducts:any=undefined;
let allowRefresProducts:boolean=true;

let listPedidos:any=[];
let allowRefreshPedidos:boolean=true;

let listFarmers:Array<IFarmer> = [];
let allowRefreshFarmers:boolean=true;

class appData {

    static getListShops(){
        return listShops;
    }

    static setListShops(newListShops:any){
        listShops=newListShops;
    }

    static getListFarmers(){
        return listFarmers;
    }

    static setListFarmers(data:Array<IFarmer>){
        listFarmers = data;
    }

    static getListProducts(){
        return listProducts;
    }

    static setListProducts(newListProducts:any){
        listProducts=newListProducts;
    }

    static getListPedidos(){
        return listPedidos;
    }

    static setListPedidos(newListPedidos:any){
        listPedidos=newListPedidos;
    }

    static getAllowRefreshShops(){
        return allowRefreshShops;
    }

    static setAllowRefreshShops(newState:boolean){
        allowRefreshShops=newState;
    }

    static getAllowRefresProducts(){
        return allowRefresProducts;
    }

    static setAllowRefresProducts(newState:boolean){
        allowRefresProducts=newState;
    }

    static getAllowRefreshPedidos(){
        return allowRefreshPedidos;
    }

    static setAllowRefreshPedidos(newState:boolean){
        allowRefreshPedidos=newState;
    }
}
export default appData;