import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Layout from './Layout';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import {filter, includes, cloneDeep} from 'lodash';
import OrderService from '../ApiClients/OrderService';
import { SelectorField, CheckBoxField, TextFieldInput } from './Material/FormsWidgets';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {} from '../Library/helpers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import CameraIcon from '@material-ui/icons/CameraAltOutlined';
import ResetImg from '@material-ui/icons/HighlightOff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Material/Loader';
import { IIProduct, IMe } from '../Library/types';
import LoaderBeauty from './Material/activityProgress';
import {iva, sale_unit, category_sale, price_unit} from '../ApiClients/DummyData';
import CreateModal from './Material/CreateDialog';
import Camera from './Material/camera';
import UploadIcon from '../Assets/icons/uploadPhoto.svg';
import Translate from './Material/Traductor';

const listadoStyles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

function GetFieldsFormListado(lang:string){
    return(
        [
            [{
                id: 'name',
                name: Translate(lang, "label.home.03"),
                required: true,
                style:2,
                typeField:2,
            },
            {
                id: 'activated',
                name: Translate(lang, "label.home.04"),
                required: true,
                style:3,
                typeField:3,
            }],
            [{
                id: 'description',
                name: Translate(lang, "label.home.05"),
                required: false,
                style:3,
                typeField:2,
                multiline: true,
                maxText:150
            }],
            [
                {
                    id: 'base_price',
                    name: Translate(lang, "label.home.06"),
                    required: true,
                    style:1,
                    typeField:2,
                    type: 'number'
                },
                {
                    id: 'price_unit',
                    name: Translate(lang, "label.home.07"),
                    required: true,
                    style:1,
                    typeField:1,
                    disabled: true
                },
                {
                    id: 'minimunQuantity',
                    name: Translate(lang, "label.home.08"),
                    required: true,
                    style:1,
                    typeField:2,
                    type: 'number'
                }
            ],
            [    {
                id: 'iva',
                name: Translate(lang, "label.home.09"),
                required: true,
                style:3,
                typeField:1
            },
                {
                id: 'sale_unit',
                name: Translate(lang, "label.home.10"),
                required: true,
                style:1,
                typeField:1,
            },
        ],
            [
                {
                    id: 'category_sale',
                    name: Translate(lang, "label.home.11"),
                    required: true,
                    style:3,
                    typeField:1,
                },
        
            ],
            [
                {
                    id: 'uri_img',
                    typeField:4,
                }
            ],
            [
                
            ],
        ]
    )
}
/*
const fieldsFormListado = [
    [{
        id: 'name',
        name: 'Nombre',
        required: true,
        style:2,
        typeField:2,
    },
    {
        id: 'activated',
        name: 'Activo',
        required: true,
        style:3,
        typeField:3,
    }],
    [{
        id: 'description',
        name: 'Descripción',
        required: false,
        style:3,
        typeField:2,
        multiline: true,
        maxText:150
    }],
    [
        {
            id: 'base_price',
            name: 'Precio base',
            required: true,
            style:1,
            typeField:2,
            type: 'number'
        },
        {
            id: 'price_unit',
            name: 'Unidad monetaria',
            required: true,
            style:1,
            typeField:1,
            disabled: true
        },
        {
            id: 'minimunQuantity',
            name: 'Cantidad mínima de venta',
            required: true,
            style:1,
            typeField:2,
            type: 'number'
        }
    ],
    [    {
        id: 'iva',
        name: 'IVA',
        required: true,
        style:3,
        typeField:1
    },
        {
        id: 'sale_unit',
        name: 'Unidad de venta',
        required: true,
        style:1,
        typeField:1,
    },
],
    [
        {
            id: 'category_sale',
            name: 'Categoría',
            required: true,
            style:3,
            typeField:1,
        },

    ],
    [
        {
            id: 'uri_img',
            typeField:4,
        }
    ],
    [
        
    ],
];
*/
const item:IIProduct = {
    name: "",
    description: "",
    base_price: 0,
    sale_unit: '',
    category_sale: -1,
    price_unit: "€",
    uri_img: "https://spo-agrir.web.app/static/media/abast05.4a68af7d62c3a61a55c2579fa155685e.svg",
    iva: -1,
    activated: false,
    minimunQuantity: 1
}

interface IFormListadoItemProps {
    classes: any;
    item?:IIProduct;
    myInfo?: IMe;
    insideModel:boolean;
    callback?:Function;
    lang: string;
}

interface IFormListadoItemState {
    item:IIProduct;
    newProduct: boolean;
    loading: boolean;

    modalType: number;
    titleModal: string;
    typeAction: number;
    inputText: string;
    loadingModal: boolean;
    creationModalOpen: boolean;

    options: any;

    creationCameraOpen:boolean;
}

class FormProduct extends Component<IFormListadoItemProps, IFormListadoItemState> {
    constructor(props: IFormListadoItemProps) {
        super(props);
        this.state = {
            item: (props.item?props.item:item),
            newProduct: (!props.item?true:false),
            loading: false,

            modalType: -1,
            typeAction: -1,
            inputText:'',
            titleModal: '',
            creationModalOpen: false,
            loadingModal: false,

            options: {iva:iva, sale_unit: sale_unit, category_sale: category_sale, price_unit: price_unit},

            creationCameraOpen: false
        };
    }

    
    saveProduct = async (userId:string, action:number, data:any) => {
        const {lang} = this.props;

        let response = await OrderService.saveData(userId, action, data);

        if([-1,0].indexOf(response) > -1){
            this.setState({
                inputText: Translate(lang, "label.general.02"),
                modalType: 1,
                creationModalOpen: true,
                titleModal:Translate(lang, "label.general.01").toUpperCase(),
                loadingModal: false
            });
        }
        else {
            this.setState({
                loadingModal: false,
                creationModalOpen: false
            })
            if(this.props.callback)
                this.props.callback()
            /*if(!this.props.insideModel)
                this.props.navigate(`/farmer/${userId}/products`);
            else
                console.log('send order to close and load again data');*/
        }
    }
    
    handleCreateModal = (typeAction: number) => {
        const {myInfo} = this.props;

        switch(typeAction) {
            case 1: // Cuando genera un producto
                console.log('voy a crear el producto');
                if(!myInfo)
                    return;
                
                this.setState({
                    loadingModal: true
                }, ()=> this.saveProduct(myInfo.userId, 5, this.state.item))
                
                break;
            case 2: // Cuando actualiza un producto
                console.log('voy a actualizar el producto');

                if(!myInfo)
                    return;
                
                this.setState({
                    loadingModal: true
                }, ()=> this.saveProduct(myInfo.userId, 6, this.state.item))
                
                break;
            default:
                break;
        }
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onCloseCameraModal = () => {
        this.setState({ creationCameraOpen: false });
    }

    handleProductItem=(item:IIProduct)=>{
        const {lang} = this.props;

        let text = Translate(lang, "label.editProduct.01");
        let action = 1;

        if(!this.state.newProduct){
            text = Translate(lang, "label.editProduct.02");
            action = 2;
        }

        this.setState({
            inputText: text,
            modalType: 2,
            typeAction: action,
            creationModalOpen: true,
            titleModal:Translate(lang, "label.general.01").toUpperCase(),
        })
    }

    handlePhotoTaken = (img:string) => {
        let t_item = cloneDeep(this.state.item);
        t_item.uri_img = img;
        t_item.uri_img_refreshed = true;
        this.setState({
            item: t_item
        })
        /*const {sitemline} = this.state;
        if(!sitemline)
            return;
        const photoname = `${new Date().getTime()}.png`;
        this._going2upload.attachments.push({name: photoname, stream: img, centrotrabajoId: sitemline.centrotrabajoId, tipo: sitemline.tipo, listadoId: sitemline.listadoTableId, id: sitemline.id});

        const items = 
        <div>
            <p>{`¿Quiere adjuntar la foto?`}</p>
            <table>
                <thead><tr><th>{`Nombre`}</th><th>{`Tamaño`}</th></tr></thead>
                <tbody key={1}>
                    <tr><td style={{borderRight:'2px solid #337ab7'}}>{`${photoname}`}</td><td>{`1 MB`}</td></tr>
                </tbody>
            </table>
        </div>;

        this.setState({
            modalAction: 5,
            modalText: items,
            modalType: 2,
            creationModalOpen: true
        });*/
    }

    handleChangeUser=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.item);
        newItemListado[id] = event.target.value;
        this.setState({ item: newItemListado });
    }

    handleChangeCheckBox=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.item);
        newItemListado[id] = event.target.checked;
        this.setState({ item: newItemListado });
    }

    shouldDisableToday = (date:any) => {
        return false;
    }

    handleChangeDate=(id:any, event:any)=>{
        let newItemListado = Object.assign({}, this.state.item);
        newItemListado[id] = event;
        this.setState({ item: newItemListado });
    }

    verifyForm=(type:number, id?:string)=>{
        const item = this.state.item;

        if(type == 1){ // Verifica todo el formulario
            if(item.base_price <= 0){
                return false;
            } else if(item.category_sale == -1){
                return false;
            } else if(item.iva == -1){
                return false;
            } else if(item.minimunQuantity <= 0){
                return false;
            } else if(item.name.length == 0){
                return false;
            } else if(item.price_unit.length == 0){
                return false;
            } 
            else if(item.sale_unit.length == 0){
                return false;
            } /*else if(item.riesgo.length == 0){
                return false;
            } else if(item.detalleRiesgo.length == 0){
                return false;
            } */

            return true;
        } else if(type == 2){ // Verifica el ítem
            if(id == "name" && item.name.length == 0)
                return false;
            else if(id == "base_price" && item.base_price <= 0)
                return false;
            else if(id == "minimunQuantity" && item.minimunQuantity <= 0)
                return false;
            else if(id == "sale_unit" && item.sale_unit.length == 0)
                return false;
            else if(id == "iva" && item.iva == -1)
                return false;
            else if(id == "category_sale" && item.category_sale == -1)
                return false;
            return true;
        }

        return false;
    }

    handleResetItemForm = () => {
        this.setState({item:item});
    }

    handleResetPhotoTaken = () => {
        let t_item = cloneDeep(this.state.item);
        t_item.uri_img = (this.props.item?this.props.item.uri_img:item.uri_img);
        t_item.uri_img_refreshed = false;

        this.setState({
            item: t_item
        })
    }

    attachedfiles = (evt:any) => {
        const afiles = evt.target.files;

        const element = afiles[0];

        if((element.size/1048576) > 5){
            const {lang} = this.props;

            this.setState({
                inputText: Translate(lang, "label.editProduct.03"),
                modalType: 1,
                creationModalOpen: true,
                titleModal:Translate(lang, "label.general.01").toUpperCase(),
                loadingModal: false
            });
        } else {
            let reader = new FileReader();

            reader.onloadend = async ()=>{
                const img:any = reader.result;
                if(reader.result == null)
                    return;
                let t_item = cloneDeep(this.state.item);
                t_item.uri_img = String(reader.result);
                t_item.uri_img_refreshed = true;
    
                this.setState({
                    item: t_item
                });
            }
            reader.readAsDataURL(element);
        }
    }

    render() {

        const {item, lang} = this.props;
        const {options, loading, newProduct} = this.state;
        
        let fieldsTMP = GetFieldsFormListado(lang).map((n0:any, index:number)=>{
            let fieldsN0 = n0.map((n:any, index:number)=>{
                switch(n.typeField){
                    case 1:
                        return(
                            <SelectorField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={options[n.id]?options[n.id]:[]}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                required={n.required}
                                style={n.style}
                                disabled={n.disabled}
                                deviceData={this.state.item}
                                noError={n.required?this.verifyForm(2,n.id):true}
                            />
                        )
                    case 2:
                        return(
                            <TextFieldInput
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.state.item}
                                handleFieldChange={this.handleChangeUser}
                                disabled={n.disabled}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                required={n.required}
                                style={n.style}
                                helper={n.helper}
                                maxText={n.maxText}
                                noError={n.required?this.verifyForm(2,n.id):true}
                                multiline={n.multiline}
                            />
                        )
                    case 3:
                        return(
                            <CheckBoxField
                                key={index}
                                name={n.name}
                                id={n.id}
                                fieldsData={this.state.item}
                                handleFieldChange={this.handleChangeCheckBox}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                type={n.type}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                            />
                        )
                    case 3:
                        return(
                            ``
                            /*<div style={{marginBottom:20}}>{DatePicker(n.id, n.name, "dd/MM/yyyy", this.handleChangeDate, this.state.itemListado.fechaPrevista, false, this.shouldDisableToday, new Date())}</div>*/
                        );
                    case 4:
                        return(
                            <div style={{marginBottom:20, textAlign:'center', position:'relative', margin:'auto'}}>
                                <div style={{position:'absolute', right:0, bottom:0}}>
                                    <IconButton size='small' onClick={()=>this.setState({creationCameraOpen:true})} style={{ float: 'left' }}>
                                        <CameraIcon />
                                    </IconButton>
                                </div>
                                <div style={{position:'absolute', left:0, bottom:0}}>
                                    <IconButton size='small' onClick={()=>this.handleResetPhotoTaken()} style={{ float: 'left' }}>
                                        <ResetImg />
                                    </IconButton>
                                </div>
                                <div style={{position:'absolute', right:5, top:0}}>
                                    {/*<label style={{cursor:'pointer'}} htmlFor="attachFileLine">{`${option.label}`}</label>*/}
                                    <label style={{cursor:'pointer'}} htmlFor="attachFileLine">
                                        <img width={22} height={22} src={UploadIcon}/>    
                                    </label>
                                    <input style={{display:'none'}} multiple={false} onChange={(evt:any)=>this.attachedfiles(evt)} title={"Adjuntar foto"} accept="image/*" type="file" id="attachFileLine"/> 
                                </div>
                                <img
                                    style={{borderRadius:'50%', objectFit:'contain', maxHeight:200}}
                                    src={this.state.item[n.id]}
                                    width={200}
                                    height='auto'
                                />
                            </div>
                        );
                    default:
                        return('')
                }
            })
            return (
                <form key={index} style={{display:'flex', flexWrap:'wrap'}} noValidate autoComplete="off">
                    {fieldsN0}
                </form>
            )
        })

        return (
            <div>
                {fieldsTMP}

                {!loading?
                    <div style={{marginLeft:'auto', marginRight:'auto', marginTop:20, textAlign:'center'}}>
                        <Button style={{width:'50%', border:'2px solid #ddd'}} disabled={!this.verifyForm(1)} onClick={()=>this.handleProductItem(this.state.item)} color="primary">
                            {`${newProduct?Translate(lang, "label.editProduct.04"):Translate(lang, "label.editProduct.05")}`}
                        </Button>
                    </div>
                :
                    <Loader loading={true}/>
                }

                {this.state.creationModalOpen?
                    <CreateModal
                        onCloseCreationModal={this.onCloseCreationModal}
                        isOpen={this.state.creationModalOpen}
                        inputText={this.state.inputText}
                        typeModal={this.state.modalType}
                        action={this.handleCreateModal}
                        typeAction={this.state.typeAction}
                        loadingModal={this.state.loadingModal}
                        titleModal={this.state.titleModal}
                    />
                :
                    null
                }

                {this.state.creationCameraOpen?
                    <Camera
                        onCloseCamera={this.onCloseCameraModal}
                        isOpen={this.state.creationCameraOpen}
                        handlePhotoTaken={this.handlePhotoTaken}
                    />
                :
                    null
                }
            </div>
        );
    }
}
export default withStyles(listadoStyles)(FormProduct);