import { fetchAPI, GetLanguage, zLibInflate } from '../Library/helpers';
import appData from './appData'
import { IFarmer, IMarker, IMe, IPedido, IProduct } from '../Library/types';
import { cloneDeep, filter, find, findIndex } from 'lodash';
import {projects} from './DummyData';
import {GetUserLogged, RemoveDocument, GetDocument, GetDocuments, Test} from '../Library/sp_firebase';
import { writeLocalStorage, readLocalStorage } from '../Library/sp_general';

const baseURL = 'https://fnc-abast-app-01.azurewebsites.net/api/';

class OrderService {

  static loadFarmers = async () => {
    let ret:Array<IFarmer> = [];
    try{
        const farmers = appData.getListFarmers();
        if(farmers.length > 0)
          ret = farmers;
        else {
          let url = `${baseURL}loadFarmers`

          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, true);
  
          //console.log('loadFarmers', response.data)
          if(response.valid)
            ret = response.data;
        }

    }
    catch(err){
      ret = [];
    }
    finally{
      appData.setListFarmers(ret);

      return ret;
    }
  }

  static loadProducts = async (userId?:string, farmerId?:string) => {
    try{
        /*if(appData.getListProducts() && appData.getListProducts().length > 0)
            //await OrderService.getProducts(shopRef, true, dataareaid)
            return appData.getListProducts();*/

        let url = `${baseURL}loadProducts`

        if(farmerId)
          url = `${baseURL}loadProducts?farmerId=${farmerId}&userId=${userId}`

        let originalDate = new Date().getTime();
        let response = await fetchAPI(url, 'GET', true, undefined, true);

        //console.log('loadProducts', response.data)

        if(response.valid){
          appData.setListProducts(response.data);
          return response.data;
        }

        return [];
    }
    catch(err){
        return [];
    }
  }

  static savePedido = async (pedidos:Array<IPedido>) => {
    try {
        let iPedido:Array<IPedido> = appData.getListPedidos();
        pedidos.map((pedido:IPedido)=>{
          iPedido.push(pedido);
        });
        appData.setListPedidos(iPedido);

        writeLocalStorage('pedidos', JSON.stringify(iPedido))

        return 1;
    }
    catch(err){
        appData.setListPedidos([]);
        return [];
    }
  }

  static savePedido02 = async (pedido:IPedido) => {
    try {
        //let iPedido:Array<IPedido> = appData.getListPedidos();
        let x = readLocalStorage('pedidos');

        if(x === null || x === undefined)
          return [];
        let iPedido:Array<IPedido> = JSON.parse(x);

        let idx = findIndex(iPedido, function(o){return o.id == pedido.id});

        if(idx == -1)
          return 1;

        iPedido[idx] = pedido;
        appData.setListPedidos(iPedido);

        writeLocalStorage('pedidos', JSON.stringify(iPedido))

        return 1;
    }
    catch(err){
        appData.setListPedidos([]);
        return [];
    }
  }

  static getPedidos_old = async (userId:string) => {
    try {
      let promise =  new Promise((resolve, reject)=>{
        GetDocuments(`pedidos_${userId}`, true, `/users/${userId}/pedidos`)
          .then(ret=>{
            if(ret){
              let data:Array<any> = [];
              ret.forEach((doc:any) => {
                console.log(doc)
              // doc.data() is never undefined for query doc snapshots
                data.push(doc.data())
              });
              data.sort((a,b)=>a.date - b.date)
              resolve(data)
            }
            else
              reject([])
          })
          .catch(err=>{
            console.log(err)
            reject([])
          });
      })

      return promise;
        //if(appData.getAllowRefreshPedidos() !==false) {
            //let response = await fetchAPI(url, 'GET', true, undefined, false);
            //appData.setListPedidos(response);
        //    appData.setAllowRefreshPedidos(false);
        //}
        //return appData.getListPedidos();
    }
    catch(err){
        //appData.setListPedidos([]);
        return [];
    }
  }

  static getPedidos = async (userId:string, type:number) => {
    try{
      /*if(appData.getListProducts() && appData.getListProducts().length > 0)
          //await OrderService.getProducts(shopRef, true, dataareaid)
          return appData.getListProducts();*/

      let url = `${baseURL}loadPedidos?userId=${userId}&type=${type}`

      let originalDate = new Date().getTime();
      let response = await fetchAPI(url, 'GET', true, undefined, true);

      //console.log('getPedidos', response.data)

      if(response.valid){
        appData.setListPedidos(response.data);
        return response.data;
      }

      return [];
  }
  catch(err){
      return [];
  }
  }

  static getPedido = async (userId:string, id:string, type:number) => {
    try {
      let pedidos = appData.getListPedidos();
      if(!pedidos || pedidos.length == 0){
          await OrderService.getPedidos(userId, type)
      }
      pedidos = appData.getListPedidos();
      let pedido = pedidos.find((currPedido:IPedido) => {
          if(currPedido.id){
              return currPedido.id == id;
          }
      });
      return pedido;
    }
    catch(err){
        //appData.setListPedidos([]);
        return undefined;
    }
  }

  static getPedido_old = async (userId: string, id:string) => {
    try {
      let promise =  new Promise((resolve, reject)=>{
        GetDocument(`pedidos${userId}`, id, `/users/${userId}/pedidos/${id}`)
          .then(ret=>{
            if(ret)
              resolve(ret);
            else
              reject(undefined)
          })
          .catch(err=>{
            console.log(err)
            reject(undefined)
          });
      })

      return promise;
        //if(appData.getAllowRefreshPedidos() !==false) {
            //let response = await fetchAPI(url, 'GET', true, undefined, false);
            //appData.setListPedidos(response);
        //    appData.setAllowRefreshPedidos(false);
        //}
        //return appData.getListPedidos();
    }
    catch(err){
        //appData.setListPedidos([]);
        return [];
    }
  }

  static getProduct = async (productId: string) => {
    try{
      let products = appData.getListProducts();
        if(!products) {
            products = await OrderService.loadProducts()
            appData.setListProducts(products);
        }
        let product = appData.getListProducts().find((currProd:IProduct) => {
            return currProd.id === productId;
        });
        return Promise.resolve(product);
    } catch(err) {
        return undefined
    }
  }

static getDashboard = async (userId:string) => {
  try{
      /*if(appData.getListProducts() && appData.getListProducts().length > 0)
          //await OrderService.getProducts(shopRef, true, dataareaid)
          return appData.getListProducts();*/

      let url = `${baseURL}dashboard?userId=${userId}`

      let originalDate = new Date().getTime();
      let response = await fetchAPI(url, 'GET', true, undefined, true);

      //console.log('getDashboard', response.data)

      if(response.valid){
        return response.data;
      }

      return undefined;
  }
  catch(err){
      return undefined;
  }
}

      /*static loadDashboard = async (farmId:string,dataareaid:string, dashboard:number) => {
        try {
          let dashboards = appData.getExploitationData()?.data.dashboards;

          if(dashboards == undefined){
            let response = await OrderService.loadExploitation(farmId,dataareaid, true);

            if(response){
              dashboards = response.data.dashboards;
            }
          }
          
          let nDashboard = filter(dashboards, function(o){return o.parentId == dashboard});

          return nDashboard;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }*/

      /*static loadHistoricoData = async (listId, startDay:string, endDay:string, dataType:number) => {
        try {
          let url = `${baseURL}ReturnValues?startDay=${startDay}&endDay=${endDay}&dataType=${dataType}`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'POST', true, {...listId}, false);
          let finalDate = new Date().getTime();
          let diff = finalDate - originalDate;
          //console.log('loadHistoricoData', `${response.length} items loaded in ${diff} ms`)
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }*/
  
      /*static loadDeviceList = async (farmId:string, dataareaid:string) => {
        try {
          let url = `${baseURL}LoadDeviceList?farmId=${farmId}&dataareaid=${dataareaid}`
          console.log(url)
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          let finalDate = new Date().getTime();
          let diff = finalDate - originalDate;
          //console.log('loadDeviceList', `${response.length} items loaded in ${diff} ms`)
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }*/
  
      /*static updateAlarms = async (updatedData) => {
        try {
          //console.log('updatedData', updatedData)
          let url = `${baseURL}UpdateAlarms`
          let response = await fetchAPI(url, 'POST', true, {...updatedData}, false);
          //console.log(response)
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }
  
      static updateNews = async (updatedData) => {
        try {
          //console.log('updatedData', updatedData)
          let url = `${baseURL}UpdateNews`
          let response = await fetchAPI(url, 'POST', true, {...updatedData}, false);
          //console.log(response)
          return response;
        } catch (err) {
            console.log(err);
            return -1;
        }
      }
  
      static updateLimitsCfg = async (updatedData) => {
        try {
          let url = `${baseURL}UpdateCfg`
          let response = await fetchAPI(url, 'POST', true, {...updatedData}, false);
          //console.log(response)
          return response;
        } catch (err) {
            console.log(err);
            return {response:0, msg:'Error en frontend'};
        }
      }*/
  
      static saveData = async (userId:string, action:number, data:any) => {
        try {

          let url = `${baseURL}saveData?userId=${userId}&action=${action}`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'POST', true, {...data}, true);

          //console.log('saveData', response.data)
          if(response.valid)
            return response.data;

          return 0;

        } catch (err) {
            console.log(err);
            return 0;
        }
      }


      static me = async () => {
        try {
          console.log('me')
          let url = `${baseURL}me`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, true);
          return response
          /*if(response.data !== null && response !== undefined){
            let nData = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            //console.log('me', nData)
            console.log('me', nData)
            return nData;
          }*/
          return undefined;

        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static me02 = async () => {
        try {

          let dale:any = await GetUserLogged();

          if(dale == null)
            return null;

          let url = `${baseURL}me`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'POST', true, dale, true);

          /*const item = response.body.multiFactor.user;

          response.userId = item.uid;

          if(response.userId == "pWkjZTkpwLfInsD0lRwgxkQhHn42")
            response.profile = 10;
          else
            response.profile = 20;*/

          //console.log('me02', response.data)
          if(response.valid)
            return response.data;

          return undefined;

        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static me_bck = async () => {
        try {
          console.log('me')
          return new Promise((resolve, reject)=>{
            GetUserLogged()
              .then((ret:any)=>{
                if(ret == null || !ret)
                  reject(null);
                else {
                  GetDocument('users', ret.uid, `/users/${ret.uid}/settings/account`)
                    .then(data=>{
                      console.log(data)
                      let n_me:any = {
                        userId: ret.uid,
                        displayName: ret.displayName,
                        email: ret.email,
                        refreshToken: ret.refreshToken,
                        phone: ret.phoneNumber,
                        authorized: true,
                        activated: false,
                        registered: false,
                        farmers: []
                      };
                      if(data){
                        n_me.registered = true;
                        n_me.activated = data.subscription.activated;
                        n_me.subscription = data.subscription;
                        n_me.farmers = data.farmers;
                      }
                      resolve(n_me)
                    })
                    .catch(err=>{
                      console.error(err)
                    })

                }

              })
              .catch(err=>{
                console.error(err);
                reject(null)
              })
          })
          let url = `${baseURL}me`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          response.data.projects = projects;
          return response.data
          /*if(response.data !== null && response !== undefined){
            let nData = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            //console.log('me', nData)
            console.log('me', nData)
            return nData;
          }*/
          return undefined;

        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static GetLibrary = async (projectId:string, skip: number, offset: number, datetime: string, process: number, prediction: number, tags: Array<any>) => {
        try {
          let q_tags:string = "";
          if(tags.length > 0) {
            tags.map((tag:any, idx:number)=>{
              q_tags += `&tagIds=${tag.name}`;
            });
          }

          let url = `${baseURL}LoadLibrary?projectId=${projectId}&skip=${skip}&offset=${offset}&datetime=${datetime}&process=${process}&prediction=${prediction}${(q_tags.length > 0?q_tags:'')}`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          return response[0]
          /*if(response.data !== null && response !== undefined){
            let nData = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            //console.log('me', nData)
            console.log('me', nData)
            return nData;
          }*/
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static Save2Training = async (projectId:string, data: any) => {
        try {
          let url = `${baseURL}Save2Training?projectId=${projectId}`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'POST', true, {...data}, false);
          return response
          /*if(response.data !== null && response !== undefined){
            let nData = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            //console.log('me', nData)
            console.log('me', nData)
            return nData;
          }*/
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }
  
      static RemoveFromTraining = async (projectId:string, rowkey: string) => {
        try {
          let url = `${baseURL}RemoveFromTraining?projectId=${projectId}&rowkey=${rowkey}`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'DELETE', true, undefined, false);
          return response
          /*if(response.data !== null && response !== undefined){
            let nData = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            //console.log('me', nData)
            console.log('me', nData)
            return nData;
          }*/
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static downloadData = async (data:any) => {
        try {
          let url = `${baseURL}DownloadData`
          let response = await fetchAPI(url, 'POST', true, {...data}, false);
          //console.log(response)
          if(response.data !== null){
            return zLibInflate(response.data);
          }
          return undefined;
        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static LoadInvitations = async () => {
        try {
          let url = `${baseURL}LoadInvitations`
          let response = await fetchAPI(url, 'get', true, undefined, false);
          //console.log(response)
          if(response.data !== null){
            return zLibInflate(response.data);
          }
          return undefined;
        } catch (err) {
            console.log(err);
            return [];
        }
      }
  
      static metricService = async (metricType:number, me:any, version: string) => { // Llamadas de registro de métricas //
        try {
            const navigator:any = window.navigator;
            let _navigator:any = {};
            for (var i in navigator) _navigator[i] = navigator[i];
            const language = GetLanguage('language');

            const origin = {version: version, me: me, language: language, origin:window.location.href, navigator: JSON.stringify(_navigator)};

            let url = `${baseURL}metrics?metricType=${metricType}`
            await fetchAPI(url, 'PUT', true, {...origin}, false);
        } catch(err) {
  
        }
      }
}
export default OrderService;