import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CamIcon from '@material-ui/icons/PhotoCamera';
import CleanPhotoIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';
import ChangeCam from '@material-ui/icons/FlipCameraAndroid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop:20,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      border:'3px solid rgb(0, 43, 97, 0.60)',
      borderRadius:'5px'
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface ICameraProps {
  handlePhotoTaken: Function;
  onCloseCamera: any;
  isOpen: boolean;
}

export default function Camera(props: ICameraProps) {
    const classes = useStyles();
    const [selected, setSelected] = React.useState<string[]>([]);
    const [initialized, setInitialized] = React.useState(false);
    const [visibilityCam, setCamVisibility] = React.useState(true);
    const [wcam, setwidth] = React.useState(100);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [camselected, setCamselected] = React.useState<number>(0);
    const camtypes = ["environment", "user"];

    let width = 0;
    let height = 0;

    const startCamera = (w = 680, h = 480) => {
        let video:any = document.getElementById('video');

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            if(initialized)
                return;
            width = w;
            height = h;

            //this.video = document.getElementById('video');
            //this.canvas = document.getElementById('canvas');
            //this.context = this.canvas.getContext('2d');
            const constraints = {
                video: {
                  facingMode: camtypes[camselected]
                },
            };
            
            (function (video) {
                navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                    video.srcObject = stream;
                    video.play();
                    setTimeout(() => {
                        setInitialized(true);
                    }, 1000);
                });
            })(video)

        }
    }

    const takeSnapshot = () => {
        let canvas:any = document.getElementById('canvas');
        let context:any = canvas.getContext('2d');
        let video:any = document.getElementById('video');

        //this.context.drawImage(this.video, 0, 0, width, height);
        canvas.width = video.videoWidth - 100;
        canvas.height = video.videoHeight;

        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        setCamVisibility(false);
        //this.canvas.getContext('2d').drawImage(this.video, 0, 0, width, height);
    }

    const stopCamera=()=> {
        let video:any = document.getElementById('video');
        const stream = video.srcObject;

        if(stream == null)
            return;
        const tracks = stream.getTracks();
      
        tracks.forEach(function(track:any) {
          track.stop();
        });
        video.pause();
        video.src = "";

        video.srcObject = null;
    }

    const closeCamera = () => {
        setCamVisibility(true);
        stopCamera();
        props.onCloseCamera();
    }

    const savePhoto = () => {
        let canvas:any = document.getElementById('canvas');
        props.handlePhotoTaken(canvas.toDataURL());
        setCamVisibility(true);
        stopCamera();
        props.onCloseCamera();
    }

    const handleChangeCam = () => {
        setCamVisibility(true);
        setInitialized(false);
        stopCamera();
        setCamselected(camselected==0?1:0);
        startCamera();
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        //cam.startCamera();
        setTimeout(() => {
            startCamera();
        }, 500);
    });

    return (
    <Dialog
        open={props.isOpen}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        //maxWidth={"xl"} 
    >
        <DialogTitle style={{marginRight:30}} id="alert-dialog-slide-title">{`CAPTURA`}</DialogTitle>
        <IconButton aria-label="close" style={{position:'absolute', top:10, right:10}} onClick={closeCamera}>
            <CloseIcon />
        </IconButton>
        <DialogContent>
            <video
                id={'video'}
                width={'100%'}
                height={'auto'}
                autoPlay={true}
                hidden={!visibilityCam}
                playsInline
            />
            <canvas
                hidden={visibilityCam}
                id={'canvas'}
                width={'auto'}
                height={'auto'}
            />
     
        </DialogContent>
        <DialogActions style={{textAlign:'center', backgroundColor:'#ddd'}}>
            <IconButton disabled={!initialized} style={{marginLeft:'auto',marginRight:'auto', display:!visibilityCam?'none':'inline'}} aria-label="close" onClick={()=>takeSnapshot()} >
                <CamIcon />
            </IconButton>
            <IconButton disabled={!initialized} style={{marginLeft:'auto', display:visibilityCam?'none':'inline'}} aria-label="close" onClick={()=>setCamVisibility(true)} >
                <CleanPhotoIcon />
            </IconButton>
            <IconButton disabled={!initialized} style={{marginLeft:'auto', marginRight:'auto', display:visibilityCam?'none':'inline'}} aria-label="close" onClick={()=>savePhoto()} >
                <SaveIcon />
            </IconButton>
            <IconButton disabled={!initialized} style={{marginLeft:'auto',marginRight:'auto', display:!visibilityCam?'none':'inline'}} aria-label="close" onClick={()=>handleChangeCam()} >
                <ChangeCam />
            </IconButton>
        </DialogActions>
    </Dialog>
    );
}
/*
const camera = function () {
    let width = 0;
    let height = 0;
    
    const createObjects = function () {
    
    
        const video = document.createElement('video');
        video.id = 'video';
        video.width = width;
        video.width = height;
        video.autoplay = true;
        document.body.appendChild(video);
    
        const canvas = document.createElement('canvas');
        canvas.id = 'canvas';
        canvas.width = width;
        canvas.width = height;
        document.body.appendChild(canvas);
    }
    
    
    return {
        video: null as any,
        context: null as any,
        canvas: null as any,
    
        startCamera: function (w = 680, h = 480) {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                width = w;
                height = h;
    
                createObjects();
    
                this.video = document.getElementById('video');
                this.canvas = document.getElementById('canvas');
                this.context = this.canvas.getContext('2d');
    
    
                (function (video) {
                    navigator.mediaDevices.getUserMedia({video: true}).then(function (stream) {
                        video.srcObject = stream;
                        video.play();
                    });
                })(this.video)
    
            }
        },
    
    
        takeSnapshot: function () {
            //this.context.drawImage(this.video, 0, 0, width, height);
            this.canvas.getContext('2d').drawImage(this.video, 0, 0, width, height);
        
            return this.canvas.toDataURL();        
        },

        closeCamera: function () {
            const stream = this.video.srcObject;
            const tracks = stream.getTracks();
          
            tracks.forEach(function(track:any) {
              track.stop();
            });
          
            this.video.srcObject = null;
        },
    
    }
    }();
    
    export default camera;*/