import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchField from './Material/SearchField';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import UserIcon from '@material-ui/icons/AccountCircleRounded';
import Map from '@material-ui/icons/MapOutlined';
import Drawer from './Sidebar';
import { authContext } from '../Library/adalConfig';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IFilter, IMarker, IMe } from '../Library/types';
//import LogoWhite from '../Assets/icons/abast05.svg';
import LogoWhite from '../Assets/icons/abast10.png';
//import LogoWhite from '../Assets/icons/abast07.png';
//import LogoWhite from '../Assets/icons/fa-refresh.svg';
import BasketIcon from '@material-ui/icons/ShoppingBasketOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import background from '../Assets/images/agr.jpg';
import {signOut} from '../Library/sp_firebase';
import Translate from './Material/Traductor';

function Navigate(navigate:any, path:string){
    navigate(`${path}`);
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerPaper: {
    position: 'absolute',
    top:143,
    backgroundColor:'#003B71',
  },
});



interface ILayoutProps {
    navigate:any;
    params:any;
    filters?: IFilter;
    onItemClick?: any;
    onFilterChange?: any;
    onSearchChange?:any;
    onClearClick?: any;
    onDrawerChange?: any;
    onKeydown?:any;
    handleFilterClick?:any;
    searchValue:string;
    placeholderSearch:string;
    badgeAlarms:number;
    marker?:IMarker;
    navBar?:any;
    me?:IMe;
    version: string;
}

export default function Layout(props: ILayoutProps) {
    const classes = useStyles();
    const [opened, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElEvents, setAnchorElEvents] = React.useState(null);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickEvents = (event:any) => {
        setAnchorElEvents(event.currentTarget);
    };
    const handleCloseEvents = () => {
        setAnchorElEvents(null);
    };

    const handleDrawerOpenClose = () => {
        //props.onDrawerChange(!opened);

        setOpen(!opened);
    };

    const appLogOut = () => {
        authContext.logOut();
    }

    const handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica el cierre de sesión de la app
                //this.appLogOut();
                window.location.href = ``;
                break;
            default:
                break;
        }
    }

    const handleExitApp = () =>{
        console.log('voy a cerrar session...');
        signOut();
    }

    const onItemClick = (value:string) => {
        handleDrawerOpenClose();
        props.navigate(value)
    }

    return(
        <div>
        <div className='header-content' >
            <table style={{width:'100%'}}>
                <tbody>
                    <tr>
                        <td style={{width:'10%'}}>
                            <div style={{ textAlign: 'left' }}>
                                {props.me?
                                    <IconButton style={{margin:10, zIndex:20}} onClick={()=>handleDrawerOpenClose()} size="small" aria-label="Notificaciones">
                                        <MenuIcon style={{fill:'#fff'}} />
                                    </IconButton>
                                :
                                    null
                                }
                            </div>
                        </td>
                        <td style={{width:'80%'}}>
                            {<div className={/*"hide-on-mobile-tablet"*/""} style={{marginBottom:0, textAlign:'center'}}>
                                {/*<strong style={{color:'#FFF'}}>{`BIENVENID@ A AGRIR`}</strong>*/}
                                <div>
                                    <img style={{cursor:'pointer', backgroundColor:'#fff', borderRadius:'50%'}} onClick={()=>Navigate(props.navigate, '/')} src={LogoWhite} alt='vcLogo' className='background-logo' />
                                </div>
                            </div>}
                        </td>
                        <td style={{ paddingBottom:0, textAlign:'right', width:'10%' }}>
                            {props.me?
                                <div style={{ display:'flex', justifyContent:'center', backgroundColor:'rgba(221, 221, 221, 1)', borderRadius:20 }}>
                                    <IconButton style={{marginRight:10, marginLeft:10}} onClick={()=>props.navigate('/')} size="small" aria-label="Home">
                                        <HomeIcon style={{fill:'#cc9966'}} />
                                    </IconButton>
                                    {[10].indexOf(props.me.profile) > -1?
                                        <div className="vl" >
                                            <IconButton style={{marginRight:10}} onClick={()=>props.navigate('/cesta')} size="small" aria-label="Cesta">
                                                <Badge badgeContent={props.badgeAlarms} color="error">
                                                    <BasketIcon style={{fill:'#e6b800'}} />
                                                </Badge>
                                            </IconButton>
                                        </div>
                                    :
                                        null
                                    }
                                    <div className="vl"></div>
                                    <IconButton  style={{marginRight:10}} onClick={handleClick} size="small" aria-label="Usuario">
                                        <UserIcon style={{fill:'#66ccff'}}/>
                                    </IconButton>
                                        <Menu
                                        style={{marginTop:40,width:'auto', minWidth:'200px'}}
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}

                                        >
                                            <div onClick={()=>Navigate(props.navigate, `/account`)}  style={{display:'flex',marginLeft:0, borderBottom:'1px #BBBBBB solid'}}>
                                                <MenuItem dense style={{width:'100%', maxWidth:'200px', fontSize:14}} onClick={handleClose}><a>{`${Translate(props.me.language, "label.layout.account")}`}</a></MenuItem>
                                            </div>
                                            {[10].indexOf(props.me.profile) > -1?
                                                <div onClick={()=>Navigate(props.navigate, `/purchases`)}  style={{display:'flex',marginLeft:0, borderBottom:'1px #BBBBBB solid'}}>
                                                    <MenuItem dense style={{width:'100%', maxWidth:'200px', fontSize:14}} onClick={handleClose}><a>{`${Translate(props.me.language, "label.layout.purchases")}`}</a></MenuItem>
                                                </div>
                                            :
                                                [20].indexOf(props.me.profile) > -1?
                                                    <div onClick={()=>Navigate(props.navigate, `/sales`)}  style={{display:'flex',marginLeft:0, borderBottom:'1px #BBBBBB solid'}}>
                                                        <MenuItem dense style={{width:'100%', maxWidth:'200px', fontSize:14}} onClick={handleClose}><a>{`${Translate(props.me.language, "label.layout.sales")}`}</a></MenuItem>
                                                    </div>
                                                :
                                                    null
                                            }
                                            {[20].indexOf(props.me.profile) > -1?
                                                <div onClick={()=>Navigate(props.navigate, `/farmer/${props.me?.userId}/products`)}  style={{display:'flex',marginLeft:0, borderBottom:'1px #BBBBBB solid'}}>
                                                    <MenuItem dense style={{width:'100%', maxWidth:'200px', fontSize:14}} onClick={handleClose}><a>{`${Translate(props.me.language, "label.layout.my.products")}`}</a></MenuItem>
                                                </div>
                                            :
                                                null
                                            }
                                            {/*<div onClick={()=>Navigate(props.navigate, `/news`)}  style={{display:'flex',marginLeft:0, borderBottom:'1px #BBBBBB solid'}}>
                                                <MenuItem dense style={{width:'100%', maxWidth:'200px', fontSize:14}} onClick={handleClose}><a>{`Mi Lista`}</a></MenuItem>
                            </div>*/}
                                            {/*props.me && [50,100].indexOf(props.me.role) > -1?
                                                <div onClick={()=>Navigate(props.navigate, `/iotdevice/register`)} style={{display:'flex',marginLeft:20, marginTop:10}}>
                                                    <div style={{margin:'auto'}}><img height={'30px'} src={undefined} /></div>
                                                    <MenuItem style={{width:'45vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Alta dispositivos`}</strong></MenuItem>
                                                </div>
                                            :
                                                null
                            */}
                                            {/*props.me && [30,50,100].indexOf(props.me.role) > -1?
                                            <div onClick={()=>Navigate(props.navigate, `/generalsettings`)} style={{display:'flex',marginLeft:20, marginTop:10}}>
                                                <div style={{margin:'auto'}}><img height={'30px'} src={undefined} /></div>
                                                <MenuItem style={{width:'45vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Ajustes generales`}</strong></MenuItem>
                                            </div>
                                            :
                                                null
                        */}

                                            {props.me.superadmin?
                                                <div onClick={()=>Navigate(props.navigate, `/admin`)} style={{display:'flex', borderBottom:'1px #BBBBBB solid'}}>
                                                    <MenuItem dense onClick={handleClose} style={{width:'100%', maxWidth:'200px', fontSize:14}}><a>{`${Translate(props.me.language, "label.layout.administrator")}`}</a></MenuItem>
                                                </div>
                                            :
                                                null
                                            }
                                            <div onClick={()=>handleExitApp()} style={{display:'flex', borderBottom:'1px #BBBBBB solid'}}>
                                                <MenuItem dense onClick={handleClose} style={{width:'100%', maxWidth:'200px', fontSize:14}}><a>{`${Translate(props.me.language, "label.signout")}`}</a></MenuItem>
                                            </div>
                                            <div style={{display:'flex', textAlign:'right'}}>
                                                <MenuItem dense disabled onClick={handleClose} style={{width:'45vw', maxWidth:'200px', textAlign:'right', display:'block', fontSize:10}}><strong>{props.version}</strong></MenuItem>
                                            </div>
                                        </Menu>

                                    {/*<div>*/}
                                        {/*<label style={{color:'#FFF'}}>{this.props.myInfo?.usuario}</label>*/}
                                        {/*<IconButton style={{marginLeft:5}} size="small" aria-label="Menú">
                                            <ArrowDropDownIcon style={{fill:'#FFF'}}/>
                                        </IconButton>
                                    </div>*/}
                                </div>
                            :
                                null
                            }
                        </td>
                    </tr>
                
                </tbody>
            </table>
        </div>
        {props.onSearchChange?
            <div style={{textAlign:'center', marginTop:20}}>
                <div style={{maxWidth:'900px', margin:'auto'}}>
                    <SearchField
                        value={props.searchValue}
                        allowSearch={true}
                        onValueChange={(evt:any)=>props.onSearchChange(evt)}
                        placeholder={props.placeholderSearch}
                        allowFilter={true}
                        onFilterClick={()=>props.handleFilterClick()}
                        onKeydown={(e:any)=>props.onKeydown(e)}
                        onClearClick={()=>props.onSearchChange(undefined)}
                    />
                </div>
            </div>
        :
            null
        }
        {props.me?
            <Drawer me={props.me} navBar={props.navBar} onItemClick={onItemClick} onToggle={handleDrawerOpenClose} params={props.params} navigate={props.navigate} opened={opened} anchor={'left'} filters={props.filters} onFilterChange={props.onFilterChange}/>
        :
            null
        }

        <div style={{ position: 'absolute', height:25, bottom: 0, left: 0, right: 0, backgroundColor:'#E0D4AE' }}>
            <div style={{paddingLeft:10}}>
                <span style={{fontSize:12}}><a href="https://abastim.cat/">{`Abastim Lleida - Entre Naltros`}</a> &copy; 2023 Abastim</span>
            </div>
        </div>
        </div>
    );
}
