import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IFarmer, IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {farmers} from '../ApiClients/DummyData';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import {readLocalStorage, writeLocalStorage, callPhone} from '../Library/sp_general';
import { GetViewDefaultType } from '../Library/helpers';
import Translate from './Material/Traductor';

//import {GetLogoEmpresa} from '../Library/helpers';

const tlf:string = `+34 686 01 49 62`;

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});

interface IContactProps {
    //history: any;
    //match: any;
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    cart: Array<IProduct>;
    pedido: IPedido;
    addOrRemoveFromCart: Function;
    updateCart: Function;
    checkPermission:Function;
    allowed:boolean;
    lang:string;
}

interface IContactState {
    loading: boolean;
    loadbeauty: boolean;
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;
    searchValue: string;
    viewType: number;
    titleModal:string;
}

class Farmers extends Component<IContactProps, IContactState> {
    constructor(props: IContactProps) {
        super(props);
        this.state = {
            loading: true,
            loadbeauty: false,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue: '',
            viewType: GetViewDefaultType(),
            titleModal: ''
        };
    }

    componentDidMount() {
        this.handleMyInfoChange();
    }

    componentDidUpdate = (prevprops:IContactProps) => {
        if(prevprops.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo)
        //this.loadData();
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica el cierre de sesión de la app
                console.log('voy a cerrar sesión');
                break;
            default:
                break;
        }
    }
    
    contact = (viewType:number, me:IMe, lang:string) => {
        return (
                <table style={{width:'100%', color:'#003B71'}}>
                    <tbody>
                        <tr>
                            <td>
                                <p>
                                    <a>
                                        {`${Translate(lang, "label.contact.01")}`}
                                    </a>
                                </p>
                                <p>
                                    <a style={{color:'#003B71', cursor:'pointer', textDecoration:'underline'}} onClick={()=>callPhone(tlf)}>
                                        {`${tlf}`}
                                    </a>
                                </p>
                                <p>
                                    <a style={{color:'#003B71'}}>
                                        <strong>{`${Translate(lang, "label.contact.02")}`}</strong>
                                    </a>
                                </p>
                                <p>
                                    <a href='mailto:abastim.lleida@gmail.com'>
                                        {`abastim.lleida@gmail.com`}
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

        );
    }

    handleSearchChange=(evt:any)=>{
        if(!evt){
            this.setState((prevState, props) =>({
                searchValue: '',
            }),);
        } else {
            this.setState((prevState, props) =>({
                searchValue: evt.target.value,
            }),);
        }
    }

    render() {
        const {myInfo, version, navigate, pedido, params, lang } = this.props;
        const {loadbeauty, searchValue, viewType } = this.state;

        if(myInfo == null)
            console.log('Usuario no autorizado');
            
        return (
            <div>
                {/*TutorialExplication()*/}
                <Layout
                    navigate={navigate}
                    params={params}
                    placeholderSearch={""}
                    badgeAlarms={pedido.cart.length}
                    version={version}
                    searchValue={searchValue}
                    me={myInfo}
                />
                <div className='home-content'>

                    {/*this.home()*/}
                    {loadbeauty || !myInfo?
                        <div style={{marginLeft:'50%',marginTop:'10%'}}>
                            <Loader loading={this.state.loading}/>
                        </div>

                    :
                        this.contact(viewType, myInfo, lang)
                    }

                    <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={false}
                            titleModal={this.state.titleModal}
                        />
                </div>
            </div>
        );
    }
}

export default withParamsAndNavigate(Farmers);