import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    width: 200,
    display: 'flex',
    alignItems: 'center'
  },
});

interface IRatingProps {
    precision: number;
    onValueChange?: any;
    value: number;
    labels: { [index: string]: string };
    readOnly: boolean;
}

export default function HoverRating(props:IRatingProps) {
  const [hover, setHover] = React.useState(-1);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Rating
        name="hover-feedback"
        value={props.value}
        precision={props.precision}
        onChange={(event, newValue) => {
          props.onValueChange(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        readOnly={props.readOnly}
      />
      {props.value !== null && <Box ml={2}>{props.labels[hover !== -1 ? hover : props.value]}</Box>}
    </div>
  );
}
