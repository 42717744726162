import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IContact, IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {} from '../ApiClients/DummyData';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import {onAuthStateChanged, signOut } from '../Library/sp_firebase';
import Translate from './Material/Traductor';
import {callPhone} from '../Library/sp_general';
import { SelectorField, CheckBoxField, TextFieldInput } from './Material/FormsWidgets';
import OrderService from '../ApiClients/OrderService';
import LogoWhite from '../Assets/icons/abast10.png';

//import {GetLogoEmpresa} from '../Library/helpers';
const tlf:string = `686 01 49 62`;

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});


interface IHomeProps {
    version: string;
    params?:any;
    navigate?:any;
    myInfo:IMe;
    lang:string;
}

interface IHomeState {
    loading: boolean;
    contactForm: IContact;
    resultContact?: number;
    sendingForm: boolean;

    inputText: any;
    creationModalOpen: boolean;
    modalType: number;
    typeAction: number;
    titleModal:string;
}

class Home extends Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            loading: false,
            contactForm: {
                userId: '',
                username: '',
                email: '',
                phone: '',
                message: '',
                profile: ''
            },
            sendingForm: false,

            inputText:'',
            creationModalOpen: false,
            modalType: -1,
            typeAction: -1,
            titleModal:'',
        };
    }

    componentDidMount=()=>{
        const {myInfo} = this.props;

        if(myInfo && myInfo.body)
            this.setState({
                contactForm: {
                    userId: myInfo.userId,
                    username: myInfo.displayName,
                    email: myInfo.body.email,
                    phone: '',
                    message: '',
                    profile: ''
                }
            })
    }

    componentDidUpdate = (prevprops:IHomeProps) => {
        if(prevprops.myInfo !== this.props.myInfo) {
            const {myInfo} = this.props;
            if(myInfo && myInfo.body)
                this.setState({
                    contactForm: {
                        userId: myInfo.userId,
                        username: myInfo.displayName,
                        email: myInfo.body.email,
                        phone: '',
                        message: '',
                        profile: ''
                    }
                })
        }

    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }


    goContact = async (userId:string, action:number, data:any) => {
        let response = await OrderService.saveData(userId, action, data);

        this.setState({
            resultContact: response,
            sendingForm: false
        })
    }

    handleChangeUser=(id:string, evt:any)=>{
        let newRegister = cloneDeep(this.state.contactForm);

        newRegister[id] = String(evt.target.value);

        this.setState({contactForm: newRegister});
    }

    RegisterControl = (register:IContact) => {
        if(register.username.length == 0)
            return false;

        if(register.phone.length < 9)
            return false;

        if(register.message.length == 0)
            return false;

        if(register.profile.length == 0)
            return false;
        return true;
    }

    handleSendContact=(me:IMe, form: IContact, lang:string)=>{
        if(!this.RegisterControl(form))
            this.setState({
                inputText: `${Translate(lang, "label.boarding.error.02")}`,
                modalType: 1,
                creationModalOpen: true
            });
        else
            this.setState({
                sendingForm: true
            }, ()=>this.goContact(me.userId, 7, form))
    }

    render() {
        const {version, navigate, myInfo, lang } = this.props;
        const {loading, contactForm, sendingForm, resultContact } = this.state;

        if(!myInfo)
            return(``);
        //console.log(contactForm)
        
        return (
            <div className='boarding-login-image-background'>
                <div style={{position:'relative', width:'100%'}}>
                    <div>
                        <a href="/#/login" aria-current="page" className="boarding-brand-logo-image" aria-label="home">
                            <img src={LogoWhite} loading="lazy" width={150} alt="" className="boarding-brand-logo-image" />
                        </a>
                    </div>

                    <div className='boarding-contact-form'>
                        <div style={{textAlign:'justify', marginLeft:'auto', marginRight:'auto'}}>
                            <p style={{lineHeight:2}}>
                                <a>{`${Translate(lang, "label.hi")} `}</a><a style={{fontWeight:'bold'}}>{`${myInfo.displayName}`}</a><a>{`, ${Translate(lang, "error.label.boarding.01")}`}</a>
                            </p>
                        </div>
                        <SelectorField
                            key={0}
                            name={`Perfil`}
                            id={"profile"}
                            fieldsData={[{id:20, name:'Productor/a'},{id:10, name:'Restaurador/a'}]}
                            handleFieldChange={this.handleChangeUser}
                            required={true}
                            style={3}
                            deviceData={contactForm}
                            disabled={false}
                            noError={contactForm.profile.length > 0?true:false}
                        />
                        <TextFieldInput
                            key={1}
                            name={`${Translate(lang, "label.boarding.03")}`}
                            id={`username`}
                            fieldsData={contactForm}
                            handleFieldChange={this.handleChangeUser}
                            size="small"
                            shrink={true}
                            type={"text"}
                            required={true}
                            style={3}
                            helper={``}
                            maxText={100}
                            noError={contactForm.username.length > 0?true:false}
                            disabled={false}
                        />  
                        <TextFieldInput
                            key={2}
                            name={`${Translate(lang, "label.boarding.04")}`}
                            id={`email`}
                            fieldsData={contactForm}
                            handleFieldChange={this.handleChangeUser}
                            size="small"
                            shrink={true}
                            type={"text"}
                            required={true}
                            style={3}
                            helper={``}
                            maxText={100}
                            noError={true}
                            disabled={true}
                        />  
                        <TextFieldInput
                            key={3}
                            name={`${Translate(lang, "label.boarding.05")}`}
                            id={`phone`}
                            fieldsData={contactForm}
                            handleFieldChange={this.handleChangeUser}
                            size="small"
                            shrink={true}
                            type={"text"}
                            required={true}
                            style={2}
                            helper={``}
                            maxText={20}
                            noError={contactForm.phone.length >= 9?true:false}
                            disabled={false}
                        />  
                        <TextFieldInput
                            key={4}
                            name={`${Translate(lang, "label.boarding.06")}`}
                            id={`message`}
                            fieldsData={contactForm}
                            handleFieldChange={this.handleChangeUser}
                            size="small"
                            shrink={true}
                            type={"text"}
                            required={true}
                            style={3}
                            placeholder={`${Translate(lang, "label.boarding.02")}`}
                            helper={`${contactForm.message.length}/500`}
                            maxText={500}
                            multiline
                            noError={contactForm.message.length > 0?true:false}
                            disabled={false}
                            rows={5}
                        />  
                        
                        <div>
                            {sendingForm?
                                <div style={{marginLeft:'50%',marginTop:'5%'}}>
                                    <Loader loading={true}/>
                                </div>
                            :
                                resultContact === undefined?
                                    <button className='boarding-send-contact-form' onClick={()=>this.handleSendContact(myInfo, contactForm, lang)}>
                                        {`${Translate(lang, "label.boarding.01")}`}
                                    </button>
                                :
                                    resultContact == 1?
                                        <p className='boarding-p-text boarding-p-text-good'>
                                            {`${Translate(lang, "label.boarding.07")}`}
                                        </p>
                                    :
                                        <p className='boarding-p-text boarding-p-text-error'>
                                        {`${Translate(lang, "label.boarding.error.01")}`}
                                        </p>
                            }
                        </div>
                    </div>
                    {/*<div style={{position:'absolute', top:20, textAlign:'center', width:'100%'}}>
                        <div style={{padding:20, textAlign:'justify'}}>
                            <p style={{lineHeight:2}}>
                                <a>{`${Translate(lang, "label.hi")} `}</a><a style={{fontWeight:'bold'}}>{`${myInfo.displayName}`}</a><a>{`, ${Translate(lang, "error.label.boarding.01")}`}</a>
                            </p>
                            <p>
                                <a href='mailto:abastim.lleida@gmail.com'>
                                    {`abastim.lleida@gmail.com`}
                                </a>
                            </p>
                            <p>
                                <a style={{color:'#003B71', cursor:'pointer', textDecoration:'underline'}} onClick={()=>callPhone(tlf)}>
                                    <strong>{`${tlf}`}</strong>
                                </a>
                            </p>
                        </div>
            </div>*/}
                    <div className='boarding-form-login'>
                        <button className='boarding-btn-signout' onClick={()=>signOut()}>
                            {`${Translate(lang, "label.signout")}`}
                        </button>
                        <button className='boarding-login-content' onClick={()=>window.location.replace(window.location.origin)}>
                            {`${Translate(lang, "label.tryagain")}`}
                        </button>
                    </div>

                </div>

                <CreateModal
                    onCloseCreationModal={this.onCloseCreationModal}
                    isOpen={this.state.creationModalOpen}
                    inputText={this.state.inputText}
                    typeModal={this.state.modalType}
                    typeAction={this.state.typeAction}
                    loadingModal={false}
                    titleModal={this.state.titleModal}
                />

                <div className='boarding-footer'>
                        <a href='mailto:abastim.lleida@gmail.com'>
                            {`abastim.lleida@gmail.com`}
                        </a>
                        {` - `}
                        <a style={{color:'#003B71', cursor:'pointer', textDecoration:'underline'}} onClick={()=>callPhone(tlf)}>
                            {`${tlf}`}
                        </a>
                </div>
            </div>

        );
    }
}

export default withParamsAndNavigate(Home);