import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IFarmer, IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {farmers} from '../ApiClients/DummyData';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import {readLocalStorage, writeLocalStorage, GetFormattedDate, GetDatetimeFormat} from '../Library/sp_general';
import { GetViewDefaultType, } from '../Library/helpers';
import Translate from './Material/Traductor';
import OrderService from '../ApiClients/OrderService';

//import {GetLogoEmpresa} from '../Library/helpers';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});

interface IAccountProps {
    //history: any;
    //match: any;
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    cart: Array<IProduct>;
    pedido: IPedido;
    addOrRemoveFromCart: Function;
    updateCart: Function;
    checkPermission:Function;
    allowed:boolean;
    lang:string;
}

interface IAccountState {
    loading: boolean;
    loadbeauty: boolean;
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;
    searchValue: string;
    viewType: number;
    dashboard?:any;
    titleModal: string;
}

class Farmers extends Component<IAccountProps, IAccountState> {
    constructor(props: IAccountProps) {
        super(props);
        this.state = {
            loading: true,
            loadbeauty: false,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue: '',
            viewType: GetViewDefaultType(),
            titleModal: ''
        };
    }

    componentDidMount() {
        this.handleMyInfoChange();
    }

    componentDidUpdate = (prevprops:IAccountProps) => {
        if(prevprops.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo)
        this.loadData();
    }
    
    loadData = async () => {
        const {myInfo, allowed} = this.props;
        console.log('data', allowed)

        if(!allowed)
            return;
    
        this.loadPanel();
    }

    loadPanel = async () => {
        const {myInfo} = this.props;

        if(!myInfo)
            return;

        let dashboard:any = await OrderService.getDashboard(myInfo.userId);

        //console.log(dashboard)

        this.setState({
            dashboard: dashboard,
            loading: false,
            loadbeauty: false
        });
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica el cierre de sesión de la app
                console.log('voy a cerrar sesión');
                break;
            default:
                break;
        }
    }

    dashboard = (me:IMe, dashboard:any) => {
        const {lang} = this.props;

        return (
            <div className='admin-body'>
                <div className='admin-data-text account-data-radius-top'>
                    <p className='admin-p-data-text'>
                            <strong>
                                {`${Translate(lang, "admin.text.01")}`}
                            </strong>
                    </p>
                    <p className='admin-p-data-text'>
                        {`${dashboard.registered.length}`}
                    </p>
                </div>
                <div className='admin-data-text'>
                    <p className='admin-p-data-text'>
                        <strong>
                            {`${Translate(lang, "admin.text.02")}`}
                        </strong>
                    </p>
                    <p className='admin-p-data-text'>
                        {`${dashboard.visits.map((item:any) => item.visits).reduce((prev:any, next:any) => prev + next)}`}
                    </p>
                </div>
                
                <div className='admin-data-text'>
                    <p className='admin-p-data-text'>
                            <strong>
                                {`${Translate(lang, "admin.text.05")}`}
                            </strong>
                    </p>
                    <p className='admin-p-data-text'>
                        {`${dashboard.forms.length}`}
                    </p>
                </div>

                <div className='admin-data-text'>
                    <p className='admin-p-data-text'>
                        <strong>
                            {`${Translate(lang, "admin.text.03")}`}
                        </strong>
                    </p>
                    {
                        dashboard.registered.map((item:any, idx:number)=>{
                            return(
                                <p className='admin-p-data-text'>
                                    <a>
                                        {GetDatetimeFormat(item.dtCreated)}
                                    </a>
                                    {` <---> `}
                                    <a>
                                        {item.email}
                                    </a>
                                </p>
                            )
                        })
                    }
                </div>

                <div className='admin-data-text admin-data-text-end admin-data-radius-bottom'>
                    <p className='admin-p-data-text'>
                        <strong>
                            {`${Translate(lang, "admin.text.04")}`}
                        </strong>
                    </p>
                    {
                        dashboard.visits.map((item:any, idx:number)=>{
                            return(
                                <p className='admin-p-data-text'>
                                    <a>
                                        {GetFormattedDate(item.date)}
                                    </a>
                                    {` <---> `}
                                    <a>
                                        {item.visits}
                                    </a>
                                </p>
                            )
                        })
                    }
                </div>


            </div>
        );
    }

    render() {
        const {myInfo, version, navigate, pedido, params } = this.props;
        const {loadbeauty, searchValue, loading, dashboard } = this.state;

        if(myInfo == null)
            console.log('Usuario no autorizado');
            
        return (
            <div>
                {/*TutorialExplication()*/}
                <Layout
                    navigate={navigate}
                    params={params}
                    placeholderSearch={""}
                    badgeAlarms={pedido.cart.length}
                    version={version}
                    searchValue={searchValue}
                    me={myInfo}
                />
                <div className='home-content'>

                    {/*this.home()*/}
                    {loading || !myInfo?
                        <div style={{marginLeft:'50%',marginTop:'10%'}}>
                            <Loader loading={this.state.loading}/>
                        </div>

                    :
                        dashboard?
                            this.dashboard(myInfo, dashboard)
                        :
                            `Error...`
                    }

                    <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={false}
                            titleModal={this.state.titleModal}
                        />
                </div>
            </div>
        );
    }
}

export default withParamsAndNavigate(Farmers);