import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, createStyles, makeStyles } from '@material-ui/styles';

const useStyles = (theme:any) => ({
    paper: { minWidth: "80vw", minHeight:'90vh' },
});

interface ICreateModalProps {
    isOpen: boolean;
    onCloseCreationModal?: any;
    inputText: string;
    typeModal: number;
    action?: Function;
    typeAction?: number;
    loadingModal?: boolean;
    titleModal?:string;
    closeButton?:boolean;
    fullscreen?:boolean;
    maxwidth?:any;
    classes?:any;
};

interface ICreateModalState {
}

class CreateModal extends React.Component<ICreateModalProps, ICreateModalState> {

    constructor(props: ICreateModalProps) {
        super(props);
        this.state = {
        };
    }

    handleAction = () => {
        if(this.props.action !== undefined) {
            this.props.action(this.props.typeAction);
        }
    }

    renderButtons = () => {
        if(!this.props.loadingModal) {
            switch(this.props.typeModal) {
                case 1:
                    return(
                        <DialogActions>
                            <Button onClick={this.props.onCloseCreationModal} color="primary">
                            {`Ok`}
                            </Button>
                        </DialogActions>
                    );
                case 2:
                    return (
                        <DialogActions>
                            <Button style={{marginRight:'auto'}} onClick={()=>this.props.onCloseCreationModal(this.props.typeAction)} color="primary">
                            {`No`}
                            </Button>
                            <Button style={{marginLeft:'auto'}} onClick={this.handleAction} color="primary">
                            {`Sí`}
                            </Button>
                        </DialogActions>
                    );
                case 3:
                    return(
                        <DialogActions>
                            <Button onClick={this.handleAction} color="primary">
                            {`Guardar`}
                            </Button>
                        </DialogActions>
                    );
                default:
                    break;
            }
        } else {
            return(
                <div style={{marginLeft:'47%',marginTop:'5%', marginBottom:'3%'}}>
                    <Loader loading={true}/>
                </div>
            );
        }
    }

    render() {

        return (
            <div>
            <Dialog
                open={this.props.isOpen}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                //fullWidth={this.props.fullscreen}
                //maxWidth={this.props.maxwidth}
                classes={{ paper: (this.props.fullscreen?this.props.classes.paper:null) }}
            >
                <DialogTitle style={{marginRight:30}} id="alert-dialog-slide-title">{this.props.titleModal!==undefined?this.props.titleModal:"MENSAJE"}</DialogTitle>
                {this.props.closeButton?
                    <IconButton aria-label="close" style={{position:'absolute', top:10, right:10}} onClick={this.props.onCloseCreationModal}>
                        <CloseIcon />
                    </IconButton>
                :
                    null
                }
                <DialogContent>
                    {this.props.inputText}
                </DialogContent>
                {this.renderButtons()}
            </Dialog>
          </div>
        );
    }
}

export default withStyles(useStyles)(CreateModal);