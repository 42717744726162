import React, { Component } from 'react';
import './App.css';
import './Components/Home.css';
import './Components/Material/activityProgress.css';
import './Components/Sidebar.css';
import './Components/Layout.css';
import './Components/Login.css';
import './Components/Information.css';
import './Components/Account.css';
import './Components/Administrator.css';
import './Components/Boarding.css';

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { IFarmer, IMe, IPedido, IProduct } from './Library/types';
import Login from './Components/Login';
import Unauthorized from './Components/Unactivated';
import Boarding from './Components/Boarding';

import Account from './Components/Account';
import Contact from './Components/Contact';
import Mission from './Components/Mission';
import Us from './Components/Us';
import Home from './Components/Home';
//import Products from './Components/Products';
import ProductDetail from './Components/ProductDetail';

import Cesta from './Components/Cesta';
import Pedidos from './Components/Pedidos';
import PedidoDetail from './Components/PedidoDetail';

import Information from './Components/Information';
import Administrator from './Components/Administrator';

import Farmers from './Components/Farmers';
import FarmerDetail from './Components/FarmerDetail';

import OrderService from './ApiClients/OrderService';
import appData from './ApiClients/appData';
import {removeItemLocalStorage, findAndModifyFirst} from './Library/sp_general';
import {onAuthStateChanged, signInWithGoogle } from './Library/sp_firebase';
import { cloneDeep, findIndex, indexOf } from 'lodash';
import { withParamsAndNavigate } from './Components/Material/ReactRouter';
import { GetLanguage } from './Library/helpers';
let timerGetData:any = undefined;

let version:string = 'v1.1.5';

const b_user:IMe = {
  userId: '',
  authorized: false,
  activated: false,
  allowWhatsapp: false,
  phone: '',
  profile: 0,
  email: '',
  displayName: '',
  language: '',
  description: '',
  uri_img: '',
  superadmin: false
}

const farm = {marker:undefined, data: {navbar:undefined, dashboards:undefined, alarmsA: undefined, alarmsH: undefined}};

interface IAppProps {
  //history: any;
  //match:any;
  navigate:any;
  params:any;
}

interface IAppState {
  myInfo?: IMe;
  loading:boolean;
  pedido: IPedido;
  idPedido?:string;
  filters:any;
  goingToPlatform:boolean;
  lang:string;
}

const initialPedido = {
  version: 1,
  id: '',
  cart: [],
  comment: '',
  farmers: [],
  estado: 0
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      myInfo: undefined,
      loading:false,
      pedido: initialPedido,
      filters: {
        products: {
          farmersId: [],
        },
        farmers: {

        }
      },
      goingToPlatform: false,
      lang: GetLanguage('language')
    };
  }

  componentDidMount(){
    onAuthStateChanged((data:any)=>this.handleOnAuthStateChanged(data));
    //this.getMyInfo();

    /*
    setTimeout(() => {
      signInWithGoogle()

    }, 2000);*/
    // Evita que el usuario pueda hacer zoom
    /*document.addEventListener('touchmove', function (event:any) {
      if (event.scale !== 1) { event.preventDefault(); }
    }, { passive: false });*/

    //this.getMyInfo();
    //this.resetLocalStorage();
  }

  handleOnAuthStateChanged=(user:any)=>{
    //console.log('handleOnAuthStateChanged', user);
    this.getMyInfo();
  }

  metrics = async (metricType:number, me:IMe) => { // Registra el acceso del usuario, el fallo no autorizado y el fallo por mantenimiento
    let response = await OrderService.metricService(metricType, me, version);
    //console.log(response);
  }

  getMyInfo = async () => {
    
    let ret = await OrderService.me02();

    this.metrics(1, ret);

    let response = ret?ret:b_user
    //console.log('getMyInfo', response);
    if(response)
      response.language = GetLanguage('language');


    this.setState({
      myInfo: response
    }, ()=>this.checkUserPermission(response));

    /*await OrderService.me()
      .then(user=>{
        console.log('getMyInfo', user)
        this.setState({
          myInfo: user
        }, ()=>this.checkUserPermission(user));
      }).catch(err=>{
        console.log(err)
        this.setState({
          myInfo: b_user
        }, ()=>this.checkUserPermission(b_user));
      })
      .finally(()=>{
      })*/


    //this.metrics(1); // Guarda el registro del primer Login
    //this.checkUserPermission(response);
  }
    
  checkUserPermission=(inputInfo:IMe)=>{ // Verifica los permisos del usuario //
    //console.log('checkUserPermission',inputInfo)

    if(!inputInfo.authorized) {
      console.log('usuario no autorizado')
      this.setState({
        myInfo: undefined
      }, ()=>this.props.navigate('/login'))
      
      //signInWithGoogle()
    } else if(!inputInfo.registered) {
      console.log('Usuario no registrado');
      this.props.navigate('/onboarding');      
    } else if(!inputInfo.activated) {
        console.log('Usuario no activado');
        this.props.navigate('/unauthorized');
    } else {
      if(this.state.goingToPlatform && ["#/login"].indexOf(window.location.hash) > -1) {
        this.setState({goingToPlatform: false}, ()=> this.props.navigate('/'));
      }
      else if(["#/cesta"].indexOf(window.location.hash) > -1 && [20].indexOf(inputInfo.profile) > -1)
        this.props.navigate('/');
      else if(["#/admin"].indexOf(window.location.hash) > -1 && !inputInfo.superadmin)
        this.props.navigate('/');
      else
        console.log('tutto benne')
    }
  }

  handleGoingToPlatform = () => {
    this.setState({goingToPlatform: true});
  }

  addOrRemoveFromCart = (product: IProduct, quantity?: number) => {
    /*if ((t_pedido.length !== 0 && farmRef !== this.state.cartFarmRef) || (this.state.cart.length !== 0 && dataareaid !== this.state.cartDataareaid)) {
      this.setState({
        otherProductFarm: true
      })
      return;
    }*/
    let newPedido: IPedido = cloneDeep(this.state.pedido);

    let isInCart = newPedido.cart.find((item:IProduct) => {
      return (item.farmerId + item.id) === (product.farmerId + product.id);
    }) !== undefined ? true : false;

    if (isInCart) {
      product.isInCart = false;
      //product.quantity = undefined;

      newPedido.cart = newPedido.cart.filter((item:IProduct) => {
        return (item.farmerId + item.id) !== (product.farmerId + product.id);
      })
    } else {
      product.isInCart = true;
      product.quantity = (!product.quantity?product.minimunQuantity:product.quantity);
      product.quantitySend = product.quantity;
      product.quantityAsked = product.quantity;

      newPedido.cart.push(product);
    }

    newPedido.farmers = [];
    newPedido.cart.map((item:IProduct)=>{
      // Check if new farmer product
      if(findIndex(newPedido.farmers, function(o:IFarmer){return o.id == item.farmer.id}) == -1)
        newPedido.farmers.push(item.farmer);
    })



    this.setState((prevState, props) =>({
      pedido: newPedido,
    }))
  }

  addOrRemoveUnitFromProduct = (product: IProduct, type: number) => {
    /*if ((t_pedido.length !== 0 && farmRef !== this.state.cartFarmRef) || (this.state.cart.length !== 0 && dataareaid !== this.state.cartDataareaid)) {
      this.setState({
        otherProductFarm: true
      })
      return;
    }*/

    let newPedido: IPedido = cloneDeep(this.state.pedido);

    let isInCart = newPedido.cart.find((item:IProduct) => {
      return (item.farmerId + item.id) === (product.farmerId + product.id);
    }) !== undefined ? true : false;

    if (isInCart) {
      product.isInCart = false;
      product.quantity = undefined;
      product.quantitySend = product.quantity;
      product.quantityAsked = product.quantity;

      newPedido.cart = newPedido.cart.filter((item:IProduct) => {
        return (item.farmerId + item.id) !== (product.farmerId + product.id);
      })
    } else {
      product.isInCart = true;
      product.quantity = (!product.quantity || product.quantity <= 0?1:0);
      product.quantitySend = product.quantity;
      product.quantityAsked = product.quantity;

      newPedido.cart.push(product);
    }

    this.setState((prevState, props) =>({
      pedido: newPedido,
    }))
  }

  updateCart = (product: IProduct, changeQty?:boolean) => {
    let newPedido: IPedido = cloneDeep(this.state.pedido);

    let isInCart = newPedido.cart.find((item:IProduct) => {
      return (item.farmerId + item.id) === (product.farmerId + product.id);
    }) !== undefined ? true : false;

    if (isInCart) {

      newPedido.cart = newPedido.cart.map((item:IProduct) => {
        if ((item.farmerId + item.id) === (product.farmerId + product.id)) {
          item = product
        }
        return item;

      });
      //console.log('newCart', newCart)
      this.setState({ pedido: newPedido })
    }
  }

  updateCartComments = (evt: any) => {
    let newPedido: IPedido = cloneDeep(this.state.pedido);
    newPedido.comments = evt.target.value;
    this.setState({ pedido: newPedido })
  }

  updateCartDate = (date: any) => {
    let newPedido: IPedido = cloneDeep(this.state.pedido);
    newPedido.date = new Date(date).getTime();
    this.setState({ pedido: newPedido })
  }

  handleChangeFilter = (f_type: string, f_node: string, value: any) => {
    let n_filters = cloneDeep(this.state.filters);
    n_filters[f_type][f_node] = value;

    this.setState({
      filters: n_filters
    });

  }

  resetPedido = () => {
    this.setState({
      pedido: initialPedido
    })
  }

  syncPedido = (pedido:IPedido) => {
    this.setState({
      pedido: pedido
    })
  }

  handleGetAllowed=()=> {
    const {myInfo} = this.state;
    if(myInfo === null || myInfo === undefined || !myInfo.activated || !myInfo.authorized)
      return false;
    return true;
  }

  render() {

    const {lang} = this.state;
    
    return (
        <Routes>
          <Route
            path="/"
            element={
              <Home
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                addOrRemoveFromCart={this.addOrRemoveFromCart}
                updateCart={this.updateCart}
                filters = {this.state.filters}
                onChangeFilter = {this.handleChangeFilter}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          {/*<Route
            path="/products"
            element={
              <Products
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                cart={[]}
              />
            }
          />
          <Route
            path="/products/:filters"
            element={
              <Products
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                cart={[]}
              />
            }
          />*/}
          <Route
            path="/product/:productRef"
            element={
              <ProductDetail
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/cesta"
            element={
              <Cesta
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                updateCartComments={this.updateCartComments}
                updateCartDate={this.updateCartDate}
                updateCart={this.updateCart}
                addOrRemoveFromCart={this.addOrRemoveFromCart}
                resetPedido={this.resetPedido}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/farmers"
            element={
              <Farmers
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                cart={[]}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/farmer/:farmerRef"
            element={
              <FarmerDetail
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                lang={lang}
              />
            }
          />
          <Route
            path="/farmer/:farmerRef/products"
            element={
              <Home
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                addOrRemoveFromCart={this.addOrRemoveFromCart}
                updateCart={this.updateCart}
                filters = {this.state.filters}
                onChangeFilter = {this.handleChangeFilter}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/purchases"
            element={
              <Pedidos
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                type={1}
                lang={lang}
              />
            }
          />
          <Route
            path="/sales"
            element={
              <Pedidos
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                pedido={this.state.pedido}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                type={2}
                lang={lang}
              />
            }
          />
          <Route
            path="/purchase/:pedidoRef"
            element={
              <PedidoDetail
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                updateCart={this.updateCart}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                type={1}
                syncPedido={this.syncPedido}
                lang={lang}
              />
            }
          />
          <Route
            path="/sale/:pedidoRef"
            element={
              <PedidoDetail
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                updateCart={this.updateCart}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                syncPedido={this.syncPedido}
                type={2}
                lang={lang}
              />
            }
          />
          <Route
            path="/account"
            element={
              <Account
                //match={this.props.match}
                //history={this.props.history}
                pedido={this.state.pedido}
                myInfo={this.state.myInfo}
                version={version}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/contact"
            element={
              <Contact
                //match={this.props.match}
                //history={this.props.history}
                pedido={this.state.pedido}
                myInfo={this.state.myInfo}
                version={version}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/mission"
            element={
              <Mission
                //match={this.props.match}
                //history={this.props.history}
                pedido={this.state.pedido}
                myInfo={this.state.myInfo}
                version={version}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/us"
            element={
              <Us
                //match={this.props.match}
                //history={this.props.history}
                pedido={this.state.pedido}
                myInfo={this.state.myInfo}
                version={version}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Information
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                checkPermission={this.checkUserPermission}
                handleGoingToPlatform={this.handleGoingToPlatform}
                lang={lang}
              />
            }
          />
          <Route
            path="/admin"
            element={
              <Administrator
                //match={this.props.match}
                //history={this.props.history}
                pedido={this.state.pedido}
                myInfo={this.state.myInfo}
                version={version}
                checkPermission={this.checkUserPermission}
                allowed={this.handleGetAllowed()}
                lang={lang}
              />
            }
          />
          <Route
            path="/onboarding"
            element={
              <Boarding
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                lang={lang}
              />
            }
          />
          <Route
            path="/unauthorized"
            element={
              <Unauthorized
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                lang={lang}
              />
            }
          />
          <Route
            path="/information"
            element={
              <Information
                //match={this.props.match}
                //history={this.props.history}
                version={version}
                handleGoingToPlatform={this.handleGoingToPlatform}
                lang={lang}
              />
            }
          />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    );
  }
}

export default withParamsAndNavigate(App);
