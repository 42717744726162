import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    clientId: '4aaca9fd-21b6-48d6-a8dd-a32f212972a9',
    tenant: '96c20d9e-b612-4c42-b402-9b9bc895eddf',
    endpoints: {
        api: '4aaca9fd-21b6-48d6-a8dd-a32f212972a9',
    },
    cacheLocation: 'localStorage',
};
export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);