import React from 'react';
import { IPedido } from '../Library/types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/ArrowForwardIos';
import { getStatusDescriptionColor, GetFormattedPedidoDate, addThousandsAndReplaceDecimal } from '../Library/helpers'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Translate from './Material/Traductor';

const useStyles = makeStyles(createStyles({
    root: {
        borderRadius: 0,
    },
    item: {
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        cursor: 'pointer',
        position: 'relative',
        color: '#284770',
        border: '1px solid #ddd'
    },
    status: {
        position: 'absolute',
        bottom: '15px',
        right: '25px',
        borderRadius: '10px',
        padding: '5px 10px',
        fontSize: '11px',
    }
}));

const PedidoListItem = (props: ListChildComponentProps, lang:string) => {
    const { index, style, data } = props;

    const classes = useStyles();
    let item: IPedido = data[index];

    let statusProperties : Array<string> = getStatusDescriptionColor(item.estado, lang)
    
    return (
        <div style={style} key={index}>
            <ListItem button classes={{ root: classes.item }} onClick={item.onItemClick}>
                <ListItemText style={{whiteSpace:'nowrap', overflowX:'auto'}} primary={item.farm ? item.farm.name : ''} secondary={
                    <span>
                        {`${Translate(lang, "label.order.01")}: `}{item.farmer.displayName}
                        <IconButton onClick={(e:any)=>item.onClickInformation(e, 1)}  size='medium'>
                            <InfoIcon />
                        </IconButton>
                        <br />
                        {`${Translate(lang, "label.order.reference")}: `}{item.id}
                        <br />
                        {`${Translate(lang, "label.order.dateDelivery")}: `}{GetFormattedPedidoDate(Number(item.date))}
                        <br />
                        {`${Translate(lang, "label.order.cost")}: `}{`${addThousandsAndReplaceDecimal(item.invoice.toFixed(2), ',')} €`}
                        <br />

                    </span>
                } />
                <div style={{backgroundColor:statusProperties[1], color:statusProperties[2]}} className={classes.status}>{statusProperties[0]}</div>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

PedidoListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default PedidoListItem;