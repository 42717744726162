import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IFarmer, IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {farmers} from '../ApiClients/DummyData';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import VirtualizedList from './Material/VirtualizedList';
import {readLocalStorage, writeLocalStorage} from '../Library/sp_general';
import { searchPedidos, GetViewDefaultType } from '../Library/helpers';
import PedidoListItemFarmer from './PedidoListItemFarmer';
import PedidoListItemCustomer from './PedidoListItemCustomer';
import OrderService from '../ApiClients/OrderService';
import Translate from './Material/Traductor';
import {isPhoneNumber, callPhone} from '../Library/sp_general';

const styles = (theme: Theme) => createStyles({
    container: {
        position: 'absolute',
        top: 200,
        left: 0,
        right: 0,
        bottom: 30,
        overflowY: 'auto',
        padding: '10px',
        textAlign: 'center',
        color:'#000'
    },
    image: {
        maxWidth:'700px',
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
});

const tlfFormatter=(numbers:string)=>{
    if(numbers.length == 0)
        return numbers;
    const splitted = numbers.split('|');
    const nnumbers = splitted.map((tlf:string, i:number)=>{
        let isphone = (isPhoneNumber(tlf) && tlf.trim().replace(/\s/g, "").length == 9?true:false);
        return (
            <p style={{textDecoration:isphone?'underline':'none', cursor:isphone?'pointer':'default'}} onClick={isphone?()=>callPhone(tlf):undefined} key={i}>{`${tlf}`}</p>
        );
    });

    return nnumbers;
}

interface IPedidosProps {
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    pedido: IPedido;
    classes:any;
    checkPermission:Function;
    allowed:boolean;
    type:number;
    lang: string;
}

interface IPedidosState {
    loading: boolean;
    loadbeauty: boolean;
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    pedidos: Array<IPedido>;
    searchValue: string;
    viewType: number;
    titleModal:string;
}

class Pedidos extends Component<IPedidosProps, IPedidosState> {
    _mounted = true;
    constructor(props: IPedidosProps) {
        super(props);
        this.state = {
            pedidos: [],
            loading: true,
            loadbeauty: true,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue: '',
            viewType: GetViewDefaultType(),
            titleModal: 'MENSAJE'
        };
    }
    componentWillUnmount(){
        this._mounted = false;
    }
    componentDidMount() {
        this.handleMyInfoChange();
    }

    componentDidUpdate = (prevprops:IPedidosProps) => {
        if(prevprops.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo)
        this.loadData();
    }

    loadData = async () => {
        const {myInfo, allowed} = this.props;

        if(!allowed)
            return;
    
        this.loadPedidos();
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica el cierre de sesión de la app
                console.log('voy a cerrar sesión');
                break;
            default:
                break;
        }
    }

    loadPedidos = async () => {
        /*let read:any = readLocalStorage('pedidos');
        if(read == null)
            read = [];
        else
            read = JSON.parse(read);
        this.setState({
            pedidos: read
        })*/
        const {myInfo, type} = this.props;

        if(!myInfo)
            return;

        let pedidos:any = await OrderService.getPedidos(myInfo.userId, type);

        this.setState({
            pedidos: pedidos,
            loading: false,
            loadbeauty: false
        });
    }

    handleValueChange = (evt: any) => {
        this.setState({ 
            searchValue: evt.target.value,
        })
    }

    handleItemClick = (pedido: IPedido, i: number) => {
        if(this.props.type == 1) // purchase
            this.props.navigate(`/purchase/${pedido.id}`)
        else if(this.props.type == 2) // sale
            this.props.navigate(`/sale/${pedido.id}`)
    }

    handleClickInformation = (e:any, type:number, pedido:IPedido, lang: string)=>{
        e.stopPropagation();

        let title = "";
        let text:any = "";

        if(type == 1) {
            title = Translate(lang, "label.order.03");
            text =
                <div>
                    <p>
                        { pedido.farmer.displayName}
                    </p>
                </div>
        } else if(type == 2){
            title = Translate(lang, "label.order.04");
            text = 
                <div>
                    <p>
                        {pedido.userName}
                    </p>
                    <p>
                        {pedido.userAddress}
                    </p>
                    <p>
                        {tlfFormatter(pedido.userAddressPhone)}
                    </p>
                </div>
        }
        this.setState({
            inputText: text,
            modalType: 1,
            typeAction: -1,
            creationModalOpen: true,
            titleModal: title
        })
    }

    pedidos=(viewType:number, me:IMe, lang:string)=>{
        const searchValue = this.state.searchValue;

        let f_pedidos = filter(this.state.pedidos, function(o:IPedido){return o.id.toLowerCase().includes(searchValue.toLowerCase()) || (o.farmer.displayName?o.farmer.displayName.toLowerCase().includes(searchValue.toLowerCase()):null) ||  (o.userName?o.userName.toLowerCase().includes(searchValue.toLowerCase()):null) });

        let ret = undefined;

        let items = f_pedidos.map((pedido:any, i) => {
            return {
                ...pedido,
                onItemClick: () => this.handleItemClick(pedido, i),
                onClickInformation: (e:any, type:number)=>this.handleClickInformation(e,type, pedido, lang)
            }
        })
        
        ret = <VirtualizedList items={items} RowComponent={(me.profile == 10?((props:any)=>PedidoListItemCustomer(props, lang)):((props:any)=>PedidoListItemFarmer(props, lang)))} itemSize={150} />;

        return(
            <div style={{
                position: 'absolute',
                top: 200,
                left: 0,
                right: 0,
                bottom: 30,
                overflowY: 'auto',
                zIndex:0
            }}>
                {ret}
            </div>
        )
    }

    handleSearchChange=(evt:any)=>{
        if(!evt){
            this.setState((prevState, props) =>({
                searchValue: '',
            }),);
        } else {
            this.setState((prevState, props) =>({
                searchValue: evt.target.value,
            }),);
        }
    }

    render() {

        const {myInfo, version, navigate, pedido, classes, params, lang } = this.props;
        const {loadbeauty, searchValue, viewType, pedidos, loading } = this.state;

        if(myInfo == null)
            console.log('Usuario no autorizado');

        return (
            <div>
                {/*TutorialExplication()*/}
                <Layout
                    navigate={navigate}
                    params={params}
                    placeholderSearch={(myInfo?Translate(myInfo.language,"label.placeholderSearch.03"):"")}
                    badgeAlarms={pedido.cart.length}
                    version={version}
                    searchValue={searchValue}
                    onSearchChange={this.handleSearchChange}
                    me={myInfo}
                />
                <div className='home-content'>
                    {loadbeauty || !myInfo?
                        <div style={{marginLeft:'50%',marginTop:'10%'}}>
                            <Loader loading={this.state.loading}/>
                        </div>

                    :
                        pedidos.length > 0?
                            this.pedidos(viewType, myInfo, lang)
                        :
                            <div className={classes.container}>
                                <h4>{'No hay ningún pedido que mostrar.'}</h4>
                            </div>
                    }

                    <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={false}
                            titleModal={this.state.titleModal}
                        />
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(withParamsAndNavigate(Pedidos));