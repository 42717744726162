import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IIProduct, IMe, IPedido, IProduct, IProject } from '../Library/types';
import Loader from './Material/Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';
import logo from '../Assets/icons/fa-refresh.svg';
import {AddDocument, RemoveDocument, GetDocument, GetDocuments} from '../Library/sp_firebase';
import background from '../Assets/images/agr.jpg';
import itemIcon from '../Assets/images/items.png';
import orderIcon from '../Assets/images/cesta.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import pedidosIcon from '../Assets/images/pedido.png';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CreateModal from './Material/CreateDialog';
import Layout from './Layout';
import {} from '../ApiClients/DummyData';
import ProductItem from './ProductListItem';
import { cloneDeep, fill, filter, find, findIndex } from 'lodash';
import ProductListItem03 from './ProductListItem03';
import VirtualizedList from './Material/VirtualizedList';
import OrderService from '../ApiClients/OrderService';
import FormProduct from './EditProduct';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {iva, sale_unit, category_sale, price_unit} from '../ApiClients/DummyData';
import Translate from './Material/Traductor';

//import {GetLogoEmpresa} from '../Library/helpers';


const styles = (theme: Theme) => createStyles({
    container: {
        position: 'absolute',
        top: 200,
        left: 0,
        right: 0,
        bottom: 30,
        overflowY: 'auto',
        padding: '10px',
        textAlign: 'center',
        color:'#000'
    },
    image: {
        maxWidth:'700px',
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
});

interface IHomeButtonProps {
    id: string;
    name: string;
    icon?: any;
    goToFeature: Function;
    classes: any;
    cartProductCount?: number;
}

interface IHomeButtonState {
}

class HomeButtonInternal extends Component<IHomeButtonProps, IHomeButtonState> {
    render() {
        let imgComponent = <img src={this.props.icon} alt='icon' className='feature-icon' />;
        return (
            <div className='home-button' onClick={() => this.props.goToFeature(this.props.id)}>
                {this.props.id === 'cesta' ?
                    <Badge badgeContent={this.props.cartProductCount} color="secondary" classes={{ badge: this.props.classes.badge }}>
                    {imgComponent}
                    </Badge>
                    :
                    imgComponent
                }

                <div className='button-text'>
                    {this.props.name}
                </div>
            </div>
        );
    }
}
const HomeButton = withStyles(styles)(HomeButtonInternal);

const GetViewDefaultType=()=>{
    if(window.innerWidth >= 1024)
        return 1;
    return 2;
}

interface IHomeProps {
    //history: any;
    //match: any;
    classes: any;
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    cart: Array<IProduct>;
    pedido: IPedido;
    addOrRemoveFromCart: Function;
    updateCart: Function;
    checkPermission:Function;
    allowed: boolean;
    lang:string;
}

interface IHomeState {
    loading: boolean;
    loadbeauty: boolean;
    modalType: number;
    titleModal: string;
    modalCloseButton:boolean;
    typeAction: number;
    inputText: any;
    loadingModal: boolean;
    creationModalOpen: boolean;
    products: Array<IProduct>
    searchValue: string;
    viewType: number;

    creationModalProductOpen: boolean;
    creationModalProductAction: number;
    productModelLoading: boolean;
}

class Home extends Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            loading: true,
            loadbeauty: false,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            titleModal: '',
            creationModalOpen: false,
            loadingModal: false,
            products: [],
            searchValue: '',
            viewType: GetViewDefaultType(),
            creationModalProductOpen: false,
            productModelLoading:false,
            creationModalProductAction: 0,
            modalCloseButton: false
        };
    }

    componentDidMount=()=>{
        this.handleMyInfoChange();
    }

    componentDidUpdate = (prevprops:IHomeProps) => {
        if(prevprops.myInfo !== this.props.myInfo)
            this.handleMyInfoChange();
        else if(prevprops.params.farmerRef !== this.props.params.farmerRef)
            this.handleMyInfoChange();
    }

    handleMyInfoChange = () => {
        if(this.props.myInfo === undefined || this.props.myInfo === null)
            return;
        this.props.checkPermission(this.props.myInfo)
        this.loadData();
    }

    onCloseFormProductModal = () => {
        this.setState({ creationModalProductOpen: false });
    }

    loadData = async () => {
        const {myInfo, allowed} = this.props;

        if(!allowed)
            return;
    
        const farmerId:any = this.props.params.farmerRef

        if(myInfo && [10].indexOf(myInfo.profile) > -1 || farmerId) {
            let products:any = await OrderService.loadProducts(myInfo?.userId, farmerId);

            this.setState({
                products: products,
                loading: false
            }, ()=>this.handleInitialView())
        }
        else {
            this.setState({
                loading: false
            })
        }

    }

    handleInitialView = () => {
        let n_products:Array<IProduct> = cloneDeep(this.state.products);

        /*const farmerRef:any = this.props.params.farmerRef;

        if(farmerRef)
            n_products = filter(n_products, function(o:IProduct){return o.farmerId == farmerRef});*/

        // Revisa si está en el carrito
        n_products.map((item:IProduct, idx:number)=>{
            if(findIndex(this.props.pedido.cart, function(o:IProduct){return o.id == item.id}) > -1)
                item.isInCart = true;
        })

        this.setState({
            products: n_products
        })
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalCloseButton: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            default:
                break;
        }
    }

    handleAddUnit=(product:IProduct)=>{
        let p_products: Array<IProduct> = cloneDeep(this.state.products);

        const idx = findIndex(p_products, function(item:IProduct){return item.id == product.id})

        if(idx > -1){   
            product.quantity = 
                product.quantity?
                    product.quantity + 1
                :
                    product.minimunQuantity + 1;

            product.quantitySend = product.quantity;
            product.quantityAsked = product.quantity;
            p_products[idx] = product;
            
            this.setState({products: p_products}, ()=>this.props.updateCart(product));
        }
    }

    handleRemoveUnit=(product:IProduct)=>{
        let p_products: Array<IProduct> = cloneDeep(this.state.products);

        const idx = findIndex(p_products, function(item:IProduct){return item.id == product.id})

        if(idx > -1){   
            product.quantity = 
                product.quantity?
                    (product.quantity > product.minimunQuantity?
                        product.quantity - 1
                    :
                        product.minimunQuantity
                    )
                :
                    product.minimunQuantity;

            product.quantitySend = product.quantity;
            product.quantityAsked = product.quantity;
            p_products[idx] = product;
            this.setState({products: p_products}, ()=>this.props.updateCart(product));
        }
    }

    handleChangeQuantity=(evt:any, product:IProduct)=>{
        const value = evt.target.value;

        if(isNaN(value))
            return;

        const quantity = Math.floor(value);

        let p_products: Array<IProduct> = cloneDeep(this.state.products);

        const idx = findIndex(p_products, function(item:IProduct){return item.id == product.id})

        if(idx > -1){   
            product.quantity = 
                quantity && quantity >= product.minimunQuantity?
                    quantity
                :
                    product.minimunQuantity;

            product.quantitySend = product.quantity;
            product.quantityAsked = product.quantity;
            p_products[idx] = product;
            this.setState({products: p_products}, ()=>this.props.updateCart(product));
        }
    }

    handleGoToCart=(product:IProduct)=>{
        this.props.navigate('/cesta')
    }
    
    handleItemClick = (product: IProduct, index: number) => {
        this.props.navigate(`/product/${product.id}`)
    }


    products = (viewType:number, allowBuy: boolean, allowEdit: boolean, role:number, lang:string) => {

        const searchValue = this.state.searchValue;

        let f_products = filter(this.state.products, function(o:IProduct){return o.name.toLowerCase().includes(searchValue.toLowerCase())});
        let ret:any = undefined;

        if(f_products.length > 0) {

            if(viewType == 1){
                ret = f_products.map((item:IProduct, idx:number)=>{
                    let qty = item.quantity;
                    if(item.isInCart){
                        let idx = findIndex(this.props.pedido.cart, function(o){return o.id == item.id});
                        if(idx > -1)
                            qty = this.props.pedido.cart[idx].quantity;
                    }
                    item.onItemClick = () => this.handleItemClick(item, idx)

                    if(allowBuy) {

                        item.onAddClick = ()=>this.props.addOrRemoveFromCart(item)
                        item.onRemoveClick = ()=>this.props.addOrRemoveFromCart(item)
                        item.onChangeQuantity = (evt:any)=>this.handleChangeQuantity(evt, item)
                        item.onAddUnitClick = ()=>this.handleAddUnit(item)
                        item.onRemoveUnitClick = ()=>this.handleRemoveUnit(item)
                        item.onGoToCart = ()=>this.handleGoToCart(item)
                        item.quantity = qty
                    }

                    if(allowEdit)
                        item.allowEdit = ()=>(this.props.myInfo?this.handleRenderProductEdition(item, this.props.myInfo, lang):undefined)

                    item.isFarmerProduct = false;
                    if(role == 20)
                        item.isFarmerProduct = true;
        
                    return(
                        <ProductItem
                            key={`${item.id}_${item.dtUpdated}`}
                            style={{}}
                            index={idx}
                            data={[item, lang]}
                        />
                    )
                });

                ret = 
                    <div className='dynamic-grid'>
                        {ret}
                    </div>

            } else if(viewType == 2){
                let vt_02_items = f_products.map((item:IProduct, idx:number)=>{

                    let qty = item.quantity;
                    if(item.isInCart){
                        let idx = findIndex(this.props.pedido.cart, function(o){return o.id == item.id});
                        if(idx > -1)
                            qty = this.props.pedido.cart[idx].quantity;
                    }

                    item.isFarmerProduct = false;
                    if(role == 20)
                        item.isFarmerProduct = true;

                    if(allowBuy) {
                        return {
                            ...item,
                            onItemClick: () => this.handleItemClick(item, idx),
                            onAddClick: ()=>this.props.addOrRemoveFromCart(item),
                            onRemoveClick: ()=>this.props.addOrRemoveFromCart(item),
                            onChangeQuantity: (evt:any)=>this.handleChangeQuantity(evt, item),
                            onAddUnitClick: ()=>this.handleAddUnit(item),
                            onRemoveUnitClick: ()=>this.handleRemoveUnit(item),
                            onGoToCart: ()=>this.handleGoToCart(item),
                            quantity: qty
                        }
                    } else {
                        return {
                            ...item,
                            onItemClick: () => this.handleItemClick(item, idx),
                            allowEdit: ()=>(this.props.myInfo?this.handleRenderProductEdition(item, this.props.myInfo, lang):undefined)
                        }
                    }
                });

                ret = <VirtualizedList items={vt_02_items} RowComponent={(props:any)=>ProductListItem03(props, lang)} itemSize={145} />
            }
        } else {
            ret = 
            <div style={{textAlign:'center', marginTop:20}}>
                <a style={{color:'#000'}}>{`${Translate(lang, "label.home.12")}`}</a>
            </div>
        }

        return (
            <div style={{
                position: 'absolute',
                top: 190,
                left: 0,
                right: 0,
                bottom: 30,
                overflowY: 'auto',
            }}>
                {ret}
            </div>

        );
    }

    handleSearchChange=(evt:any)=>{
        if(!evt){
            this.setState((prevState, props) =>({
                searchValue: '',
            }),);
        } else {
            this.setState((prevState, props) =>({
                searchValue: evt.target.value,
            }),);
        }
    }

    handleProductCreated = (userId:string) => {
        this.onCloseCreationModal();
        this.props.navigate(`/farmer/${userId}/products`);
    }

    handleProductEdited = () => {
        this.onCloseCreationModal();
        this.loadData();
    }

    handleRenderProductEdition = (item:any, myInfo:IMe, lang:string) => {

        this.setState({
            inputText: <FormProduct
                            item={item}
                            myInfo={myInfo}
                            insideModel={true}
                            callback={(!item?()=>this.handleProductCreated(myInfo.userId):()=>this.handleProductEdited())}
                            lang={lang}
                        />,
            modalType: 0,
            typeAction: -1,
            creationModalOpen: true,
            titleModal:Translate(lang,"label.home.01"),
            creationModalProductOpen: false,
            modalCloseButton: true
        })
    }

    render() {
        const {myInfo, version, navigate, pedido, classes, params, lang } = this.props;
        const {loadbeauty, searchValue, viewType, productModelLoading, loading } = this.state;

        if(myInfo === null)
            console.log('Usuario no autorizado');
            
        return (
            <div>
                {/*TutorialExplication()*/}
                <Layout
                    navigate={navigate}
                    params={params}
                    placeholderSearch={(myInfo?Translate(myInfo.language,"label.placeholderSearch.02"):"")}
                    badgeAlarms={pedido.cart.length}
                    version={version}
                    searchValue={searchValue}
                    onSearchChange={myInfo && ([10].indexOf(myInfo.profile) > -1 || (params["*"] !== ``))?this.handleSearchChange:undefined}
                    me={myInfo}
                />
                <div className='home-content'>
                    {/*this.home()*/}
                    {loading || !myInfo?
                        <div style={{marginLeft:'50%',marginTop:'10%'}}>
                            <Loader loading={this.state.loading}/>
                        </div>

                    :
                        [10].indexOf(myInfo.profile) > -1?
                            this.products(viewType, true, false, myInfo.profile, lang)
                        :
                            [20].indexOf(myInfo.profile) > -1 && this.props.params.farmerRef?
                                this.products(viewType, false, true, myInfo.profile, lang)
                            :
                                [20].indexOf(myInfo.profile) > -1?
                                    <div className={classes.container}>
                                        <h4>{`Hola, ${myInfo.displayName}`}</h4>
                                        <button className='button-add-cart' onClick={()=>this.handleRenderProductEdition(undefined, myInfo, lang)} >
                                            {`${Translate(lang,"label.home.01")}`}
                                        </button>
                                    </div>
                                :
                                    null
                    }


                    {this.state.creationModalOpen?
                        <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={this.state.loadingModal}
                            closeButton={this.state.modalCloseButton}
                            titleModal={this.state.titleModal}
                        />
                    :
                        null
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(withParamsAndNavigate(Home));