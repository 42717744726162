import React, { Fragment, useState }from 'react';
import './LanguagePicker.css';

interface Language {
    lang: string;
    name: string;
    value: string;
}
function languagePicker(data:Array<Language>, defaultLanguage:string, disabled:boolean, handleChange:Function) {
  /*return (
    <div className="js">
        <div className="language-picker js-language-picker" data-trigger-class="btn btn--subtle">
            <form action="" className="language-picker__form">
                <label htmlFor="language-picker-select">{`Escoger idioma`}</label>
                <select onChange={(evt:any)=>handleChange(evt)} disabled={disabled} name="language-picker-select" id="language-picker-select">
                    {data.map((item:any, idx:number)=>{
                        return(
                            <option selected={idx == defaultLanguage?true:false} lang={item.lang} value={item.value}>{item.name}</option>
                        )
                    })}
                </select>
            </form>
        </div>
    </div>
  );*/

  const idxLanguage:number = data.findIndex(function(o){return o.lang == defaultLanguage});
  
  return(
      <nav>
        <div className="lang-menu">
            <div style={{display:'flex'}}>
                <div className={`selected-lang ${defaultLanguage}`}></div>
                <div className='selected-lang-text'>
                    <a >
                        {idxLanguage == -1?
                            `No default language`
                        :
                            data[idxLanguage].value}
                    </a>
                    <i className="arrow down"></i>
                </div>
            </div>
            
            <ul>
                {data.map((item:any, idx:number)=>{
                    return(
                        /*                        <li className="container" onClick={(evt:any)=>handleChange(evt)}>
                            <a className={item.lang}>{item.name}</a>
                            <label>
                                <input type="checkbox" name="radio"/>
                                <span className="checkmark"></span>
                            </label>
                        </li>*/
                        <li onClick={()=>handleChange(item.lang)}>
                            <a className={item.lang}>{item.name}</a>
                            <input style={{cursor:'pointer'}} type={'checkbox'} defaultChecked={idx == idxLanguage?true:false}  />
                        </li>
                    )
                })}
            </ul>
            
        </div>
        </nav>

  )
}

export default languagePicker;
