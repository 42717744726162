import React, { Fragment, useState }from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { alpha } from '@material-ui/core/styles';
import locale from 'date-fns/locale/es'

function cDatePicker(id:string, label:string, format:string, handleChange:Function, data:string, disabled:boolean, shouldDisableDate:any, minDate?:Date) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
    <DatePicker
        label={label}
        value={data}
        onChange={(evt:any)=>handleChange(id, evt)}
        animateYearScrolling
        format={format}
        minDate={minDate}
        shouldDisableDate={shouldDisableDate}
        disabled={disabled}
        InputLabelProps={{ style: {width:'150px'} }}
        InputProps={{ style: {} }}

    />
    </MuiPickersUtilsProvider>
  );
}

export default cDatePicker;